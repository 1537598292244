import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutomationComponent from "./component";
import { getAutomationState } from "../../store_new/selectors/Automation";
import {
  fetchActions,
  fetchTriggers,
  resetCurrentAutomation,
  addNewAction,
  setIsAddActionContainer,
  setSelectedAction,
  setTrigger,
  setCurrentAutomationName,
  setActions,
  setCurrentAutomationEnabledDisabled,
  addUpdateAutomation,
} from "../../store_new/reducers/Automation";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { IAutomationAction, IAutomationTrigger } from "../../models/Automation";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";

const AutomationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [activeTriggerGroup, setActiveTriggerGroup] =
    useState<string>("allTriggers");
  const [isDeleteActionIconHovered, setIsDeleteActionIconHovered] =
    useState<boolean>(false);

  const { firstName } = useSelector(getCurrentUserData);
  const {
    currentAutomation,
    triggers,
    actions,
    isTriggersLoading,
    isAddActionContainer,
    selectedAction,
    isSaveLoading,
    isActionsLoading,
  } = useSelector(getAutomationState);

  const isTrigger = currentAutomation.trigger;
  const hasActions = currentAutomation.actions.length > 0;
  const allActionsAreCompleted = currentAutomation.actions.every(
    (action) => action.actionCompleted
  );
  const isCurrentAutomationCompleted =
    isTrigger &&
    hasActions &&
    allActionsAreCompleted &&
    currentAutomation.name.length &&
    !isSaveLoading;

  const isTriggersOrActionsLoading =
    !triggers.length ||
    isTriggersLoading ||
    !actions.length ||
    isActionsLoading;

  const groupNames = [
    "Job",
    t("automation.application"),
    "Account",
    "Talentpool",
    "Workflow",
  ];

  const allTriggers = [
    {
      name: t("all"),
      type: "allTriggers",
      params: [],
    },
  ];

  const messageAction = [
    {
      name: t("automation.message"),
      type: "Message",
      params: [],
    },
  ];

  const uniqueTriggers = [
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...new Map(triggers.map((item) => [item.type, item.type])).values(),
  ];

  const formattedUniqueTriggers = uniqueTriggers.map((type, index) => {
    const findTrigger = triggers.find((trigger) => trigger.type === type);
    return {
      name: groupNames[index],
      type,
      params: findTrigger ? findTrigger.params : [],
    };
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const triggersGroup = allTriggers.concat(formattedUniqueTriggers);
  const actionsGroup = triggersGroup.slice(0, 3).concat(messageAction);

  const formattedTriggersGroup = currentAutomation.trigger
    ? actionsGroup
    : triggersGroup;

  const filteredTriggers = useMemo(() => {
    if (currentAutomation.trigger) {
      if (activeTriggerGroup === triggersGroup[0].type) {
        return actions;
      }
      const findSelectedActions = actions.filter(
        (action) => action.type === activeTriggerGroup
      );
      return findSelectedActions;
    }
    if (activeTriggerGroup === triggersGroup[0].type) {
      return triggers;
    }
    const findSelectedTriggers = triggers.filter(
      (trigger) => trigger.type === activeTriggerGroup
    );
    return findSelectedTriggers;
  }, [activeTriggerGroup, currentAutomation.trigger, triggers]);

  const handleChangeActiveTrigger = (value: string | undefined) => {
    const findTriggerGroup = triggersGroup.find(
      (trigger) => trigger.name === value
    );
    if (findTriggerGroup) {
      setActiveTriggerGroup(findTriggerGroup?.type);
    } else {
      if (value) {
        const findActionGroup = actionsGroup.find(
          (action) => action.name === value
        );
        setActiveTriggerGroup(findActionGroup?.type as string);
      } else {
        setActiveTriggerGroup(actionsGroup[0]?.type as string);
        dispatch(setSelectedAction(undefined));
      }
    }
  };

  const handleSelectTrigger = (value: string) => {
    if (currentAutomation.trigger) {
      const findAction = actions.find((action) => action.name === value);
      if (findAction) {
        const formattedAction = {
          ...findAction,
          id: uuidv4(),
        };
        dispatch(addNewAction(formattedAction));
        dispatch(setSelectedAction(formattedAction));
      }
    } else {
      const findTrigger = triggers.find((trigger) => trigger.name === value);
      if (findTrigger) {
        const formattedTrigger = {
          name: findTrigger.name,
          const: findTrigger.const,
          params: findTrigger.params,
          type: findTrigger.type,
        };
        dispatch(setTrigger(formattedTrigger));
      }
      if (currentAutomation.actions.length) {
        dispatch(setSelectedAction(undefined));
      } else {
        dispatch(setIsAddActionContainer(true));
      }
    }
  };

  const handleOpenSelectActionContainer = () => {
    dispatch(setIsAddActionContainer(false));
  };

  const handleOpenAction = (action: IAutomationAction) => {
    if (!isDeleteActionIconHovered) {
      dispatch(setSelectedAction(action));
    }
  };

  const handleChangeCurrentAutomationName = (name: string) => {
    dispatch(setCurrentAutomationName(name));
  };

  const handleDeleteTriggerAction = (variant: string, actionId: string) => {
    if (variant === "trigger") {
      dispatch(setTrigger(null));
    } else {
      const currentActions = [...currentAutomation.actions];
      const updatedActions = currentActions.filter(
        (action) => action.id !== actionId
      );
      dispatch(setActions(updatedActions));
      if (actionId === selectedAction?.id) {
        dispatch(setSelectedAction(undefined));
      }
      setIsDeleteActionIconHovered(false);
    }
  };

  const handleEnabledDisabledAutomation = () => {
    dispatch(setCurrentAutomationEnabledDisabled(!currentAutomation.enabled));
  };

  const handleHoverLeaveDeleteActionIcon = (value: boolean) => {
    setIsDeleteActionIconHovered(value);
  };

  const onSuccessSaveAutomation = () => {
    dispatch(
      addMessage({
        title: t("automation.saveAutomation"),
        type: "success",
      })
    );
    navigate("/automations");
  };

  const handleSaveUpdateAutomation = () => {
    dispatch(
      addUpdateAutomation({
        callback: onSuccessSaveAutomation,
      })
    );
  };

  useEffect(() => {
    if (currentAutomation.trigger) {
      setActiveTriggerGroup("allTriggers");
    }
  }, [currentAutomation.trigger]);

  useEffect(() => {
    if (!triggers.length && firstName && !isTriggersLoading) {
      setTimeout(() => {
        dispatch(fetchTriggers());
      }, 300);
    }
    if (!actions.length && firstName && !isActionsLoading) {
      setTimeout(() => {
        dispatch(fetchActions());
      }, 300);
    }
    return () => {
      dispatch(resetCurrentAutomation());
    };
  }, [firstName]);

  return (
    <AutomationComponent
      t={t}
      currentAutomation={currentAutomation}
      triggers={filteredTriggers as IAutomationTrigger[]}
      triggersGroup={formattedTriggersGroup}
      activeTriggerGroup={activeTriggerGroup}
      isTriggersLoading={isTriggersOrActionsLoading}
      isAddActionContainer={isAddActionContainer}
      selectedAction={selectedAction}
      isCurrentAutomationCompleted={Boolean(isCurrentAutomationCompleted)}
      onEnabledDisabledAutmation={handleEnabledDisabledAutomation}
      onDeleteTriggerAction={handleDeleteTriggerAction}
      onChangeCurrentAutomationName={handleChangeCurrentAutomationName}
      onOpenAction={handleOpenAction}
      onOpenSelectActionContainer={handleOpenSelectActionContainer}
      onChangeActiveTrigger={handleChangeActiveTrigger}
      onSelectTrigger={handleSelectTrigger}
      onHoverLeaveDeleteActionIcon={handleHoverLeaveDeleteActionIcon}
      onSaveUpdateAutomation={handleSaveUpdateAutomation}
    />
  );
};

export default AutomationPage;
