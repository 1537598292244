import React, { useState, useRef, useEffect } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import RegisterForm from "./RegisterForm";
import LoggedCandidate from "./LoggedCandidate";
import CandidateExistLogin from "./CandidateExistLogin";
import ShareJob from "../ShareJob";
import {
  StyledJobPreviewActionContainer,
  StyledJobPreviewTemplateSectionContent,
  StyledJobPreviewTemplateSectionTitle,
  StyledFloatingButton,
} from "../styles";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";
import { GlobalToastNotificationsComponent } from "../../../components/Snackbar";

const JobAction = ({
  desktop,
  disabled,
  companyColor,
}: {
  readonly desktop: boolean;
  readonly disabled: boolean;
  readonly companyColor: string;
}) => {
  const { t } = useTranslation();
  const { key } = useParams();

  const formContainerRef = useRef<HTMLDivElement>(null);
  const [existingEmail, setExistingEmail] = useState<string>("");
  const [isApplyFormVisible, setIsApplyFormVisible] = useState<boolean>(false);
  const { firstName } = useSelector(getCurrentUserData);
  const { applyError } = useSelector(getJobPreviewState);

  const handleScrollToApplyForm = () => {
    formContainerRef?.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsApplyFormVisible(entry.isIntersecting);
    });
    observer.observe(formContainerRef.current as Element);
  }, []);

  return (
    <StyledJobPreviewActionContainer
      width={desktop ? 360 : "calc(100vw - 96px)"}
      disabled={disabled}
      ref={formContainerRef}
    >
      <GlobalToastNotificationsComponent desktop={desktop} />
      <StyledJobPreviewTemplateSectionTitle>
        {t("apply_page.apply_now")}
      </StyledJobPreviewTemplateSectionTitle>
      <StyledJobPreviewTemplateSectionContent pt={1}>
        {t("apply_page.apply_text")}
      </StyledJobPreviewTemplateSectionContent>
      {!desktop && !isApplyFormVisible && (
        <Stack width={"calc(100vw - 96px)"} position="fixed" bottom={24}>
          <StyledFloatingButton
            companyColor={companyColor}
            variant="contained"
            onClick={handleScrollToApplyForm}
          >
            {t("apply_page.apply_now")}
          </StyledFloatingButton>
        </Stack>
      )}
      {disabled ? (
        <RegisterForm
          setExistingEmail={setExistingEmail}
          companyColor={companyColor}
        />
      ) : firstName ? (
        <LoggedCandidate />
      ) : !applyError ? (
        <RegisterForm
          setExistingEmail={setExistingEmail}
          companyColor={companyColor}
        />
      ) : (
        <CandidateExistLogin
          t={t}
          existingEmail={existingEmail}
          urlKey={key as string}
          companyColor={companyColor}
        />
      )}
      <ShareJob />
    </StyledJobPreviewActionContainer>
  );
};

export default JobAction;
