import { Alert, Button } from "@mui/material";
import { styled } from "@mui/styles";

export const StyledAlert = styled(Alert)(
  ({ desktop }: { desktop: boolean }) => ({
    flexDirection: desktop ? "row" : "column",
    marginBottom: 24,
    height: desktop ? 80 : "auto",
    width: "100%",
    padding: desktop ? "0px 24px" : "6px 0px 0px 0px",
    alignItems: "center",
    justifyContent: "center",
  })
);

export const StyledSuccessOutlineButton = styled(Button)({
  color: "#22C55E",
  border: "1px solid #22C55E !important",
  "&:hover": {
    color: "#16A34A",
    border: "1px solid #15803D !important",
    background: "transparent !important",
  },
});

export const StyledContainedInfoButton = styled(Button)(
  ({ desktop }: { desktop: boolean }) => ({
    minWidth: desktop ? "fit-content" : "100%",
    maxWidth: desktop ? "fit-content" : "100%",
    background: "#818CF8",
    "&:hover": {
      background: "#6366F1",
    },
  })
);

export const StyledContainedSuccessButton = styled(Button)(
  ({ desktop }: { desktop: boolean }) => ({
    minWidth: desktop ? "auto" : "100%",
    maxWidth: desktop ? "auto" : "100%",
    background: "#22C55E",
    "&:hover": {
      background: "#15803D",
    },
  })
);
