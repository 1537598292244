import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { debounce } from "debounce";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useDispatch, useSelector } from "react-redux";
import AddProfileDialogComponent from "./component";
import { getTalentProfileFormFields, profileDefaultValues } from "./config";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import { fetchCities } from "../../../store_new/reducers/JobPreview";
import { getTalentPoolState } from "../../../store_new/selectors/Talentpool";
import { ITalentPoolProfile } from "../../../models/TalentPools";

const AddProfileDialog = ({
  isOpen,
  onAdd,
  onClose,
}: {
  isOpen: boolean;
  onAdd: (profile: ITalentPoolProfile, callback: void) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { cities } = useSelector(getJobPreviewState);
  const { isProfileLoading } = useSelector(getTalentPoolState);
  const [countryCode, setCountryCode] = useState("");
  const [uploadedCV, setUploadedCV] = useState<any>(null);

  useEffect(() => {
    dispatch(fetchCities({ city: "Berlin" })); // get Berlin by default
  }, []);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(getTalentProfileFormFields(t)),
    defaultValues: profileDefaultValues,
  });

  const handleOnSearchCity = useMemo(
    () =>
      debounce((city: string) => {
        dispatch(fetchCities({ city }));
      }, 500),
    []
  );

  const handleOnChangeCountry = (countryCode: string) => {
    setValue("country", countryCode);
    setCountryCode(countryCode);
  };

  const handleOnUploadCV = (event?: ChangeEvent<HTMLInputElement>) => {
    if (event && event.target.files?.[0]) {
      setUploadedCV(event.target.files[0]);
      setValue("cv", event.target.files[0]);
    }
  };

  const handleOnResetCV = (event: any) => {
    setUploadedCV(null);
    setValue("cv", {});
    event.preventDefault();
  };

  const onSubmit = (formData: ITalentPoolProfile) => {
    onAdd && onAdd(formData, handleOnClose());
  };

  const handleOnClose = () => {
    setCountryCode("");
    reset();
    onClose && onClose();
    setUploadedCV(null);
    setValue("cv", {});
  };

  return (
    <AddProfileDialogComponent
      t={t}
      errors={errors}
      control={control}
      countryCode={countryCode}
      cities={cities}
      isLoading={isProfileLoading}
      isOpen={isOpen}
      register={register}
      uploadedCV={uploadedCV}
      handleSubmit={handleSubmit}
      onClose={handleOnClose}
      onChangeCountry={handleOnChangeCountry}
      onSearchCity={handleOnSearchCity}
      onSubmit={onSubmit}
      onUploadCV={handleOnUploadCV}
      onResetCV={handleOnResetCV}
    />
  );
};

export default AddProfileDialog;
