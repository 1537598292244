import React from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  globalNotificationSliceSelectors,
  deleteMessage,
} from "../../slices/NotificationSlice/GlobalNotificationSlice";
import { GlobalToastNotificationItem } from "./GlobalToastNotificationItem";

export function GlobalToastNotificationsComponent({ desktop }) {
  const notifications = useSelector(
    globalNotificationSliceSelectors.notifications
  );
  const dispatch = useDispatch();

  const closeNotification = (uuid) => {
    dispatch(deleteMessage(uuid));
  };

  return (
    <Stack>
      {notifications.map((notif, index) => (
        <GlobalToastNotificationItem
          key={index}
          desktop={desktop}
          onClose={closeNotification}
          notification={notif}
        />
      ))}
    </Stack>
  );
}
