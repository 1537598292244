import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PackagePageComponent from "./component";
import { getActiveProduct } from "../../store_new/selectors/CurrentUser";
import { setIsBuyProductDialogOpen } from "../../store_new/reducers/dialogs";
import { TPremiumPackage } from "../../models/SingleJob";
import { setSelectedProducts } from "../../slices/Job/jobSlice";
import { getSingleJobState } from "../../store_new/selectors/SingleJob";

const PackagePageNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const activeProduct = useSelector(getActiveProduct);
  const { packages } = useSelector(getSingleJobState);

  const handleOpenBuyProductDialog = () => {
    dispatch(setSelectedProducts([activeProduct]));
    dispatch(setIsBuyProductDialogOpen(true));
  };

  useEffect(() => {
    if (!activeProduct) {
      navigate("/discover");
    }
  }, []);

  return (
    <PackagePageComponent
      t={t}
      packages={packages}
      activeProduct={activeProduct as TPremiumPackage}
      onOpenBuyProductDialog={handleOpenBuyProductDialog}
    />
  );
};

export default PackagePageNew;
