// TODO convert this component to TS

import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Flag from "react-country-flag";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fetchCountries } from "../../../services/utils";
import { companyDataSchema } from "../validation/schemas";
import { useTranslation } from "react-i18next";
import { getDialogsState } from "../../../store_new/selectors/Dialogs";
import { StyledLoadingApplyButton } from "../../../pages/JobPreview/styles";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";
import { fetchUser } from "../../../store_new/reducers/CurrentUser";
import { updateCompanyAddress } from "../../../store_new/reducers/CompanySettings";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import { setIsCompanyAddressDialogOpen } from "../../../store_new/reducers/dialogs";
import { logOut } from "../../../store_new/reducers/Auth";

const useStyles = makeStyles(() => ({
  "@global": {
    companyAddress: {
      ".MuiAutocomplete-paper": {
        overflow: "visible !important"
      },
      ".MuiPaper-root": {
        overflow: "visible"
      }
    }
  },
  fullWidthField: {
    width: "100%",
    textDecoration: "none",
    textTransform: "none",
    "& .MuiInputBase-input": {
      position: "relative",
      top: 8
    }
  },
  textField: {
    width: "100%",
    textDecoration: "none",
    textTransform: "none"
  },
  star: {
    color: "#EF4444",
    paddingLeft: 2,
    fontSize: 20
  },
  closeIcon: {
    width: 24,
    height: 24,
    stroke: "#131E30",
    strokeWidth: 2,
    cursor: "pointer"
  },
  clearIcon: {
    cursor: "pointer",
    marginRight: 8,
    height: 18,
    width: 18
  },
  placeholder: {
    color: "#6B7280",
    fontWeight: 400
  },
  autocompleteFlagContainer: {
    display: "flex",
    position: "relative",
    top: 11,
    width: 20,
    height: 14,
    overflow: "hidden"
  },
  autocompleteFlagLiContainer: {
    display: "flex",
    position: "relative",
    top: 0,
    width: 20,
    height: 14,
    overflow: "hidden"
  },
  autocompleteTextField: {
    width: "100%",
    textDecoration: "none",
    textTransform: "none",
    height: 40,
    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
      position: "relative",
      bottom: 17.5,
      left: ({ leftTextField }) => leftTextField,
      width: "100%"
    }
  },
  autocompleteTypographyContainer: {
    display: "flex",
    width: "85%",
    paddingLeft: 10
  }
}));

const flags = {
  width: 20,
  height: 12
};

export default function CompanyAddressDialog() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [country, setCountry] = useState(null);
  const [autocompleteDisabled, setAutocompleteDisabled] = useState(false);
  const { isCompanyAddressDialogOpen } = useSelector(getDialogsState);
  const props = {
    leftTextField: country?.Id ? 18 : 0
  };
  const classes = useStyles(props);

  const { countries } = useSelector((state) => state.company);

  const { isAddressUpdateLoading } = useSelector(
    getCompanySettingsCompanyState
  );

  useEffect(() => {
    setValue("country", "");
    if (isCompanyAddressDialogOpen && countries?.length === 0) {
      dispatch(fetchCountries());
    }
  }, [isCompanyAddressDialogOpen]);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(companyDataSchema(t)),
    defaultValues: {
      country: {}
    }
  });

  const changeCountry = (_, data) => {
    setAutocompleteDisabled(true);
    if (data) {
      const { value } = data;
      setValue("country", value.Id);
      setCountry(value);
      clearErrors("country");
    } else {
      setError("country", {
        message: t("register.message"),
        type: "manual"
      });
      setCountry(null);
      setValue("country", "");
    }
    setTimeout(() => {
      setAutocompleteDisabled(false);
    }, 100);
  };

  const onSuccessAddressUpdate = () => {
    reset();
    dispatch(setIsCompanyAddressDialogOpen(false));
    setCountry({});
    dispatch(fetchUser());
    setTimeout(() => {
      dispatch(
        addMessage({
          type: "success",
          title: t("update_company_information.success_snackbar")
        })
      );
    }, 750);
  };

  const onSubmit = (data) => {
    const payload = {
      country: country.Id,
      city: data.city,
      plz: data.postcode,
      address: data.address,
      street_number: data.house_number
    };
    dispatch(
      updateCompanyAddress({
        data: payload,
        callback: () => onSuccessAddressUpdate()
      })
    );
  };

  const isValidNumber = (e) => {
    const specialCharacters = /^[0-9]*$/;
    if (!specialCharacters.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Dialog
      open={isCompanyAddressDialogOpen}
      PaperProps={{
        elevation: 0
      }}
      className={classes.companyAdress}
    >
      <Stack p={3} width={512} backgroundColor="#fff">
        <Typography variant="title">
          {t("update_company_information.title")}
        </Typography>
        <Stack mt={3}>
          <Typography variant="body3" color="textSecondary">
            {t("update_company_information.textOne")}
          </Typography>
          <Typography variant="body3" color="textSecondary">
            {t("update_company_information.textTwo")}
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mt={1} mb={1}>
            <Typography variant="fieldText">
              {t("update_company_information.country")}
              <span className={classes.star}>*</span>
            </Typography>
            <Autocomplete
              ListboxProps={{
                style: {
                  maxHeight: 220
                }
              }}
              {...register("country")}
              disabled={
                autocompleteDisabled || !countries || countries.length < 1
              }
              disablePortal
              options={countries}
              getOptionLabel={(option) => option.value.Label}
              onChange={changeCountry}
              renderOption={(optionProps, option) => (
                <li {...optionProps} translate="no">
                  <Box className={classes.autocompleteFlagLiContainer}>
                    <Flag countryCode={option.value.Id} svg style={flags} />
                  </Box>
                  <Box className={classes.autocompleteTypographyContainer}>
                    <Typography variant="body4" color="textSecondary">
                      {option.value.Label}
                    </Typography>
                  </Box>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.autocompleteTextField}
                  error={Boolean(errors.industry)}
                  placeholder={t("update_company_information.country")}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: country?.Id && (
                      <InputAdornment position="start">
                        <Box className={classes.autocompleteFlagContainer}>
                          <Flag countryCode={country?.Id} svg style={flags} />
                        </Box>
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
            {errors.country && (
              <Typography color="error" variant="subtitle2">
                {errors.country.message}
              </Typography>
            )}
          </Box>
          <Stack direction="row" spacing={4.5} mt={1.5} mb={1}>
            <Stack flex={2}>
              <Typography variant="fieldText">
                {t("update_company_information.city")}
                <span className={classes.star}>*</span>
              </Typography>
              <TextField
                {...register("city")}
                variant="outlined"
                size="small"
                className={classes.textField}
                placeholder={t("update_company_information.city")}
              />
              {errors.city && (
                <Typography color="error" variant="subtitle2">
                  {errors.city.message}
                </Typography>
              )}
            </Stack>
            <Stack flex={1}>
              <Typography variant="fieldText">
                {t("update_company_information.postcode")}
                <span className={classes.star}>*</span>
              </Typography>
              <TextField
                {...register("postcode")}
                variant="outlined"
                size="small"
                className={classes.textField}
                placeholder={t("update_company_information.postcode")}
                onKeyPress={isValidNumber}
              />
              {errors.postcode && (
                <Typography color="error" variant="subtitle2">
                  {errors.postcode.message}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={4.5} mt={1.5}>
            <Stack flex={2}>
              <Typography variant="fieldText">
                {t("update_company_information.street")}
                <span className={classes.star}>*</span>
              </Typography>
              <TextField
                {...register("address")}
                variant="outlined"
                size="small"
                className={classes.textField}
                placeholder={t("update_company_information.street")}
              />
              {errors.address && (
                <Typography color="error" variant="subtitle2">
                  {errors.address.message}
                </Typography>
              )}
            </Stack>
            <Stack flex={1}>
              <Typography variant="fieldText">
                {t("update_company_information.number")}
                <span className={classes.star}>*</span>
              </Typography>
              <TextField
                {...register("house_number")}
                variant="outlined"
                size="small"
                className={classes.textField}
                placeholder={t("update_company_information.number")}
              />
              {errors.house_number && (
                <Typography color="error" variant="subtitle2">
                  {errors.house_number.message}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack
            mt={4}
            direction="row"
            width="100%"
            justifyContent="flex-end"
            spacing={1.5}
          >
            <Button
              type={"button"}
              variant="outlined"
              onClick={() => {
                dispatch(setIsCompanyAddressDialogOpen(false));
                dispatch(logOut());
              }}
            >
              {t("userSettings.logout")}
            </Button>
            <StyledLoadingApplyButton
              type={"submit"}
              variant={"contained"}
              loadingPosition="end"
              loading={isAddressUpdateLoading}
              sx={{
                width: 128
              }}
            >
              {t("button.save")}
            </StyledLoadingApplyButton>
          </Stack>
        </form>
      </Stack>
    </Dialog>
  );
}
