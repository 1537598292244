import React, { useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  BanknotesIcon,
  BriefcaseIcon,
  FolderOpenIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Video from "./Video";
import JobTemplateSection from "./JobTemplateSection";
import JobLocations from "../../../components/JobLocations";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import {
  StyledJobPreviewJobDescriptionHeader,
  StyledJobPreviewTemplateSectionContent,
} from "../styles";
import { convertSalaryString, getSalarySymbol } from "../../../utils/common";

const JobDescription = ({
  desktop,
  companyColor,
}: {
  readonly desktop: boolean;
  readonly companyColor: string;
}) => {
  const { t } = useTranslation();
  const { jobTemplate, jobLocations } = useSelector(getJobPreviewState);
  const noLocations = `${t("update_company_information.city")}, ${t(
    "update_company_information.country"
  )}`;

  const salarySymbol = getSalarySymbol(jobTemplate?.salary_currency || "");
  const { minSalary, maxSalary } = useMemo(
    () => ({
      minSalary: convertSalaryString(jobTemplate?.min_salary),
      maxSalary: convertSalaryString(jobTemplate?.max_salary),
    }),
    [jobTemplate?.max_salary, jobTemplate?.min_salary]
  );

  return (
    <Stack spacing={3}>
      <StyledJobPreviewJobDescriptionHeader>
        <Typography fontSize={20} fontWeight={500} color={companyColor}>
          {jobTemplate?.title ||
            t("create_job_first_step.job_title_placeholder")}
        </Typography>
        <StyledJobPreviewTemplateSectionContent
          direction={desktop ? "row" : "column"}
          sx={{ flexWrap: "wrap", gap: "16px" }}
          companyColor={companyColor}
        >
          {!jobLocations ? (
            <Box>{noLocations}</Box>
          ) : (
            <JobLocations
              desktop={desktop}
              spacing={1}
              jobLocations={jobLocations}
              companyColor={companyColor}
              containerMaxWidth={360}
              itemMaxWidth={110}
            />
          )}
          <Stack direction={"row"} spacing={1}>
            <BriefcaseIcon />
            <Box>
              {jobTemplate?.position_type ||
                t("create_job_second_step.job_type")}
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <FolderOpenIcon />
            <Box>
              {jobTemplate?.work_type ||
                t("create_job_second_step.field_of_work")}
            </Box>
          </Stack>
          {minSalary && maxSalary && (
            <Stack direction={"row"} spacing={1}>
              <BanknotesIcon />
              <Box>
                {t("jobPreview.salary", {
                  currency: salarySymbol,
                  min: minSalary,
                  max: maxSalary,
                })}
                {jobTemplate?.salary_type && (
                  <>
                    <span> / </span>
                    {t(
                      `singleJob.jobDetails.salary.type.${jobTemplate?.salary_type}`
                    )}
                  </>
                )}
              </Box>
            </Stack>
          )}
        </StyledJobPreviewTemplateSectionContent>
      </StyledJobPreviewJobDescriptionHeader>
      <JobTemplateSection
        title={jobTemplate?.template_introduction_title || ""}
        content={jobTemplate?.template_introduction || ""}
        companyColor={companyColor}
      />
      <JobTemplateSection
        title={jobTemplate?.template_tasks_title || ""}
        content={jobTemplate?.template_tasks || ""}
        companyColor={companyColor}
      />
      <JobTemplateSection
        title={jobTemplate?.template_profile_title || ""}
        content={jobTemplate?.template_profile || ""}
        companyColor={companyColor}
      />
      <JobTemplateSection
        title={jobTemplate?.template_offer_title || ""}
        content={jobTemplate?.template_offer || ""}
        companyColor={companyColor}
      />
      {jobTemplate?.template_contact_title && (
        <JobTemplateSection
          title={jobTemplate?.template_contact_title || ""}
          content={jobTemplate?.template_contact || ""}
          companyColor={companyColor}
        />
      )}
      {jobTemplate?.template_video_url && (
        <Video url={jobTemplate?.template_video_url} />
      )}
    </Stack>
  );
};

export default JobDescription;
