// TODO REFACTOR (convert this reducer according to the new redux flow)

import { createSlice } from "@reduxjs/toolkit";
import {
  applyJob,
  getJob,
  getPreview,
  savePreview,
  getJobData,
  createJob,
  checkJobsEmail,
  extendJobLimit,
  downloadCompanyLogo,
  fetchLogoBlob,
  sendMagicLink
} from "../../services/Job";

const SLICE_NAME = "job";

const initialState = {
  companyKey: undefined,
  companyColor: "#178CF2",
  job: undefined,
  jobInfo: undefined,
  jobQuestions: undefined,
  jobLocations: undefined,
  jobs: [],
  apply_loading: false,
  loading: false,
  error: false,
  applyError: false,
  preview_loading: false,
  preview: undefined,
  preview_id: undefined,
  jobData: [],
  selectedProducts: [],
  filteredProducts: [],
  defaultProducts: [],
  cart: [],
  isCartOpen: false,
  noStepCheckout: false,
  date: undefined,
  driversLicenses: [],
  languages: [],
  languageLevels: [],
  skills: [],
  appliedJobKey: undefined,
  jobLimitLoading: false,
  companyLogo: undefined,
  companyLogoBlob: undefined,
  magicLinkLoading: false,
  magicLinkEmail: undefined,
  magicLinkUrlKey: undefined,
  isJobLimitDialogOpen: false,
  isCompanyAdressDialogOpen: undefined
};

export const jobSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setStepThree(state, { payload }) {
      state.step_four = payload;
    },
    setSelectedProducts(state, { payload }) {
      state.selectedProducts = payload;
    },
    setCartOpen(state, { payload }) {
      state.isCartOpen = payload;
    },
    setNoStepCheckOut(state, { payload }) {
      state.noStepCheckout = payload;
    },
    resetSelectedProducts(state) {
      state.selectedProducts = initialState.selectedProducts;
    },
    resetJobData(state) {
      state.jobData = initialState.jobData;
    },
    setJobLimitDialog(state, { payload }) {
      state.isJobLimitDialogOpen = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getJob.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJob.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getJob.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.job = payload.data.jobTemplate;
      state.jobs = payload.data.jobs;
      state.jobInfo = payload.data.job;
      state.jobLocations = payload.data.jobLocations;
      state.jobQuestions = payload.data.jobQuestions;
      state.companyKey = payload.data.companyUrlKey;
      state.companyColor = payload.data.company_color;
      state.driversLicenses =
        payload.data.driversLicenses || payload.data.drivers_licenses;
      state.languages = payload.data.languages;
      state.languageLevels =
        payload.data.languageLevels || payload.data.language_levels;
      state.skills = payload.data.skills;
    });
    builder.addCase(applyJob.pending, (state) => {
      state.apply_loading = true;
      state.applyError = false;
    });
    builder.addCase(applyJob.rejected, (state) => {
      state.apply_loading = false;
      state.applyError = true;
    });
    builder.addCase(applyJob.fulfilled, (state) => {
      state.apply_loading = false;
      state.applyError = false;
    });
    builder.addCase(checkJobsEmail.pending, (state) => {
      state.applyError = false;
    });
    builder.addCase(checkJobsEmail.rejected, (state) => {
      state.applyError = true;
    });
    builder.addCase(checkJobsEmail.fulfilled, (state) => {
      state.applyError = false;
    });
    builder.addCase(savePreview.pending, (state) => {
      state.preview_loading = true;
    });
    builder.addCase(savePreview.rejected, (state) => {
      state.preview_loading = false;
    });
    builder.addCase(savePreview.fulfilled, (state) => {
      state.preview_loading = false;
    });
    builder.addCase(getPreview.rejected, (state) => {
      state.preview_loading = false;
    });
    builder.addCase(getPreview.fulfilled, (state, { payload }) => {
      state.preview = payload.data.template;
    });
    builder.addCase(getJobData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobData.rejected, (state) => {
      state.loading = false;
      state.isCompanyAdressDialogOpen = true;
    });
    builder.addCase(getJobData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.jobData = payload.data;
      state.isCompanyAdressDialogOpen = false;
    });
    builder.addCase(createJob.fulfilled, (state, { payload }) => {
      state.created_job_key = payload.data.urlKey;
    });
    builder.addCase(extendJobLimit.pending, (state) => {
      state.jobLimitLoading = true;
    });
    builder.addCase(extendJobLimit.rejected, (state) => {
      state.jobLimitLoading = false;
    });
    builder.addCase(extendJobLimit.fulfilled, (state) => {
      state.jobLimitLoading = false;
    });
    builder.addCase(sendMagicLink.pending, (state) => {
      state.magicLinkLoading = true;
    });
    builder.addCase(sendMagicLink.rejected, (state) => {
      state.magicLinkLoading = false;
    });
    builder.addCase(sendMagicLink.fulfilled, (state) => {
      state.magicLinkLoading = false;
    });
    builder.addCase(downloadCompanyLogo.fulfilled, (state, { payload }) => {
      state.companyLogo = payload;
    });
    builder.addCase(fetchLogoBlob.fulfilled, (state, { payload }) => {
      state.companyLogoBlob = payload;
    });
  }
});

const getAppState = (state) => state[SLICE_NAME];

export const {
  setSelectedProducts,
  setCartOpen,
  setNoStepCheckOut,
  resetSelectedProducts,
  resetJobData,
  setJobLimitDialog
} = jobSlice.actions;

export const jobSliceSelectors = {
  loading: (rootState) => {
    const appState = getAppState(rootState);
    return appState.loading;
  },
  error: (rootState) => {
    const appState = getAppState(rootState);
    return appState.error;
  },
  applyError: (rootState) => {
    const appState = getAppState(rootState);
    return appState.applyError;
  },
  companyKey: (rootState) => {
    const appState = getAppState(rootState);
    return appState.companyKey;
  },
  job: (rootState) => {
    const appState = getAppState(rootState);
    return appState.job;
  },
  jobs: (rootState) => {
    const appState = getAppState(rootState);
    return appState.jobs;
  },
  jobData: (rootState) => {
    const appState = getAppState(rootState);
    return appState.jobData;
  },
  preview: (rootState) => {
    const appState = getAppState(rootState);
    return appState.preview;
  },
  preview_id: (rootState) => {
    const appState = getAppState(rootState);
    return appState.preview_id;
  },
  preview_loading: (rootState) => {
    const appState = getAppState(rootState);
    return appState.preview_loading;
  },
  apply_loading: (rootState) => {
    const appState = getAppState(rootState);
    return appState.apply_loading;
  },
  selectedProducts: (rootState) => {
    const appState = getAppState(rootState);
    return appState.selectedProducts;
  },
  filteredProducts: (rootState) => {
    const appState = getAppState(rootState);
    return appState.filteredProducts;
  },
  defaultProducts: (rootState) => {
    const appState = getAppState(rootState);
    return appState.defaultProducts;
  },
  step_four_filters: (rootState) => {
    const appState = getAppState(rootState);
    return appState.step_four_filters;
  },
  cart: (rootState) => {
    const appState = getAppState(rootState);
    return appState.cart;
  },
  isCartOpen: (rootState) => {
    const appState = getAppState(rootState);
    return appState.isCartOpen;
  },
  jobQuestions: (rootState) => {
    const appState = getAppState(rootState);
    return appState.jobQuestions;
  },
  jobInfo: (rootState) => {
    const appState = getAppState(rootState);
    return appState.jobInfo;
  },
  jobLocations: (rootState) => {
    const appState = getAppState(rootState);
    return appState.jobLocations;
  },
  date: (rootState) => {
    const appState = getAppState(rootState);
    return appState.date;
  },
  driversLicenses: (rootState) => {
    const appState = getAppState(rootState);
    return appState.driversLicenses;
  },
  languages: (rootState) => {
    const appState = getAppState(rootState);
    return appState.languages;
  },
  languageLevels: (rootState) => {
    const appState = getAppState(rootState);
    return appState.languageLevels;
  },
  skills: (rootState) => {
    const appState = getAppState(rootState);
    return appState.skills;
  },
  created_job_key: (rootState) => {
    const appState = getAppState(rootState);
    return appState.created_job_key;
  }
};
