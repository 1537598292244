import React from "react";
import { Stack, Box, Typography, Tooltip } from "@mui/material";
import ProductsFilter from "./filter";
import ProductsEmptyState from "./ProductsEmptyState";
import { IPremiumProductsComponent } from "../../../models/SingleJob";
import {
  StyledDiscoverProductsContainer,
  StyledDivider,
  StyledPremiumPackagesProductsContainer,
  StyledProductContainerHeader,
} from "../styles";

const DiscoverProductsComponent = ({
  t,
  products,
  totalShopProducts,
  packages,
  onOpenProduct,
  onResetFilters,
}: IPremiumProductsComponent) => (
  <StyledPremiumPackagesProductsContainer packages={packages}>
    <Stack
      flex={1}
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography variant="body1">
        {t("create_job_fourth_step.products")}
      </Typography>
      <ProductsFilter t={t} />
    </Stack>
    <StyledDivider />
    {products?.length ? (
      <Stack
        mt={3}
        direction={"row"}
        flexWrap={"wrap"}
        gap={1.5}
        minHeight={
          totalShopProducts && totalShopProducts > 13 && products.length < 13
            ? 600
            : "auto"
        }
      >
        {products?.map((product) => (
          <StyledDiscoverProductsContainer
            onClick={() => onOpenProduct(product)}
            key={product.name}
            sx={{ cursor: "pointer", flex: "0 0 31.85%" }}
            height={81.83}
          >
            <StyledProductContainerHeader
              alignItems="center"
              justifyContent="flex-start !important"
              gap={3}
            >
              {product.products[0].image_url ? (
                <Stack width={115}>
                  <img src={product.products[0].image_url} alt={product.name} />
                </Stack>
              ) : (
                <Stack minWidth={115}>
                  <Tooltip title={product.name}>
                    <Typography
                      className="two-line-title"
                      maxWidth={110}
                      fontWeight={500}
                      variant="body2"
                    >
                      {product.name}
                    </Typography>
                  </Tooltip>
                </Stack>
              )}

              <Stack width="100%" spacing={0.5}>
                <Stack width="100%">
                  <Tooltip title={product.name}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="#131E30"
                      maxWidth={175}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {product.name}
                    </Typography>
                  </Tooltip>
                </Stack>
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography variant="subtitle2" color="#9CA3AF">
                    {t("create_job_fourth_step.starting_from")}
                  </Typography>
                  <Box className={"new-price"} alignSelf="flex-end">
                    {product.products[0].agency_price ||
                      product.products[0].retail_price}
                    €
                  </Box>
                </Stack>
              </Stack>
            </StyledProductContainerHeader>
          </StyledDiscoverProductsContainer>
        ))}
      </Stack>
    ) : (
      <ProductsEmptyState t={t} onResetFilters={onResetFilters} />
    )}
  </StyledPremiumPackagesProductsContainer>
);

export default DiscoverProductsComponent;
