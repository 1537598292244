import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  IJobListState,
  JOB_ACTIONS,
  JOB_STATUSES,
  TJobListFilters,
  TJobListFilterWithOptionsTypes,
  TJobListItem,
  TJobListSimpleFilterTypes,
} from "../../models/JobList";
import { PayloadActionWithCallback } from "../../models/common";

const initialState: IJobListState = {
  filters: {
    active: {
      locations: "",
      quickSearch: "",
      companyName: "",
      jobType: { value: "", options: [] },
      seniority: { value: "", options: [] },
    },
    archived: {
      locations: "",
      quickSearch: "",
      companyName: "",
      jobType: { value: "", options: [] },
      seniority: { value: "", options: [] },
    },
    draft: {
      locations: "",
      quickSearch: "",
      companyName: "",
      jobType: { value: "", options: [] },
      seniority: { value: "", options: [] },
    },
  },
  navigation: {
    jobsStatuses: [
      JOB_STATUSES.ACTIVE,
      JOB_STATUSES.ARCHIVED,
      JOB_STATUSES.DRAFT,
    ],
    selectedJobStatus: JOB_STATUSES.ACTIVE,
  },
  pagination: {
    active: {
      pageNum: 1,
      pageSize: 10,
    },
    archived: {
      pageNum: 1,
      pageSize: 10,
    },
    draft: {
      pageNum: 1,
      pageSize: 10,
    },
  },
  jobs: {
    list: [],
    totalCount: "0",
  },
  allActiveJobs: undefined,
  selectedJobs: [],
  error: false,
  isLoading: false,
  isListLoading: false,
  isAllJobsLoading: false,
  isDraftImagesLoading: false,
};

const filtersReducer = {
  setSimpleJobFilter: (
    state: Draft<IJobListState>,
    action: PayloadAction<{
      readonly filter: keyof TJobListSimpleFilterTypes;
      readonly value: string;
    }>
  ) => {
    const { filter, value } = action.payload;
    state.filters[
      state.navigation.selectedJobStatus.toLowerCase() as keyof TJobListFilters
    ][filter] = value;
  },
  setJobFilter: (
    state: Draft<IJobListState>,
    action: PayloadAction<{
      readonly value: string;
      readonly filter: keyof TJobListFilterWithOptionsTypes;
    }>
  ) => {
    const { value, filter } = action.payload;
    state.filters[
      state.navigation.selectedJobStatus.toLowerCase() as keyof TJobListFilters
    ][filter].value = value;
  },
  setFilterOptions: (
    state: Draft<IJobListState>,
    action: PayloadAction<{
      readonly filter: keyof TJobListFilterWithOptionsTypes;
      readonly options: {
        readonly value: string;
        readonly label: string;
      }[];
    }>
  ) => {
    const { options, filter } = action.payload;
    state.filters[
      state.navigation.selectedJobStatus.toLowerCase() as keyof TJobListFilters
    ][filter].options = options;
  },
  resetJobsFilters: (state: Draft<IJobListState>) => {
    state.pagination = initialState.pagination;
    state.filters[
      state.navigation.selectedJobStatus.toLowerCase() as keyof TJobListFilters
    ] =
      initialState.filters[
        state.navigation.selectedJobStatus.toLowerCase() as keyof TJobListFilters
      ];
  },
};

const navigationReducer = {
  setSelectedJobStatus: (
    state: Draft<IJobListState>,
    action: PayloadAction<JOB_STATUSES>
  ) => {
    state.navigation.selectedJobStatus = action.payload;
  },
};

const paginationReducer = {
  setPageNum: (state: Draft<IJobListState>, action: PayloadAction<number>) => {
    state.navigation.selectedJobStatus === JOB_STATUSES.ACTIVE
      ? (state.pagination.active.pageNum = action.payload)
      : state.navigation.selectedJobStatus === JOB_STATUSES.ARCHIVED
      ? (state.pagination.archived.pageNum = action.payload)
      : (state.pagination.draft.pageNum = action.payload);
  },
};

const jobsReducer = {
  setSelectedJobs: (
    state: Draft<IJobListState>,
    action: PayloadAction<TJobListItem | null>
  ) => {
    let updatedJobs: TJobListItem[] = [];

    if (action.payload) {
      if (
        state.selectedJobs.findIndex((job) => job.id === action.payload?.id) >
        -1
      ) {
        updatedJobs = state.selectedJobs.filter(
          (job) => job.id !== action.payload?.id
        );
      } else updatedJobs = [...state.selectedJobs, action.payload];
    } else updatedJobs = [];
    state.selectedJobs = updatedJobs;
  },
  fetchJobs: (state: Draft<IJobListState>, _: PayloadAction<JOB_STATUSES>) => {
    state.isListLoading = true;
  },
  fetchJobsSuccess: (
    state: Draft<IJobListState>,
    action: PayloadAction<{
      readonly list: TJobListItem[];
      readonly totalCount: string;
    }>
  ) => {
    state.jobs.list = action.payload.list;
    state.jobs.totalCount = action.payload.totalCount;
    state.isListLoading = false;
  },
  fetchJobsFailed: (
    state: Draft<IJobListState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isListLoading = false;
  },
  fetchAllActiveJobs: (state: Draft<IJobListState>) => {
    state.isAllJobsLoading = true;
  },
  fetchAllActiveJobsSuccess: (
    state: Draft<IJobListState>,
    action: PayloadAction<TJobListItem[]>
  ) => {
    state.isAllJobsLoading = false;
    state.allActiveJobs = action.payload;
  },
  fetchAllActiveJobsFailed: (
    state: Draft<IJobListState>,
    action: PayloadAction<unknown>
  ) => {
    // TODO define type of error and handle error
    state.error = action.payload;
    state.isAllJobsLoading = false;
  },
  archiveRestoreJobs: (
    state: Draft<IJobListState>,
    _: PayloadActionWithCallback<{
      readonly jobUrlKeys: string[];
      readonly action: JOB_ACTIONS;
    }>
  ) => {
    state.isLoading = true;
  },
  archiveRestoreJobsSuccess: (state: Draft<IJobListState>) => {
    state.isLoading = false;
  },
  archiveRestoreJobsFailed: (
    state: Draft<IJobListState>,
    action: PayloadAction<unknown> // TODO define type of error and handle error
  ) => {
    state.error = action.payload;
    state.isLoading = false;
  },
  deleteJobs: (
    state: Draft<IJobListState>,
    _: PayloadAction<{ readonly jobUrlKey: string }>
  ) => {
    state.isLoading = true;
  },
  deleteJobsSuccess: (state: Draft<IJobListState>) => {
    state.isLoading = false;
  },
  deleteJobsFailed: (
    state: Draft<IJobListState>,
    action: PayloadAction<unknown> // TODO define type of error and handle error
  ) => {
    state.error = action.payload;
    state.isLoading = false;
  },
  setIsDraftImagesLoading: (
    state: Draft<IJobListState>,
    action: PayloadAction<boolean> // TODO define type of error and handle error
  ) => {
    state.isDraftImagesLoading = action.payload;
  },
};

export const JobListSlice = createSlice({
  name: "JOB_LIST",
  initialState,
  reducers: {
    ...filtersReducer,
    ...navigationReducer,
    ...paginationReducer,
    ...jobsReducer,
  },
});

export const {
  setSimpleJobFilter,
  setJobFilter,
  setFilterOptions,
  resetJobsFilters,
  setSelectedJobStatus,
  setPageNum,
  setSelectedJobs,
  fetchJobs,
  fetchJobsSuccess,
  fetchJobsFailed,
  fetchAllActiveJobs,
  fetchAllActiveJobsFailed,
  fetchAllActiveJobsSuccess,
  archiveRestoreJobs,
  archiveRestoreJobsSuccess,
  archiveRestoreJobsFailed,
  deleteJobs,
  deleteJobsSuccess,
  deleteJobsFailed,
  setIsDraftImagesLoading,
} = JobListSlice.actions;

export default JobListSlice.reducer;
