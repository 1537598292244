import axios from "axios";

const lang = localStorage.getItem("lang");

export const axiosInstance = axios.create({
  baseURL: "https://be.app.karriera.de/",
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept-Language": lang,
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : undefined;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response.status;
    if (status >= 500 && status <= 599) {
      error.response.data = {
        error: {
          message: "Internal server error. Please contact your administrator!",
        },
      };
    } else if (status === 401) {
      localStorage.removeItem("token");
    }

    return Promise.reject(error);
  }
);

export const axiosInstanceDownload = axios.create({
  baseURL: "https://be.app.karriera.de/",
  headers: {
    "Content-Type": "multipart/form-data",
    "Accept-Language": lang,
  },
  responseType: "blob",
});

axiosInstanceDownload.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : undefined;
  return config;
});

axiosInstanceDownload.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response.status;
    if (status >= 500 && status <= 599) {
      error.response.data = {
        error: {
          message: "Internal server error. Please contact your administrator!",
        },
      };
    } else if (status === 401) {
      localStorage.removeItem("token");
    }

    return Promise.reject(error);
  }
);
