import React from "react";
import { Stack, Tooltip } from "@mui/material";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { IIncompleteDeleteDataComponent } from "../../models/Automation";
import { StyledDeleteNotCompletedIcon } from "./style";

const IncompleteDeleteData = ({
  t,
  data,
  variant,
  currentAutomation,
  onHoverLeaveDeleteActionIcon,
  onDeleteTriggerAction,
}: IIncompleteDeleteDataComponent) => {
  const noTrigger = variant === "trigger" && !currentAutomation?.trigger;
  const triggerExist = variant === "trigger" && currentAutomation?.trigger;
  const incompleteAction = variant === "component" && !data.actionCompleted;
  const showMissingDataIcon = noTrigger || incompleteAction;
  const showDeleteIcon = triggerExist || variant === "component";

  const tooltipText = noTrigger
    ? t("automation.noTriggerText")
    : incompleteAction
    ? t("automation.incompleteActionText")
    : "";

  return (
    <Stack direction="row" spacing={1}>
      {showMissingDataIcon && (
        <Tooltip title={tooltipText}>
          <StyledDeleteNotCompletedIcon background="#FEF3C7">
            <ExclamationCircleIcon
              width={20}
              height={20}
              stroke="#D97706"
              fill="transparent"
            />
          </StyledDeleteNotCompletedIcon>
        </Tooltip>
      )}
      {showDeleteIcon && (
        <StyledDeleteNotCompletedIcon
          background="#FEF2F2"
          onClick={() =>
            onDeleteTriggerAction && onDeleteTriggerAction(variant, data.id)
          }
          onMouseLeave={() => {
            onHoverLeaveDeleteActionIcon && onHoverLeaveDeleteActionIcon(false);
          }}
          onMouseOver={() => {
            onHoverLeaveDeleteActionIcon && onHoverLeaveDeleteActionIcon(true);
          }}
        >
          <XMarkIcon width={20} height={20} stroke="#EF4444" />
        </StyledDeleteNotCompletedIcon>
      )}
    </Stack>
  );
};

export default IncompleteDeleteData;
