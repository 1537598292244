import { TabPanel } from "@mui/lab";
import { Box, Button, DialogActions, Divider, Stack } from "@mui/material";
import { styled } from "@mui/system";
import { TPremiumPackage } from "../../models/SingleJob";

export const StyledSingleJobContainer = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  button: { border: "none" },
});

export const StyledTabPanel = styled(TabPanel)(
  ({ view }: { view?: string }) => ({
    height: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: view === "cards" ? 0 : 24,
  })
);

export const StyledPremiumPackagesProductsContainer = styled(Stack)(
  ({ packages }: { packages?: TPremiumPackage[] | null }) => ({
    backgroundColor: "#fff",
    border: "1px solid #E5E7EB",
    borderRadius: 6,
    padding: 24,
    marginTop: packages && packages.length > 1 ? 24 : 0,
  })
);

export const StyledProductBodyItem = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  padding: 12,
  alignItems: "center",
  backgroundColor: "#F9FAFB",
  ".new-price": {
    fontSize: 16,
    color: "#818CF8",
  },
  ".old-price": {
    fontSize: 16,
    textDecoration: "line-through",
    color: "#EF4444",
  },
});

export const StyledProductsContainer = styled(Stack)({
  flex: "0 0 48.85%",
  marginTop: 24,
  padding: 16,
  border: "1px solid #E5E7EB",
  borderRadius: 6,
});

export const StyledDiscoverProductsContainer = styled(Stack)({
  flex: "0 0 31.85%",
  cursor: "pointer",
  padding: 16,
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  "&:hover": {
    border: "1px solid #178CF2",
  },
});

export const StyledProductContainerHeader = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  img: { width: 120, height: 40 },
  ".new-price": {
    fontSize: 16,
    color: "#818CF8",
  },
});

export const StyledDivider = styled(Divider)({
  height: 1,
  marginTop: 16,
  borderColor: "#E5E7EB",
});

export const StyledVerticalDivider = styled(Divider)({
  height: "100%",
  marginLeft: 12,
});

export const StyledAddBtnContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: 0,
  marginLeft: 12,
  height: "100%",
  svg: {
    width: 24,
    height: 24,
    strokeWidth: 1,
    stroke: "#178CF2",
    cursor: "pointer",
  },
  ".success": {
    stroke: "#fff",
    strokeWidth: 0.1,
    fill: "#10B981",
    background: "transparent",
  },
});

export const StyledPremiumJobBoardsContainer = styled(Stack)({
  width: "calc(100% - 40px)", // 40px size of float icon (cart button)
});

export const StyledPackageContainer = styled(Stack)(
  ({ isDiscoverPage }: { isDiscoverPage?: boolean }) => ({
    width: "100%",
    height: "100%",
    flex: "1 1 calc(33.333% - 16px)",
    marginTop: 24,
    padding: 16,
    border: "1px solid #E5E7EB",
    borderRadius: 6,
    cursor: isDiscoverPage ? "pointer" : "auto",
    "&:hover": {
      border: isDiscoverPage ? "1px solid #178CF2" : "1px solid #E5E7EB",
    },
  })
);

export const StyledPackageContainerHeader = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
});

export const StyledDiscountLabel = styled(Stack)({
  backgroundColor: "#818CF8",
  padding: 8,
  borderRadius: 6,
  minWidth: 42,
  height: 24,
  color: "#fff",
  alignItems: "center",
  justifyContent: "center",
  fontSize: 12,
});

export const StyledPackageBodyItem = styled(Stack)({
  border: "1px solid #E5E7EB",
  borderRadius: 6,
  padding: 12,
  img: {
    width: 100,
    height: 33,
  },
  ".marketing-image": {
    height: 16,
    width: "auto",
    objectFit: "contain",
  },
  ".old-price": {
    fontSize: 12,
    color: "#9CA3AF",
    textDecoration: "line-through",
  },
});

export const StyledPackagePriceBlock = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  margin: "16px 0!important",
  padding: "0 12px",
  height: 48,
  alignItems: "center",
  backgroundColor: "#F9FAFB",
  ".new-price": {
    fontSize: 16,
    color: "#818CF8",
  },
  ".old-price": {
    fontSize: 16,
    textDecoration: "line-through",
    color: "#EF4444",
  },
});

export const StyledDaysBlock = styled(Stack)({
  height: 20,
  flexDirection: "row",
  alignItems: "center",
  gap: "7px",
  span: {
    lineHeight: 20,
    fontSize: 12,
    color: "#9CA3AF",
  },
  svg: {
    width: 16,
    height: 16,
    stroke: "#9CA3AF",
    strokeWidth: 1,
  },
});

export const StyledSuccessButton = styled(Button)({
  background: "#22C55E",
  "&:hover": {
    background: "#1b9f4b",
    boxShadow: "none",
  },
  "&:disabled": {
    background: "#86EFAC",
    color: "#fff",
  },
});

export const StyledDialogActions = styled(DialogActions)({
  padding: 24,
  background: "#fff",
});

export const StyledShoppingCartButtonContainer = styled(Stack)({
  top: 217, // 104 + 41 + 24 + 24 + 24 - top header + tabs container + list wrapper padding + list container padding + list container margin;
  position: "fixed",
  marginRight: -50,
  width: 40,
  height: 40,
  borderRadius: 1.5,
  justifyContent: "center",
  alignItems: "center",
  ".count-label": {
    position: "absolute",
    right: -5,
    bottom: -5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    color: "#178CF2",
    border: "1px solid #178CF2",
    borderRadius: "50%",
    width: 21,
    height: 21,
    fontSize: 10.5,
    fontWeight: 500,
    cursor: "pointer",
  },
});
