import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  container: {
    background: "#fff",
    borderRadius: "6px",
    border: "1px solid #E5E7EB",
    padding: 24,
    boxSizing: "border-box",
    transition: "all 0.5s ease-in-out",
    height: "100%",
  },
  icon: {
    width: 24,
    height: 24,
    display: "flex",
    color: "#178CF2",
    cursor: "pointer",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #E5E7EB",
    paddingBottom: "16px",
    marginBottom: "24px",
  },
  messageHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #E5E7EB",
    paddingBottom: "16px",
    marginBottom: "24px",
    "& p": {
      margin: 0,
      color: "#131e30",
      borderRadius: "6px",
      fontSize: "18px",
      lineHeight: "18px",
    },
  },
  infoWrap: {
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    "& li": {
      display: "flex",
      marginBottom: "24px",
      justifyContent: "space-between",
    },
  },
  iconWrap: {
    listStyle: "none",
    display: "inline-flex",
    alignItems: "center",
    padding: 0,
    "& svg": {
      width: 17,
      height: 17,
      color: "#818CF8",
      padding: 10,
      background: "#EEF2FF",
      boxSizing: "content-box",
      borderRadius: "100%",
      marginRight: 16,
    },
  },
  fullWidthField: {
    width: "100%",
    textDecoration: "none",
    textTransform: "none",
    height: 40,
  },
  body: {
    minHeight: "556px",
    height: "calc(100% - 55px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));
