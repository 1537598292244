import React, { useMemo } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import {
  BanknotesIcon,
  BriefcaseIcon,
  BuildingOffice2Icon,
  ChevronRightIcon,
  FolderOpenIcon,
  MapPinIcon
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getJobPreviewState } from "../../../store_new/selectors/JobPreview";
import {
  StyledJobContainer,
  StyledJobItemContainer
} from "../../CandidateDashboard_new/styles";
import { convertSalaryString, getSalarySymbol } from "../../../utils/common";

const OtherJobs = ({
  desktop,
  companyColor,
  logo,
  totalJobs
}: {
  readonly desktop: boolean;
  readonly companyColor: string;
  readonly logo: string | null;
  readonly totalJobs: number;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { jobTemplate, otherJobs } = useSelector(getJobPreviewState);

  const language = localStorage.getItem("lang");
  const checkLanguage = language === "en_US" ? "en" : "de";
  const regionNames = new Intl.DisplayNames([checkLanguage], {
    type: "region"
  });

  const handleOnShowAll = () => {
    navigate(`/companies/${jobTemplate?.company_url_key}`);
  };

  const handleOnNavigateJob = (jobUrlKey: string) => {
    navigate(`/job/${jobUrlKey}`);
  };

  const { minSalary, maxSalary } = useMemo(
    () => ({
      minSalary: convertSalaryString(jobTemplate?.min_salary),
      maxSalary: convertSalaryString(jobTemplate?.max_salary)
    }),
    [jobTemplate?.max_salary, jobTemplate?.min_salary]
  );

  return (
    <Stack spacing={1.5} maxWidth={desktop ? 744 : "calc(100vw - 96px)"}>
      {desktop && totalJobs > 6 && (
        <Typography
          mr={-4.5}
          align="right"
          variant={"subtitle2"}
          sx={{ cursor: "pointer" }}
          onClick={handleOnShowAll}
          color={companyColor}
        >
          {t("company_page.show_all")}
        </Typography>
      )}
      {otherJobs.map((job) => (
        <StyledJobContainer
          companyColor={companyColor}
          key={job.id!.toString()}
          direction={"row"}
          spacing={2}
          py={2}
          px={2.5}
          onClick={() => handleOnNavigateJob(job.url_key)}
        >
          {desktop && (
            <Box sx={{ height: 56 }}>
              {logo ? (
                <img
                  src={logo}
                  width={112}
                  height={56}
                  style={{ objectFit: "contain" }}
                  alt={"company logo"}
                />
              ) : (
                <BuildingOffice2Icon className={"company-logo"} />
              )}
            </Box>
          )}

          <Stack width={"100%"} spacing={1}>
            <Typography variant={desktop ? "h6" : "body1"} lineHeight={"28px"}>
              {job.title}
            </Typography>
            <Stack
              direction={desktop ? "row" : "column"}
              sx={{
                flexWrap: "wrap",
                gap: "8px"
              }}
            >
              <StyledJobItemContainer direction="row" spacing={1}>
                <MapPinIcon />
                <Typography
                  variant="subtitle2"
                  display="inline"
                  color="textSecondary"
                >
                  {job.locations[0]?.city},{" "}
                  {regionNames.of(job.locations[0].country as string)}
                </Typography>
              </StyledJobItemContainer>
              <StyledJobItemContainer direction="row" spacing={1}>
                <BriefcaseIcon />
                <Typography
                  variant="subtitle2"
                  display="inline"
                  color="textSecondary"
                >
                  {job.position_type}
                </Typography>
              </StyledJobItemContainer>
              <StyledJobItemContainer direction="row" spacing={1}>
                <FolderOpenIcon />
                <Typography
                  variant="subtitle2"
                  display="inline"
                  color="textSecondary"
                >
                  {job.field_of_work}
                </Typography>
              </StyledJobItemContainer>
              {minSalary && maxSalary && (
                <StyledJobItemContainer direction="row" spacing={1}>
                  <BanknotesIcon />
                  <Typography
                    variant="subtitle2"
                    display="inline"
                    color="textSecondary"
                  >
                    {t("jobPreview.salary", {
                      currency: getSalarySymbol(job?.salary_currency || ""),
                      min: minSalary,
                      max: maxSalary
                    })}

                    {job?.salary_type && (
                      <>
                        <span> / </span>
                        {t(
                          `singleJob.jobDetails.salary.type.${job?.salary_type}`
                        )}
                      </>
                    )}
                  </Typography>
                </StyledJobItemContainer>
              )}
            </Stack>
          </Stack>
          <ChevronRightIcon className={"next-icon"} />
        </StyledJobContainer>
      ))}
      {!desktop && totalJobs > 6 && (
        <Button
          variant={"outlined"}
          onClick={handleOnShowAll}
          className="show-all-jobs-mobile-button"
        >
          {t("company_page.show_all")}
        </Button>
      )}
    </Stack>
  );
};

export default OtherJobs;
