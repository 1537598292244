import React from "react";
import CustomDropdown from "./CustomDropdown";
import { TListOption } from "../../models/common";

const CustomToolbar = ({
  items,
  onSelect,
}: {
  readonly items: TListOption[];
  readonly onSelect: (value: string) => void;
}) => (
  <div id="toolbar">
    <span className="ql-formats">
      <CustomDropdown onSelect={onSelect} items={items} />
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
    </span>
  </div>
);

export default CustomToolbar;
