import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Divider, MenuItem, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IDataGridStatusSelectorCell } from "../../../models/DataGrid";
import { StyledStatusChip } from "../styles";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";
import { getTalentPoolState } from "../../../store_new/selectors/Talentpool";
import {
  addCandidateInTalentPool,
  fetchTalentPoolCandidates,
} from "../../../store_new/reducers/TalentPools";

import {
  getCurrentApplication,
  getJobWorkflow,
} from "../../../store_new/selectors/Applications";
import { addMessage } from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import TalentPoolPopover from "../../TalentPoolPopover";
import {
  setIsLimitDialogOpen,
  setIsTalentPoolsDialogOpen,
} from "../../../store_new/reducers/dialogs";
import { DIALOG_TYPE } from "../../../models/common";
import {
  fetchApplications,
  fetchSelectedJobApplications,
  updateApplicationStatus,
} from "../../../store_new/reducers/Applications";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";
import { TCandidateApplication } from "../../../models/ApplicationPage";
import { getSingleJobState } from "../../../store_new/selectors/SingleJob";
import { getWorkflowState } from "../../../store_new/selectors/Workflow";
import { StyledStatusMenu } from "../../FloatingMenu/style";
import { IWorkflowStatus } from "../../../models/Workflow";
import { ICellRendererParams } from "ag-grid-community";

const StatusSelectorCell = ({
  label,
  type,
  params,
  onSelect,
}: IDataGridStatusSelectorCell) => {
  const dispatch = useDispatch();
  const { job_id, key } = useParams();
  const { t } = useTranslation();
  const jobWorkflow = useSelector(getJobWorkflow);
  const currentApplication = useSelector(getCurrentApplication);
  const { isAgency } = useSelector(getCurrentUserData);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const defaultStatuses = [
    {
      label: t("status.new"),
      value: "new",
      color: "#818CF8",
    },
    {
      label: t("status.in_progress"),
      value: "review",
      color: "#F59E0B",
    },
    {
      label: t("status.hired"),
      value: "hired",
      color: "#22C55E",
    },
    {
      label: t("status.rejected"),
      value: "rejected",
      color: "#EF4444",
    },
  ];

  const [uniqueStatuses, setUniqueStatuses] = useState(defaultStatuses);
  const [talentPoolsAnchorEl, setTalentPoolsAnchorEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const talentPoolMenuOpen = Boolean(talentPoolsAnchorEl);
  const id = talentPoolMenuOpen ? "simple-popover" : undefined;
  const [isTalentPoolMenuHovered, setIsTalentPoolMenuHovered] =
    useState<boolean>(false);

  const { talentPools } = useSelector(getTalentPoolState);
  const { company } = useSelector(getCompanySettingsCompanyState);
  const { jobDetails } = useSelector(getSingleJobState);
  const { workflows } = useSelector(getWorkflowState);

  const allStatuses = Array.from(
    new Set(
      workflows?.flatMap(
        (workflow) =>
          workflow?.statuses?.map((status) => ({
            label: status.label,
            value: status.value,
            color: status.color,
          }))
      )
    )
  );

  const allUniqueStatuses =
    workflows.length > 0 ? allStatuses : defaultStatuses;

  const selectedStatus = useMemo(() => {
    if (params) {
      const findWorkflow = workflows.find(
        (workflow) => workflow.id == params.data.workflow_id
      );
      if (findWorkflow) {
        const findStatus =
          findWorkflow.statuses.find(({ value }) => {
            return value === params?.data.status.toLowerCase();
          })?.label || t("status.new");
        return findStatus;
      }
      const findDefaultStatus = workflows[0].statuses.find(({ value }) => {
        return value === params?.data.status.toLowerCase();
      })?.label;
      return findDefaultStatus;
    }
  }, [workflows]);

  const selectedLabel = useMemo(() => {
    if (params) {
      const findWorkflow = workflows.find(
        (workflow) => workflow.id == params.data.workflow_id
      );
      if (findWorkflow) {
        const findStatus = findWorkflow.statuses.find(({ value }) => {
          return value === params?.data.status.toLowerCase();
        })?.label;
        return findStatus;
      }
      const findDefaultStatus = workflows[0].statuses.find(({ value }) => {
        return value === params?.data.status.toLowerCase();
      })?.label;
      return findDefaultStatus;
    }
  }, [workflows]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTalentPoolClick = (event: MouseEvent<HTMLLIElement>) => {
    setTalentPoolsAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTalentPoolsAnchorEl(null);
  };

  const handleOnClickMenuItem = (newStatus: string) => {
    onSelect && onSelect(newStatus);
    handleClose();
  };

  const onSuccessChangeApplicationStatus = () => {
    if (key && job_id) {
      dispatch(
        fetchSelectedJobApplications({
          candidateUrlKey: key,
          job_id: job_id,
        })
      );
    } else {
      dispatch(
        fetchApplications({
          withoutLoader: true,
        })
      );
    }
  };

  const onSuccessAddCandidateInTalentPool = (
    id: number,
    applications: TCandidateApplication
  ) => {
    dispatch(fetchTalentPoolCandidates(id));
    dispatch(
      addMessage({
        type: "success",
        title: t("talentPools.successCandidateAddedInTalentPool"),
      })
    );
    dispatch(
      updateApplicationStatus({
        applicationId: applications?.application_id as string,
        newStatus: "rejected",
        index: "0",
        callback: () => onSuccessChangeApplicationStatus(),
      })
    );
  };

  const handleOnClickTalentPoolMenuItem = (
    id: number,
    applications: TCandidateApplication
  ) => {
    dispatch(
      addCandidateInTalentPool({
        id,
        applications,
        callback: () => onSuccessAddCandidateInTalentPool(id, applications),
      })
    );
    setTalentPoolsAnchorEl(null);
  };

  const handleCloseTalentPoolMenu = () => {
    if (!isTalentPoolMenuHovered) {
      setTalentPoolsAnchorEl(null);
    }
  };

  const handleOnTalentPoolHoverLeave = (hover: boolean) => {
    setIsTalentPoolMenuHovered(hover);
  };

  const handleOnOpenTalentPoolDialog = () => {
    if (company?.talentpools_creation_allowed) {
      dispatch(setIsTalentPoolsDialogOpen(DIALOG_TYPE.CREATE_EDIT_TALENT_POOL));
    } else {
      dispatch(setIsLimitDialogOpen(true));
    }
  };

  const onMouseOver = () => {
    if (!jobDetails) {
      if (params?.data.workflow_id) {
        const findWorkflow = workflows.find(
          (workflow) => workflow.id == params.data.workflow_id
        );
        if (findWorkflow) {
          const singleJobWorkflowStatuses = findWorkflow?.statuses?.map(
            (status) => ({
              label: status.label,
              value: status.value,
              color: status.color,
            })
          );
          setUniqueStatuses(singleJobWorkflowStatuses);
        }
      } else {
        setUniqueStatuses(defaultStatuses);
      }
    }
  };

  useEffect(() => {
    if (workflows && workflows.length > 0) {
      if (jobDetails) {
        const formattedStatuses = jobWorkflow?.statuses?.map(
          (status: IWorkflowStatus) => ({
            label: status.title || status.label,
            value: status.value,
            color: status.color,
          })
        );
        setUniqueStatuses(formattedStatuses as IWorkflowStatus[]);
      } else {
        setUniqueStatuses(allUniqueStatuses);
      }
    } else {
      setUniqueStatuses(defaultStatuses);
    }
  }, [workflows, jobDetails]);

  return (
    <>
      <StyledStatusChip
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick={onSelect ? handleClick : undefined}
        onDelete={onSelect ? handleClick : undefined}
        uniqueStatuses={allUniqueStatuses}
        params={params as ICellRendererParams}
        currentApplication={currentApplication}
        data-testid={"status-selector-button"}
        label={
          selectedStatus === "Hired"
            ? "Hire"
            : selectedStatus
            ? selectedStatus
            : selectedLabel === "Hired"
            ? "Hire"
            : selectedLabel ?? label
        }
        type={
          selectedStatus === t("status.new") ||
          selectedLabel === t("status.new")
            ? "new"
            : type
        }
        className={"status-selector"}
        deleteIcon={<ChevronDownIcon className={"status-selector"} />}
        onMouseOver={onMouseOver}
      />
      <StyledStatusMenu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        currentApplication={currentApplication}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "actions-button",
        }}
      >
        {uniqueStatuses?.map(
          (status: { value: string; label: string }) =>
            label?.toLowerCase() != status?.label?.toLowerCase() && (
              <MenuItem
                data-testid={`status-selector-${status.value}-button`}
                key={status.value}
                onClick={() => {
                  handleOnClickMenuItem(status.value);
                }}
              >
                {status.label === "Hired" ? "Hire" : status.label}
              </MenuItem>
            )
        )}
        {!isAgency && (
          <Stack
            position="sticky"
            bottom={0}
            zIndex={2}
            sx={{ background: "#fff" }}
          >
            <Divider />
            <MenuItem
              data-testid={"status-selector-talent-pool-button"}
              aria-controls={talentPoolMenuOpen ? "actions-menu" : undefined}
              onClick={handleTalentPoolClick}
            >
              {t("talentPools.addToTalentPool")}
            </MenuItem>
          </Stack>
        )}
      </StyledStatusMenu>
      <TalentPoolPopover
        t={t}
        id={id}
        onCloseTalentPoolMenu={handleCloseTalentPoolMenu}
        open={talentPoolMenuOpen}
        anchorEl={talentPoolsAnchorEl}
        jobId={job_id}
        talentPools={talentPools}
        params={params}
        currentApplication={currentApplication}
        onTalentPoolHoverLeave={handleOnTalentPoolHoverLeave}
        onClickTalentPoolMenuItem={handleOnClickTalentPoolMenuItem}
        onOpenCreateTalentPoolDialog={handleOnOpenTalentPoolDialog}
      />
    </>
  );
};

export default StatusSelectorCell;
