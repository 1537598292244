import React, { useEffect, useMemo, useState } from "react";
import RequestDataComponent from "./component";
import { useTranslation } from "react-i18next";
import { getDialogsState } from "../../../store_new/selectors/Dialogs";
import { useDispatch, useSelector } from "react-redux";
import { setIsRequestDataDialogOpen } from "../../../store_new/reducers/dialogs";
import {
  getRequestedDataList,
  getRequestedDataTitle,
} from "../../../store_new/selectors/Applications";
import {
  setRequestedDataList,
  setRequestedDataTitle,
} from "../../../store_new/reducers/Applications";

const RequestDataDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isOpen = useSelector(getDialogsState).isRequestDataDialogOpen;
  const title = useSelector(getRequestedDataTitle);
  const initialLstToRequest = useSelector(getRequestedDataList);

  const [listToRequest, setListToRequest] = useState<
    { title: string; id: string; checked: boolean }[]
  >([]);

  useEffect(() => {
    setListToRequest(
      initialLstToRequest.map((field) => ({ ...field, checked: true })),
    );
  }, [initialLstToRequest]);

  const handleOnClose = () => {
    dispatch(setIsRequestDataDialogOpen(false));
    dispatch(setRequestedDataList([]));
    dispatch(setRequestedDataTitle(""));
  };

  const handleOnsubmit = () => {
    // TODO add logic
    const filteredList = listToRequest.reduce(
      (acc: string[], { checked, id }) => (checked ? [...acc, id] : acc),
      [],
    );
    console.dir(filteredList);
    handleOnClose();
  };

  const handleOnChange = (fieldId: string) => {
    const updatedList = listToRequest.map((field) =>
      field.id === fieldId ? { ...field, checked: !field.checked } : field,
    );
    setListToRequest(updatedList);
  };

  const isDisabled = useMemo(
    () => !listToRequest.some(({ checked }) => checked),
    [listToRequest],
  );

  return (
    <RequestDataComponent
      t={t}
      isOpen={isOpen}
      title={title}
      listToRequest={listToRequest}
      isDisabled={isDisabled}
      onClose={handleOnClose}
      onChange={handleOnChange}
      onSubmit={handleOnsubmit}
    />
  );
};

export default RequestDataDialog;
