import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  drawer: {
    "& .MuiDrawer-paper": {
      padding: "32px !important",
      width: 560,
      overflowY: "auto",
      borderLeft: "1px solid #E5E7EB",
    },
  },
  removeIcon: {
    cursor: "pointer",
    width: 24,
    height: 24,
    stroke: "#131E30",
    strokeWidth: 1.5,
  },
  divider: {
    width: "100%",
    borderColor: "#E5E7EB",
    marginTop: 24,
    marginBottom: 24,
  },
  termsDivider: {
    width: "100%",
    borderColor: "#E5E7EB",
    marginTop: 24,
    marginBottom: 11,
  },
  footerDivider: {
    width: "100%",
    borderColor: "#E5E7EB",
    marginTop: 24,
  },
  clockIcon: {
    width: 16,
    height: 16,
    stroke: "#9CA3AF",
    strokeWidth: 1,
  },
  clearIcon: {
    position: "relative",
    left: 3,
    cursor: "pointer",
    height: 24,
    width: 24,
    stroke: "#EF4444",
  },
  termsText: {
    paddingTop: 13,
  },
  discountPrice: {
    textDecoration: "line-through",
  },
}));
