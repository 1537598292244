import React, { useState, useMemo, useEffect } from "react";
import { Typography, Stack, Snackbar } from "@mui/material";
import { AlertTitle } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { XMarkIcon } from "@heroicons/react/24/outline";
import ActionContainer from "./ActionContainer";
import { StyledAlert } from "./style";
import {
  globalNotificationSliceSelectors,
  setNotifications,
  turnOffNotifications,
} from "../../slices/NotificationSlice/GlobalNotificationSlice";
import {
  getCurrentUserLoadingState,
  getCurrentUserUpdateLoadingState,
} from "../../store_new/selectors/CurrentUser";
import { getDialogsState } from "../../store_new/selectors/Dialogs";

export function GlobalToastNotificationItem({
  onClose,
  notification,
  desktop,
}) {
  const dispatch = useDispatch();
  const {
    action,
    icon,
    uuid,
    type,
    title,
    message,
    visibilityTime,
    onFirstButtonClick,
  } = notification;
  const [open, setOpen] = useState(true);

  const notifications = useSelector(
    globalNotificationSliceSelectors.notifications
  );

  const noActionMobile = action || !action?.includes("without");
  const noActionMobileLeft = action?.includes("without") ? 2.5 : 2;
  const isLoading = useSelector(getCurrentUserLoadingState);
  const isUpdateUserLoading = useSelector(getCurrentUserUpdateLoadingState);
  const { isPasswordNotificationShowed } = useSelector(getDialogsState);

  const isOpen = useMemo(
    () => open && !isLoading && !isUpdateUserLoading,
    [open, isLoading, isUpdateUserLoading]
  );

  const close = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
    // Allow snackbar to fade out before removing this component.
    setTimeout(() => onClose(uuid), 200);
  };

  useEffect(
    () => () => {
      if (isPasswordNotificationShowed) {
        return;
      }
      if (notifications.length > 1 && isPasswordNotificationShowed) {
        dispatch(setNotifications());
      }
    },
    [notifications, isPasswordNotificationShowed]
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      key={uuid}
      open={isOpen}
      autoHideDuration={visibilityTime}
      onClose={close}
      sx={{
        maxWidth: "90vw",
        width: "max-content",
        margin: "auto",
        ".MuiAlert-action": {
          width: desktop ? "auto" : "100% !important",
          margin: desktop ? "auto" : "auto 0px auto 0px !important",
          padding: desktop
            ? "0px 0px 0px 16px"
            : action
            ? "16px !important"
            : "0px",
        },
        ".MuiAlert-message": {
          padding: action
            ? "0px !important"
            : desktop
            ? "0px 0px 2px 16px"
            : "0px 0px 8px 16px",
        },
      }}
    >
      <StyledAlert
        desktop={desktop}
        icon={desktop ? icon ?? false : false}
        action={
          <ActionContainer
            desktop={desktop}
            type={type}
            action={action}
            firstButtonText={notification?.firstButtonText}
            onClose={() => dispatch(turnOffNotifications())}
            onFirstButtonClick={onFirstButtonClick}
          />
        }
        onClose={close}
        severity={type}
        variant="filled"
      >
        {title && (
          <Stack
            position="relative"
            width="100%"
            direction={desktop ? "column" : "row"}
            justifyContent="center"
            alignItems={notification.firstButtonText ? "flex-start" : "center"}
            height={desktop ? 80 : "auto"}
            pt={1}
            spacing={1}
          >
            <AlertTitle
              sx={{
                pl: !action
                  ? 0
                  : !desktop && noActionMobile
                  ? noActionMobileLeft
                  : 0,
                fontWeight: 400,
                pb: desktop ? 0 : 0.25,
                maxWidth: desktop ? "auto" : "90% !important",
              }}
            >
              {title}
            </AlertTitle>
            {!desktop && noActionMobile && (
              <XMarkIcon
                width={20}
                height={20}
                onClick={close}
                cursor="pointer"
                style={{ marginBottom: 7.5, marginRight: 12 }}
              />
            )}
          </Stack>
        )}
        {typeof message === "string" ? (
          <Typography variant="body3" wordWrap="break-word">
            {message}
          </Typography>
        ) : (
          message?.map((msg, index) => (
            <Typography key={index} variant="body3" display="block">
              {msg}
            </Typography>
          ))
        )}
      </StyledAlert>
    </Snackbar>
  );
}
