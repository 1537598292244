import React, { useMemo } from "react";
import { TFunction } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import NoArchivedJobsImg from "../../../assets/no_archived_jobs.png";
import NoJobsImg from "../../../assets/no_jobs.png";
import { IJobListState, JOB_STATUSES } from "../../../models/JobList";
import { resetJobsFilters } from "../../../store_new/reducers/JobList";
import {
  getCurrentStatusJobListFiltersState,
  getJobListNavigationState,
} from "../../../store_new/selectors/JobList";
import JobListNoResultComponent from "./component";
import { RootState } from "../../../store_new/config";
import { getJobData } from "../../../services/Job";
import { openJobEditor } from "../../../store_new/reducers/JobEditor";
import { getCurrentUserData } from "../../../store_new/selectors/CurrentUser";
import { setIsLimitDialogOpen } from "../../../store_new/reducers/dialogs";
import { getCompanySettingsCompanyState } from "../../../store_new/selectors/Company";

const JobListNoResultOverlay = ({
  t,
  onOpenCustomerDialog,
}: {
  readonly t: TFunction;
  readonly onOpenCustomerDialog: () => void;
}) => {
  const dispatch = useDispatch();
  const { selectedJobStatus }: IJobListState["navigation"] = useSelector(
    getJobListNavigationState
  );

  const { isAgency } = useSelector(getCurrentUserData);

  const { company } = useSelector(getCompanySettingsCompanyState);

  const { jobType, seniority, quickSearch, locations } = useSelector(
    getCurrentStatusJobListFiltersState
  );

  const { jobData } = useSelector((state: RootState) => state.job); //TODO Refactor jobslice ...

  const hasFilters = useMemo(
    () => Boolean(jobType.value || seniority.value || quickSearch || locations),
    [jobType, seniority, quickSearch, locations]
  );

  const actionText = useMemo(
    () => t(`joblist.${hasFilters ? "reset_filters_button" : "empty_button"}`),
    [hasFilters]
  );

  const bodyText = useMemo(
    () =>
      hasFilters
        ? ""
        : selectedJobStatus === "active"
        ? t("joblist.empty_second_text")
        : "",
    [hasFilters]
  );

  const titleText = useMemo(
    () =>
      t(
        `joblist.${
          hasFilters
            ? "empty_first_text_filters_exists"
            : selectedJobStatus === "active"
            ? "empty_first_text"
            : selectedJobStatus === "archived"
            ? "no_archived_job_title"
            : "no_draft_job_title"
        }`
      ),
    [hasFilters]
  );
  const imgSrc = useMemo(
    () =>
      selectedJobStatus === JOB_STATUSES.ACTIVE ? NoJobsImg : NoArchivedJobsImg,
    [selectedJobStatus]
  );

  const handleOnOpenCreateJobDialog = () => {
    if (Object.keys(jobData).length < 1) {
      dispatch<any>(getJobData()).then((res: any) => {
        if (res.type === "getJobData/fulfilled") {
          if (isAgency) {
            onOpenCustomerDialog();
          } else if (company?.jobs_creation_allowed) {
            dispatch(openJobEditor());
          } else {
            dispatch(setIsLimitDialogOpen(true));
          }
        }
      });
    } else {
      if (isAgency) {
        onOpenCustomerDialog();
      } else if (company?.jobs_creation_allowed) {
        dispatch(openJobEditor());
      } else {
        dispatch(setIsLimitDialogOpen(true));
      }
    }
  };

  const handleOnResetFilters = () => {
    dispatch(resetJobsFilters());
  };

  return (
    <JobListNoResultComponent
      actionText={actionText}
      bodyText={bodyText}
      hasFilters={hasFilters}
      titleText={titleText}
      imgSrc={imgSrc}
      onResetFilters={handleOnResetFilters}
      onToggleDialog={handleOnOpenCreateJobDialog}
      showButton={selectedJobStatus === "active" ? true : false}
    />
  );
};

export default JobListNoResultOverlay;
