import React from "react";
import { Stack, Typography, Button, Box } from "@mui/material";
import { TFunction } from "i18next";
import BuyProductDialog from "../../components/dialogs/BuyProductDialog";
import PackageProduct from "./PackageProduct";
import {
  StyledPremiumPackagesProductsContainer,
  StyledProductBodyItem,
} from "../SingleJob_new/styles";
import { TPremiumPackage, TSalesProduct } from "../../models/SingleJob";

const PackagePageComponent = ({
  t,
  activeProduct,
  packages,
  onOpenBuyProductDialog,
}: {
  readonly t: TFunction;
  readonly activeProduct: TPremiumPackage;
  readonly packages: TPremiumPackage[] | null;
  readonly onOpenBuyProductDialog: () => void;
}) => {
  return (
    <StyledPremiumPackagesProductsContainer packages={packages}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Stack
          padding="8px 12px"
          width="48%"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ background: "#F9FAFB" }}
        >
          <Typography variant="body2" color="#374151" fontWeight={500}>
            {t("create_job_fourth_step.price")}:
          </Typography>

          <StyledProductBodyItem
            padding="0px !important"
            gap={2}
            sx={{ background: "transparent" }}
          >
            <Box className={"old-price"}>{activeProduct?.oldPrice} €</Box>
            <Box className={"new-price"}>{activeProduct?.package_price} €</Box>
          </StyledProductBodyItem>
        </Stack>
        <Stack width="50%" alignItems="flex-end">
          <Button variant="contained" onClick={onOpenBuyProductDialog}>
            {t("create_job_fourth_step.add_package")}
          </Button>
        </Stack>
      </Stack>
      <Stack direction="row" flexWrap="wrap" mt={3} gap={2}>
        {activeProduct?.sales_products?.map((productItem: TSalesProduct) => (
          <Stack key={productItem.id} width="49%">
            <PackageProduct t={t} productItem={productItem} />
          </Stack>
        ))}
      </Stack>
      <BuyProductDialog />
    </StyledPremiumPackagesProductsContainer>
  );
};

export default PackagePageComponent;
