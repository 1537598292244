import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";
import { getCurrentUserState } from "../../store_new/selectors/CurrentUser";
import JobWidgetComponent_NewPage from "./component";

export default function JobWidgetPageNew({
  desktop,
}: {
  readonly desktop: boolean;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const { userData } = useSelector(getCurrentUserState);
  const baseURL = window.location.origin.includes("app.karriera")
    ? "https://be.app.karriera.de/widget/"
    : "https://be.staging.karriera.de/widget/";
  const fullJobWidgetLink = `${baseURL}${userData.companyUrlKey}`;
  const code = `<!DOCTYPE html>
<html>
  <body>
    <iframe
      id="karriera-iframe"
      src=${fullJobWidgetLink}
      frameborder="0"
      width="100%"
    ></iframe>
    <script>
      window.addEventListener("message", function (event) {
        const iframeElement = document.getElementById("karriera-iframe");
        iframeElement.style.height = event.data;
      });
    </script>
  </body>
</html>
`;

  const handleCopyCode = () => {
    navigator.clipboard.writeText(code);
    dispatch(
      addMessage({
        type: "success",
        title: t("company_settings.copy_message"),
      })
    );
  };

  return (
    <JobWidgetComponent_NewPage
      t={t}
      onCopyCode={handleCopyCode}
      desktop={desktop}
      code={code}
      isPreviewDialogOpen={isPreviewDialogOpen}
      setIsPreviewDialogOpen={setIsPreviewDialogOpen}
    />
  );
}
