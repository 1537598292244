import React from "react";
import {
  Skeleton,
  Stack,
  Box,
  Avatar,
  Typography,
  Divider,
} from "@mui/material";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { IMobileChatTopHeaderComponent } from "../../../models/TopHeader";
import {
  StyledHeaderTitle,
  StyledHeaderTitleContainer,
  StyledMobileMessagesHeaderSubtitles,
} from "../styles";
import { StyledSmallMobileButton, generateFullDate } from "../../../utils";

const dividerStyle = {
  marginLeft: "-24px !important",
  marginRight: "-24px !important",
  borderColor: "#E5E7EB",
};

const MobileChatTopHeaderComponent = ({
  t,
  messagesInfo,
  isLoading,
  isCandidate,
  desktop,
  handleOpenCandidate,
  onNavigateBack,
}: IMobileChatTopHeaderComponent) => (
  <Stack spacing={2} width={window.innerWidth - 54}>
    {isLoading ? (
      <Skeleton variant={"rounded"} width={200} height={30} />
    ) : (
      <Stack direction="row" spacing={2} width="100% !important">
        <Stack width="fit-content">
          <ArrowLeftIcon
            style={{
              cursor: "pointer",
              width: 24,
              height: 24,
            }}
            onClick={onNavigateBack}
          />
        </Stack>
        <Avatar src={messagesInfo?.profile_photo as string} />
        <Stack direction="row" spacing={1} width="70%">
          <Stack spacing={0.5} width="100%">
            <StyledHeaderTitleContainer direction={"row"} spacing={1.5}>
              <StyledHeaderTitle
                desktop={desktop}
                direction="row"
                maxWidth="67vw !important"
                alignItems="flex-start !important"
                spacing={1}
              >
                <Box width="63%" overflow="hidden" textOverflow="ellipsis">
                  {messagesInfo?.firstname} {messagesInfo?.lastname}
                </Box>
                <StyledSmallMobileButton
                  variant="contained"
                  onClick={handleOpenCandidate}
                >
                  {isCandidate
                    ? t("messages_page.show_job_details")
                    : t("messages_page.show_profile")}
                </StyledSmallMobileButton>
              </StyledHeaderTitle>
            </StyledHeaderTitleContainer>
            {isCandidate && (
              <Stack width="90%">
                <Typography
                  fontSize={12}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="#9CA3AF"
                >
                  {t("messages_page.from")} {messagesInfo?.company_name}
                </Typography>
              </Stack>
            )}
            <StyledMobileMessagesHeaderSubtitles>
              <span className="job-title-text">
                {t("messages_page.applied_to_job_one")}
              </span>
              <span className="job-title">{messagesInfo?.job_title}</span>
              <span className="job-title-text">
                {t("messages_page.on")}{" "}
                {generateFullDate(messagesInfo?.applied_date)}
              </span>
            </StyledMobileMessagesHeaderSubtitles>
          </Stack>
        </Stack>
      </Stack>
    )}

    <Divider sx={dividerStyle} />
  </Stack>
);

export default MobileChatTopHeaderComponent;
