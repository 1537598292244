import React from "react";
import {
  Autocomplete,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Flag from "react-country-flag";
import {
  StyledInputContainer,
  StyledInputFlagContainer,
  StyledOptionContainer
} from "../../../../components/filters_new/styles";
import { Controller } from "react-hook-form";
import { StyledAdditionalQuestionsFieldContainer } from "../../styles";
import { IAdditionalQuestionsLocationsComponent } from "../../../../models/JobPreview";

const AdditionalQuestionsLocationsComponent = ({
  t,
  control,
  cities,
  desktop,
  countryCode,
  onSearch,
  onUpdateCountryCode
}: IAdditionalQuestionsLocationsComponent) => (
  <StyledAdditionalQuestionsFieldContainer desktop={desktop}>
    <Stack className={"title"}>
      <Typography variant="subtitle2">
        {t("additional_questions.location")}
      </Typography>
    </Stack>
    <Controller
      name={"city"}
      control={control}
      render={({ field: { onChange } }) => (
        <Box className={"input-field"}>
          <Autocomplete
            noOptionsText={t("additional_questions.city_no_options")}
            options={cities}
            getOptionLabel={(option) =>
              option?.state
                ? `${option.name}, ${option.state},  ${option.country}`
                : `${option.name},  ${option.country}`
            }
            onInputChange={(_, value) => {
              onSearch(value);
            }}
            onChange={(_, value) => {
              onChange(value?.name || "");
              onUpdateCountryCode(value?.countrycode || "");
            }}
            renderInput={(params) => (
              <StyledInputContainer flags={Boolean(countryCode)}>
                <TextField
                  {...params}
                  placeholder={t("update_company_information.city") as string}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: countryCode && (
                      <InputAdornment position="start">
                        <StyledInputFlagContainer>
                          <Flag countryCode={countryCode} svg />
                        </StyledInputFlagContainer>
                      </InputAdornment>
                    )
                  }}
                />
              </StyledInputContainer>
            )}
            renderOption={(optionProps, option) => (
              <StyledOptionContainer
                {...optionProps}
                translate="no"
                key={option.countrycode}
              >
                <Box>
                  <Flag countryCode={option.countrycode} svg />
                </Box>
                <Box>
                  <Typography variant="body2" color="textSecondary">
                    {option?.state
                      ? `${option.name}, ${option.state},  ${option.country}`
                      : `${option.name},  ${option.country}`}
                  </Typography>
                </Box>
              </StyledOptionContainer>
            )}
          />
        </Box>
      )}
    />
  </StyledAdditionalQuestionsFieldContainer>
);

export default AdditionalQuestionsLocationsComponent;
