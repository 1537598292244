import React, { useState, useEffect } from "react";
import { Stack, Button, Avatar } from "@mui/material";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store_new/config";
import {
  StyledHeaderContainer,
  StyledLeftHeaderSideContainer,
  StyledRightHeaderSideContainer,
  StyledHeaderJobTitleTypography,
  StyledNameTypography,
  StyledSubtitleTypography
} from "./styles";
import { IHeaderComponent, MESSAGES_DIALOG_TYPE } from "../../models/Messages";
import { generateFullDate } from "../../utils";
import { getAllApplications } from "../../store_new/selectors/Applications";
import {
  setCandidate,
  setCandidateId,
  setCandidateJob
} from "../../slices/Candidate";
import { TApplication } from "../../models/Applications";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate
} from "../../store_new/selectors/CurrentUser";

const HeaderComponent = ({
  t,
  recruiterId,
  messagesInfo,
  onOpenDialog,
  openJob
}: IHeaderComponent) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeCandidate, setActiveCandidate] = useState<TApplication | null>(
    null
  );
  const { formatedApplications } = useSelector(
    (state: RootState) => state.candidate
  ); // TODO refactor 'candidate' reducer/selector/saga
  const isCandidate = useSelector(getCurrentUserIsCandidate);
  const { email } = useSelector(getCurrentUserData);
  const allApplications = useSelector(getAllApplications);
  const jobTitle = messagesInfo?.job_title;

  const handleOpenCandidate = () => {
    if (isCandidate) {
      dispatch(setCandidateJob(activeCandidate));
    } else {
      dispatch(setCandidate(activeCandidate));
      dispatch(
        setCandidateId({
          candidateUrlKey:
            activeCandidate?.candidate_url_key || messagesInfo?.job_url_key,
          candidateJobId: activeCandidate?.job_id || messagesInfo?.job_id
        })
      );
    }
    if (isCandidate) {
      navigate(`/job/${messagesInfo?.job_url_key}`);
    } else {
      navigate(
        `/applications/candidate/${activeCandidate?.candidate_url_key}/${activeCandidate?.job_id}`
      );
    }
  };

  useEffect(() => {
    if (isCandidate) {
      const findCandidate: any = formatedApplications.find(
        (item: TApplication) => {
          return item.id == messagesInfo?.application_id;
        }
      );
      if (findCandidate) {
        const fullCandidate: TApplication = {
          email,
          ...findCandidate
        };
        setActiveCandidate(fullCandidate);
      }
    } else {
      const findCandidate = allApplications?.find(
        (item) => Number(item?.application_id) == messagesInfo?.application_id
      );
      if (findCandidate) {
        setActiveCandidate(findCandidate);
      }
    }
  }, [messagesInfo]);

  return (
    <StyledHeaderContainer>
      <StyledLeftHeaderSideContainer>
        <StyledNameTypography>Chat</StyledNameTypography>
        {isCandidate === false && (
          <Button
            variant="outlined"
            startIcon={
              <PlusCircleIcon
                width={20}
                height={20}
                stroke="#178CF2"
                strokeWidth={1.2}
              />
            }
            onClick={() =>
              onOpenDialog({ type: MESSAGES_DIALOG_TYPE.NEW_CHAT })
            }
          >
            {t("messages_page.new_chat")}
          </Button>
        )}
      </StyledLeftHeaderSideContainer>
      {recruiterId && messagesInfo && (
        <StyledRightHeaderSideContainer>
          <Stack direction="row" justifyContent="center" spacing={1.25}>
            <Avatar src={messagesInfo?.profile_photo} />
            <Stack height="100%" justifyContent="space-between">
              <Stack direction="row" spacing={0.5}>
                <StyledNameTypography>
                  {messagesInfo?.firstname} {messagesInfo?.lastname}{" "}
                </StyledNameTypography>
                {isCandidate && messagesInfo && (
                  <StyledNameTypography>
                    - {messagesInfo?.company_name}
                  </StyledNameTypography>
                )}
              </Stack>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <StyledSubtitleTypography>
                  {t("messages_page.applied_to_job_one")}{" "}
                </StyledSubtitleTypography>
                <StyledHeaderJobTitleTypography onClick={openJob}>
                  {jobTitle}{" "}
                </StyledHeaderJobTitleTypography>
                <StyledSubtitleTypography>
                  {t("messages_page.on")}{" "}
                  {generateFullDate(messagesInfo?.applied_date)}
                </StyledSubtitleTypography>
              </Stack>
            </Stack>
          </Stack>
          <Button variant="contained" onClick={handleOpenCandidate}>
            {isCandidate
              ? t("messages_page.show_job_details")
              : t("messages_page.show_profile")}
          </Button>
        </StyledRightHeaderSideContainer>
      )}
    </StyledHeaderContainer>
  );
};

export default HeaderComponent;
