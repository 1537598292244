import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import { Avatar } from "@mui/material";
import {
  XCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon
} from "@heroicons/react/24/outline";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../../services";

const Comments = ({ id, comments, timestamp, application_id, fullName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showComments, setShowComments] = useState(false);
  const [updatedComments, setUpdatedComments] = useState(comments);
  const [reply, setReply] = useState([]);
  const [newReply, setNewReply] = useState("");
  const [action, setAction] = useState(false);

  const handleToggle = () => {
    setShowComments((old) => !old);
  };

  const handleReply = (fullName, id) => {
    setReply({ fullName: fullName, note_id: id });
    setShowComments(true);
  };

  const handleCancel = () => {
    setReply([]);
    setNewReply("");
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (newReply) {
      const controller = new AbortController();

      await axiosInstance
        .post(`/company/candidates/add-comment/${application_id}`, {
          note_id: reply.note_id,
          comment: newReply,
          signal: controller.signal
        })
        .then(() => {
          setNewReply("");
          setAction(true);
          setReply([]);
        })
        .catch((err) => console.error(err));
      setShowComments(true);
      controller.abort();
      return;
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const getNotesComments = async () => {
      await axiosInstance
        .post(`/company/candidates/notes/${application_id}`, {
          signal: controller.signal
        })
        .then((res) => {
          const data = res?.data?.data.application_notes;

          const findComments = data.filter((item) => item.note.id === id)[0]
            .comments;
          console.count("Render");
          setUpdatedComments(findComments);
          setAction(false);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            return console.error("Request Aborted");
          }
          console.error(err);
        });
    };

    if (action) {
      getNotesComments();
    }

    return () => {
      controller.abort();
    };
  }, [action]);

  const RenderComents = () => {
    return updatedComments?.map(
      ({ comment, firstname, lastname, timestamp }) => {
        const fullName = firstname + " " + lastname;
        const initials = firstname.charAt(0) + lastname.charAt(0);
        const avatarUrl = "";

        return (
          <div className={classes.notesSection} key={timestamp}>
            <div className={classes.avatar}>
              {avatarUrl ? (
                <Avatar src={""} style={{ width: "35px", height: "35px" }} />
              ) : (
                <p className={classes.initials}>{initials.toUpperCase()}</p>
              )}
            </div>
            <div
              className={classes.note}
              style={{ padding: "0px 12px", background: "unset" }}
              key={timestamp}
            >
              <div className={classes.noteHeader}>
                <p className={classes.fullName}>{fullName}</p>
              </div>

              <div className={classes.noteBody}>{comment}</div>
            </div>
          </div>
        );
      }
    );
  };

  return (
    <>
      {updatedComments.length > 0 && (
        <div style={{ background: "#F3F4F6", padding: "8px 12px" }}>
          {showComments ? (
            <>
              <p className={classes.showComments} onClick={handleToggle}>
                <ChevronUpIcon
                  style={{
                    height: "16px",
                    width: "16px",
                    marginRight: "4px"
                  }}
                />
                {t("candidate.collapse_all")}
              </p>
              <div style={{ marginTop: "20px" }}>
                {updatedComments && <RenderComents />}
              </div>
            </>
          ) : (
            <p className={classes.showComments} onClick={handleToggle}>
              <ChevronDownIcon
                style={{ height: "16px", width: "16px", marginRight: "4px" }}
              />
              {updatedComments.length + " " + t("candidate.comments")}
            </p>
          )}
        </div>
      )}

      <div
        className={classes.noteFooter}
        style={{
          padding: updatedComments.length > 0 ? "12px" : "0px 12px 12px 12px"
        }}
      >
        {reply?.note_id === id ? (
          <form
            onSubmit={(e) => handleSave(e)}
            className={classes.commentSection}
          >
            <input
              name="comment"
              value={newReply}
              placeholder={t("candidate.write_a_note")}
              className={classes.input}
              onChange={(e) => {
                setNewReply(e.target.value);
              }}
              autoFocus
            />
            <XCircleIcon onClick={handleCancel} />
          </form>
        ) : (
          <div className={classes.noteFooter}>
            <p className={classes.date}>
              {moment(timestamp).format("D MMM yyy") +
                ` ${t("at")} ${moment(timestamp).format("hh:mm")}`}
            </p>
            <p
              className={classes.replay}
              onClick={() => {
                handleReply(fullName, id);
              }}
            >
              {t("candidate.reply")}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Comments;
