import React from "react";
import { Stack, Typography, Tooltip } from "@mui/material";
import { PlayCircleIcon } from "@heroicons/react/24/solid";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import ShowIcon from "./ShowIcon";
import IncompleteDeleteData from "./IncompleteDeleteData";
import { IAutomationSelectedTrigger } from "../../models/Automation";
import { StyledSelectedTriggerAutomationContainer } from "./style";

const SelectedTriggerAutomation = ({
  t,
  data,
  variant,
  currentAutomation,
  hideWhenThenText,
  subText,
  index,
  onDeleteTriggerAction,
  onHoverLeaveDeleteActionIcon,
  onClick = () => {},
}: IAutomationSelectedTrigger) => (
  <Tooltip
    title={
      variant !== "actionList"
        ? ""
        : data.name === "Send Email"
        ? ""
        : data.name === "E-Mail senden"
        ? ""
        : t("automation.comingSoon")
    }
  >
    <StyledSelectedTriggerAutomationContainer
      className="selected-trigger"
      variant={variant}
      data={data}
      currentAutomation={currentAutomation}
      onClick={() => {
        if (
          variant === "triggersList" ||
          variant === "addActionContainer" ||
          data.name === "Send Email" ||
          data.name === "E-Mail senden"
        ) {
          onClick(variant === "component" ? data : data.name);
        }
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <Stack
          height={40}
          width={40}
          alignItems="center"
          justifyContent="center"
          borderRadius={1.5}
          sx={{
            backgroundColor:
              variant === "trigger"
                ? "#ECFDF5"
                : variant === "triggersList"
                ? "#EEF2FF"
                : variant === "emptyComponent"
                ? "#F3F4F6"
                : "#EFF6FF",
          }}
        >
          {variant === "trigger" ? (
            <PlayCircleIcon height={20} width={20} fill="#059669" />
          ) : variant === "emptyComponent" ? (
            <PaperClipIcon height={20} width={20} stroke="#6B7280" />
          ) : (
            <ShowIcon type={data.const} currentAutomation={currentAutomation} />
          )}
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Stack justifyContent="space-between" spacing={0.5}>
            <Typography
              display="inline"
              variant="body2"
              fontWeight={500}
              color={variant === "trigger" ? "#059669" : "#3B82F6"}
              textTransform="uppercase"
            >
              {variant === "trigger"
                ? t("automation.when")
                : hideWhenThenText
                ? ""
                : index
                ? index === 0
                  ? t("automation.then")
                  : t("automation.and")
                : t("automation.then")}
              {!hideWhenThenText && ":"}{" "}
              <Typography
                textTransform="none"
                display="inline"
                variant="body2"
                fontWeight={500}
                color="#111827"
              >
                {variant === "trigger"
                  ? data.name ?? t("automation.selectTriggerTitle")
                  : data.name}
              </Typography>
            </Typography>
            {subText && (
              <Typography fontSize={12} color="textSecondary">
                {subText}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>

      {!hideWhenThenText && variant !== "actionList" && (
        <IncompleteDeleteData
          t={t}
          data={data}
          variant={variant}
          onDeleteTriggerAction={onDeleteTriggerAction}
          onHoverLeaveDeleteActionIcon={onHoverLeaveDeleteActionIcon}
          currentAutomation={currentAutomation}
        />
      )}
    </StyledSelectedTriggerAutomationContainer>
  </Tooltip>
);

export default SelectedTriggerAutomation;
