// TODO REFACTOR (convert this reducer according to the new redux flow)

import { createSlice } from "@reduxjs/toolkit";
import {
  getCompanyInfo,
  getPendingCompanyMembers,
  getJoinedCompanyMembers,
  inviteMembers,
  getCompanyData,
  updateCompanyProfile
} from "../../services/Company";
import { fetchCountries } from "../../services/utils";

const SLICE_NAME = "company";

const initialState = {
  countries: [],
  loading: false,
  error: false,
  company: {
    color: "#178CF2"
  },
  filters: {},
  filter: {
    type: [],
    location: []
  },
  invite_loading: false,
  pending_members: undefined,
  pending_members_page: 1,
  pending_members_filter: {
    search: ""
  },
  pending_total_count: 0,
  joined_members: undefined,
  joined_members_page: 1,
  joined_members_filter: {
    search: ""
  },
  joined_total_count: 0,
  pagenum: 1,
  pagesize: 10,
  companyData: undefined,
  companyUpdateDataLoading: false
};

export const companySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPageNum: (state, { payload }) => {
      state.pagenum = payload;
    },
    resetCountries: (state) => {
      state.countries = initialState.countries;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPendingCompanyMembers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getPendingCompanyMembers.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(
      getPendingCompanyMembers.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.pending_total_count = payload.data.totalCount;
        state.pending_members = payload.data.invitations;
      }
    );
    builder.addCase(getJoinedCompanyMembers.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getJoinedCompanyMembers.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getJoinedCompanyMembers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.joined_members = payload.data.members;
      state.joined_total_count = payload.data.totalCount;
    });
    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      const allCountries = Object.entries(payload.data.countries).map(
        (entry) => ({
          key: entry[0],
          value: entry[1]
        })
      );
      state.countries = allCountries;
    });
    builder.addCase(inviteMembers.pending, (state) => {
      state.invite_loading = true;
    });
    builder.addCase(inviteMembers.rejected, (state) => {
      state.invite_loading = false;
    });
    builder.addCase(inviteMembers.fulfilled, (state) => {
      state.invite_loading = false;
    });
    builder.addCase(getCompanyData.fulfilled, (state, { payload }) => {
      state.companyData = payload.data.company;
    });
    builder.addCase(getCompanyInfo.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getCompanyInfo.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getCompanyInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.company = {
        ...payload.data.company,
        company_color: payload.data.color || initialState.company.color
      };
      const locations = [];
      for (const key in payload.data.filters.locations) {
        locations.push({
          id: key,
          title: payload.data.filters.locations[key]
        });
      }

      const types = [];
      for (const key in payload.data.filters.work_fields) {
        types.push({
          id: key,
          title: payload.data.filters.work_fields[key]
        });
      }
      state.filters = {
        position_types: types,
        locations
      };
    });
    builder.addCase(updateCompanyProfile.pending, (state) => {
      state.companyUpdateDataLoading = true;
    });
    builder.addCase(updateCompanyProfile.rejected, (state) => {
      state.companyUpdateDataLoading = false;
    });
    builder.addCase(updateCompanyProfile.fulfilled, (state) => {
      state.companyUpdateDataLoading = false;
    });
  }
});

export const { setPageNum, resetCountries } = companySlice.actions;
