import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { getSitemap } from "../../services/utils";

const initialState = {
  notifications: [],
  sitemap: undefined,
  sitemapLoading: false,
  isPasswordNotificationShowed: false,
};

const SLICE_NAME = "globalNotifications";

export const globalNotificationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    addMessage: (state, { payload }) => {
      const notification = {
        uuid: uuidv4(),
        type: payload.type,
        title: payload.title || "",
        message: payload.message || "",
        visibilityTime:
          payload.visibilityTime === "allTime"
            ? null
            : payload.visibilityTime || 4000,
        source: payload.source,
        icon: payload.icon,
        action: payload.action,
        firstButtonText: payload.firstButtonText,
        onFirstButtonClick: payload.onFirstButtonClick,
      };
      state.notifications.push(notification);
    },
    deleteMessage: (state, { payload }) => {
      const index = state.notifications.findIndex(
        (value) => value.uuid === payload
      );
      if (index === -1) {
        return;
      }

      state.notifications.splice(index, 1);
    },
    turnOffNotifications: (state) => {
      state.notifications = initialState.notifications;
    },
    setPasswordNotificationShowed: (state) => {
      state.isPasswordNotificationShowed = true;
    },
    setNotifications: (state) => {
      const lastNotifications =
        state.notifications[state.notifications.length - 1];
      state.notifications = [lastNotifications];
    },
    setCreatePasswordSnackbar: (state, { payload }) => {
      const notifications = [...state.notifications];
      notifications[0] = {
        ...notifications[0],
        firstButtonText: payload.firstButtonText,
        title: payload.title,
      };
      state.notifications = notifications;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSitemap.pending, (state) => {
      state.sitemapLoading = true;
    });
    builder.addCase(getSitemap.rejected, (state) => {
      state.sitemapLoading = false;
    });
    builder.addCase(getSitemap.fulfilled, (state, { payload }) => {
      state.sitemapLoading = false;
      state.sitemap = payload.data;
    });
  },
});

export const {
  addMessage,
  deleteMessage,
  turnOffNotifications,
  setPasswordNotificationShowed,
  setCreatePasswordSnackbar,
  setNotifications,
} = globalNotificationSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const globalNotificationSliceSelectors = {
  notifications: (rootState) => {
    const appState = getAppState(rootState);
    return appState.notifications;
  },
  sitemap: (rootState) => {
    const appState = getAppState(rootState);
    return appState.sitemap;
  },
};
