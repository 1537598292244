import { createSlice } from "@reduxjs/toolkit";
import { navigate } from "@gatsbyjs/reach-router";
import { logIn, logOut, getMe } from "../../services/Login";
import { recoveryPassword, resetPassword } from "../../services/Password";
import { candidateLogin } from "../../services/Job";

const SLICE_NAME = "user";

const storageToken = localStorage.getItem("token");

const initialState = {
  loading: false,
  error: false,
  token: storageToken || null,
  username: undefined,
  firstname: undefined,
  lastname: undefined,
  email: undefined,
  phonenumber: undefined,
  profile_picture: undefined,
  photo: undefined,
  lang: undefined,
  company_id: undefined,
  is_admin: undefined,
  memberId: undefined,
  is_disabled: 0,
  is_activated: 1,
  is_candidate: undefined,
  company_url_key: undefined,
  previewImage: null,
  linkedin_url: undefined,
  xing_url: undefined,
  noPassword: undefined,
  isAgency: undefined,
  jassId: undefined
};

export const userSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPreviewImage(state, { payload }) {
      state.previewImage = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(candidateLogin.fulfilled, (state, { payload }) => {
      state.token = payload.data.token;
      localStorage.setItem("token", payload.data.token);
    });
    builder.addCase(logIn.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(logIn.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(logIn.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.token = payload.data.token;
      localStorage.setItem("token", payload.data.token);
    });
    builder.addCase(getMe.pending, (state) => {
      state.error = false;
    });
    builder.addCase(getMe.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getMe.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.username =
        payload?.data?.candidate?.username || payload?.data?.customer?.username;
      state.firstname =
        payload?.data?.candidate?.firstname ||
        payload?.data?.customer?.firstname;
      state.lastname =
        payload?.data?.candidate?.lastname || payload?.data?.customer?.lastname;
      state.is_admin = payload?.data?.customer?.is_admin;
      state.phonenumber = payload?.data?.customer?.phonenumber;
      state.email = payload?.data?.candidate?.email;
      state.photo = payload?.data?.candidate?.photo;
      state.profile_picture = payload?.data?.customer?.photo;
      state.cv = payload?.data?.candidate?.latest_cv_link;
      state.linkedin_url = payload?.data?.candidate?.linkedin_url;
      state.xing_url = payload?.data?.candidate?.xing_url;
      state.company_url_key = payload?.data?.customer?.company_url_key;
      state.lang =
        payload?.data?.candidate?.lang || payload?.data?.customer?.lang;
      state.memberId = payload?.data?.customer?.member_id;
      state.is_candidate = payload.data.candidate ? true : false;
      state.noPassword = payload?.data?.candidate?.noPassword || undefined;
      state.isAgency = payload?.data?.customer?.is_agency;
      state.jassId = payload?.data?.customer?.jass_id;
    });
    builder.addCase(logOut.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logOut.fulfilled, (state) => {
      for (const key in state) {
        if (key !== "loading") {
          state[key] = initialState[key];
        }
      }
      localStorage.removeItem("token");
      navigate("/login");
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(recoveryPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(recoveryPassword.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(recoveryPassword.fulfilled, (state) => {
      state.loading = false;
    });
  }
});

const getAppState = (state) => state[SLICE_NAME];

export const { setPreviewImage } = userSlice.actions;
export const userSliceSelectors = {
  loading: (rootState) => {
    const appState = getAppState(rootState);
    return appState.loading;
  },
  error: (rootState) => {
    const appState = getAppState(rootState);
    return appState.error;
  },
  isLoged: (rootState) => {
    const appState = getAppState(rootState);
    return appState.isLoged;
  },
  defaultUserFields: (rootState) => {
    const appState = getAppState(rootState);
    return {
      company_id: appState.company_id,
      username: appState.username,
      firstname: appState.firstname,
      lastname: appState.lastname,
      email: appState.username,
      phone: appState.phone,
      lang: appState.lang,
      profile_picture: appState.profile_picture,
      photo: appState.photo,
      company_url_key: appState.company_url_key,
      is_candidate: appState.is_candidate,
      memberId: appState.memberId,
      noPassword: appState.noPassword,
      jassId: appState.jassId
    };
  },
  token: (rootState) => {
    const appState = getAppState(rootState);
    return appState.token;
  }
};
