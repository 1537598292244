import React from "react";
import { Stack } from "@mui/material";
import Header from "./AutomationHeader";
import AutomationInfo from "./AutomationInfo";
import SelectionContainer from "./SelectionContainer";
import { IAutomationComponent } from "../../models/Automation";
import { StyledCreateAutomationContainer } from "./style";

const AutomationComponent = ({
  t,
  currentAutomation,
  triggers,
  triggersGroup,
  activeTriggerGroup,
  isTriggersLoading,
  isAddActionContainer,
  selectedAction,
  isCurrentAutomationCompleted,
  onSaveUpdateAutomation,
  onHoverLeaveDeleteActionIcon,
  onEnabledDisabledAutmation,
  onDeleteTriggerAction,
  onChangeCurrentAutomationName,
  onOpenAction,
  onOpenSelectActionContainer,
  onChangeActiveTrigger,
  onSelectTrigger,
}: IAutomationComponent) => (
  <StyledCreateAutomationContainer>
    <Header
      t={t}
      currentAutomation={currentAutomation}
      isCurrentAutomationCompleted={isCurrentAutomationCompleted}
      onEnabledDisabledAutmation={onEnabledDisabledAutmation}
      onChangeCurrentAutomationName={onChangeCurrentAutomationName}
      onSaveUpdateAutomation={onSaveUpdateAutomation}
    />
    <Stack direction="row" mt={3} spacing={3} height="100%">
      <AutomationInfo
        t={t}
        currentAutomation={currentAutomation}
        onOpenAction={onOpenAction}
        onDeleteTriggerAction={onDeleteTriggerAction}
        onHoverLeaveDeleteActionIcon={onHoverLeaveDeleteActionIcon}
      />
      <SelectionContainer
        t={t}
        currentAutomation={currentAutomation}
        triggers={triggers}
        triggersGroup={triggersGroup}
        activeTriggerGroup={activeTriggerGroup}
        isTriggersLoading={isTriggersLoading}
        isAddActionContainer={isAddActionContainer}
        selectedAction={selectedAction}
        onOpenSelectActionContainer={onOpenSelectActionContainer}
        onChangeActiveTrigger={onChangeActiveTrigger}
        onSelectTrigger={onSelectTrigger}
      />
    </Stack>
  </StyledCreateAutomationContainer>
);

export default AutomationComponent;
