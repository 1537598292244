import React, { useEffect } from "react";
import { Stack, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { getJobData } from "../../services/Job";

const useStyles = makeStyles(() => ({
  divider: {
    position: "relative",
    top: 17,
    height: 2,
    borderColor: "#E5E7EB"
  },
  transparentDivider: {
    position: "relative",
    top: 17,
    height: 2,
    borderColor: "transparent"
  },
  checkIcon: {
    width: 24,
    height: 24,
    fill: "transparent",
    stroke: "#F9FAFB"
  }
}));

export const ActiveStep = () => (
  <Stack spacing={1}>
    <Stack
      width={36}
      height={36}
      borderRadius="50%"
      border="2px solid #818CF8"
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        width={12}
        height={12}
        borderRadius="50%"
        backgroundColor="#818CF8"
      ></Stack>
    </Stack>
  </Stack>
);

export const Step = () => (
  <Stack spacing={1}>
    <Stack
      width={36}
      height={36}
      borderRadius="50%"
      border="2px solid #E5E7EB"
      alignItems="center"
      justifyContent="center"
    ></Stack>
  </Stack>
);

export const CompletedStep = () => {
  const classes = useStyles();

  return (
    <Stack spacing={1}>
      <Stack
        width={36}
        height={36}
        borderRadius="50%"
        backgroundColor="#818CF8"
        alignItems="center"
        justifyContent="center"
      >
        <CheckIcon className={classes.checkIcon} />
      </Stack>
    </Stack>
  );
};

export const Header = ({
  t,
  stepsText = [
    t("create_job_first_step.describe_job"),
    t("create_job_first_step.specify_job"),
    t("create_job_first_step.application_process")
  ],
  stepsStatus = ["inactive", "inactive", "inactive"]
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { jobData } = useSelector((state) => state.job);

  useEffect(() => {
    if (Object.keys(jobData).length < 1) {
      dispatch(getJobData());
    }
  }, []);

  return (
    <Stack mt={7} px="10%" spacing={1}>
      <Stack direction="row" width="92%" justifyContent="space-between">
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Stack direction="row" width="100%" justifyContent="center">
            <Stack width="100%">
              <Divider className={classes.transparentDivider} />
            </Stack>
            {stepsStatus[0] === "active" ? (
              <ActiveStep />
            ) : stepsStatus[0] === "completed" ? (
              <CompletedStep />
            ) : (
              <Step />
            )}
            <Stack width="100%">
              <Divider className={classes.divider} />
            </Stack>
          </Stack>
          <Typography variant="body3" noWrap>
            {stepsText[0]}
          </Typography>
        </Stack>
        <Stack width="100%">
          <Divider className={classes.divider} />
        </Stack>
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Stack direction="row" width="100%" justifyContent="center">
            <Stack width="100%">
              <Divider className={classes.divider} />
            </Stack>
            {stepsStatus[1] === "active" ? (
              <ActiveStep />
            ) : stepsStatus[1] === "completed" ? (
              <CompletedStep />
            ) : (
              <Step />
            )}
            <Stack width="100%">
              <Divider className={classes.divider} />
            </Stack>
          </Stack>
          <Typography variant="body3" noWrap>
            {stepsText[1]}
          </Typography>
        </Stack>
        <Stack width="100%">
          <Divider className={classes.divider} />
        </Stack>
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Stack direction="row" width="100%" justifyContent="center">
            <Stack width="100%">
              <Divider className={classes.divider} />
            </Stack>
            {stepsStatus[2] === "active" ? (
              <ActiveStep />
            ) : stepsStatus[2] === "completed" ? (
              <CompletedStep />
            ) : (
              <Step />
            )}
          </Stack>
          <Stack width="100%" position="relative" left="30%">
            <Typography variant="body3" noWrap>
              {stepsText[2]}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
