import React from "react";
import {
  Squares2X2Icon,
  ListBulletIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { Box, Button, Stack } from "@mui/material";
import SelectorFilter from "../../../components/filters_new/SelectorFilter";
import SimpleFilter from "../../../components/filters_new/SimpleFilter";
import AutocompleteFilter from "../../../components/filters_new/AutocompleteFilter";
import { IApplicationsFilters } from "../../../models/Applications";
import {
  StyledApplicationsFilterContainer,
  StyledAddApplicantsButton,
} from "../styles";
import { StyledMenuButton } from "../../../components/TopHeader_new/styles";

const ApplicationsFiltersComponent = ({
  t,
  jobTypeFilter,
  stateFilter,
  quickSearch,
  jobId,
  location,
  view,
  allJobs,
  allLocations,
  isAgency,
  customers,
  companyFilter,
  filtersWidth,
  cardsApplications,
  onOpenApplyJobDialog,
  onChangeFilters,
  onChangeView,
  onOpenSetWorkflowDialog,
}: IApplicationsFilters) => (
  <StyledApplicationsFilterContainer
    isAgency={isAgency}
    filtersWidth={filtersWidth}
    view={view}
    jobId={jobId}
    cardsApplications={cardsApplications}
  >
    <Box className={"quick-search-container"}>
      <SimpleFilter
        className="applications-search-autocomplete"
        value={quickSearch}
        onChange={(value) =>
          onChangeFilters({ value, filterType: "quickSearch" })
        }
        placeholder={t("applications.search_placeholder")}
      />
      {!!isAgency && !jobId && (
        <AutocompleteFilter
          listBoxProps={{
            className: "applications-jobs-autocomplete",
          }}
          onChange={(value: any) =>
            onChangeFilters({ value, filterType: "company" })
          }
          value={companyFilter.value}
          options={customers}
          placeholder={t("joblist.search_by_company_placeholder")}
        />
      )}
      {jobId && (
        <Stack direction="row" ml={2}>
          <StyledMenuButton
            customStroke={view === "table" ? "#178CF2" : "#6B7280"}
            onClick={() => onChangeView("table")}
          >
            <ListBulletIcon />
          </StyledMenuButton>
          <StyledMenuButton
            customStroke={view === "cards" ? "#178CF2" : "#6B7280"}
            onClick={() => onChangeView("cards")}
          >
            <Squares2X2Icon />
          </StyledMenuButton>
        </Stack>
      )}
    </Box>
    <Stack
      direction={"row"}
      className={"advanced-filters-container"}
      spacing={4}
      alignItems="flex-end"
    >
      {view !== "cards" && (
        <Box
          className={"location-search-container"}
          maxWidth={view === "cards" ? "45%" : "100%"}
        >
          <AutocompleteFilter
            listBoxProps={{
              className: "applications-jobs-autocomplete",
            }}
            onChange={(value: any) =>
              onChangeFilters({ value, filterType: "location" })
            }
            value={location}
            options={allLocations || []}
            placeholder={t("joblist.location_placeholder")}
          />
        </Box>
      )}
      {!jobId && (
        <AutocompleteFilter
          listBoxProps={{
            className: "applications-jobs-autocomplete",
          }}
          onChange={(value: any) => {
            onChangeFilters({ value, filterType: "appliedTo" });
          }}
          value={jobTypeFilter.value}
          options={allJobs || []}
          placeholder={t("applications.job_placeholder")}
        />
      )}

      {view === "table" && (
        <SelectorFilter
          isDisabled={!stateFilter.options.length}
          className={"input-selector-filter"}
          label={t("applications.status_placeholder")}
          value={stateFilter.value}
          options={stateFilter.options}
          onChange={(value: string) => {
            onChangeFilters({ value, filterType: "status" });
          }}
        />
      )}
      {jobId && (
        <Stack>
          <StyledAddApplicantsButton
            onClick={onOpenApplyJobDialog}
            variant="contained"
            startIcon={<UserPlusIcon />}
          >
            {t("joblist.add")}
          </StyledAddApplicantsButton>
        </Stack>
      )}
      {jobId && !isAgency && (
        <Button
          className="set-workflow-button"
          onClick={onOpenSetWorkflowDialog}
          variant="outlined"
        >
          {t("workflow.setWorkflow")}
        </Button>
      )}
    </Stack>
  </StyledApplicationsFilterContainer>
);

export default ApplicationsFiltersComponent;
