import React from "react";
import {
  Dialog,
  Stack,
  DialogActions,
  Button,
  Typography,
  Divider
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDialogsState } from "../../../store_new/selectors/Dialogs";
import { setIsDuplicateDialogOpen } from "../../../store_new/reducers/dialogs";
import { duplicateJob } from "../../../store_new/reducers/SingleJob";
import { fetchJobs } from "../../../store_new/reducers/JobList";
import { getJobListNavigationState } from "../../../store_new/selectors/JobList";
import { IJobListState } from "../../../models/JobList";
import {
  addMessage,
  turnOffNotifications
} from "../../../slices/NotificationSlice/GlobalNotificationSlice";
import { ACTION_TYPE } from "../../Snackbar/RenderAction";

const DuplicateJobDialog = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDuplicateDialogOpen, duplicateJobUrlKey } =
    useSelector(getDialogsState);

  const { selectedJobStatus }: IJobListState["navigation"] = useSelector(
    getJobListNavigationState
  );

  const handleCloseDialog = () => {
    dispatch(setIsDuplicateDialogOpen(false));
  };

  const onDuplicateJobSuccess = () => {
    handleCloseDialog();
    if (location.pathname === "/jobs/draft") {
      dispatch(fetchJobs(selectedJobStatus));
    }
    dispatch(
      addMessage({
        type: "success",
        title: t("joblist.duplicate_snackbar_title"),
        action: ACTION_TYPE.CONTAINED_BUTTON_WITH_CLOSE_ICON,
        firstButtonText: t("candidate_dashboard.open_drafts"),
        onFirstButtonClick: () => {
          navigate("/jobs/draft"), dispatch(turnOffNotifications());
        }
      })
    );
  };

  const handleDuplicateJob = () => {
    dispatch(
      duplicateJob({
        urlKey: duplicateJobUrlKey as string,
        callback: () => onDuplicateJobSuccess()
      })
    );
  };

  return (
    <Dialog
      open={isDuplicateDialogOpen}
      onClose={handleCloseDialog}
      PaperProps={{
        elevation: 0
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: 512,
            p: "16px"
          }
        }
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Stack>
          <Typography fontWeight={500}>
            {t("joblist.duplicate_dialog_title")}
          </Typography>
        </Stack>
        <Stack>
          <XMarkIcon
            width={24}
            height={24}
            stroke="#131E30"
            style={{ cursor: "pointer" }}
            onClick={handleCloseDialog}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack my={2.5} spacing={3}>
        <Typography variant="subtitle2" color="textSecondary">
          {t("joblist.duplicate_dialog_subtitle")}
        </Typography>
      </Stack>
      <DialogActions>
        <Button variant="outlined" onClick={handleCloseDialog} autoFocus>
          {t("button.secondCancel")}
        </Button>
        <Button variant="contained" onClick={handleDuplicateJob}>
          {t("joblist.duplicate_dialog_title")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuplicateJobDialog;
