import React from "react";
import RenderAction from "./RenderAction";

const ActionContainer = ({
  desktop,
  action,
  firstButtonText,
  onClose,
  onFirstButtonClick,
  type,
}) => {
  return (
    <RenderAction
      desktop={desktop}
      action={action}
      firstButtonText={firstButtonText}
      onClose={onClose}
      onFirstButtonClick={onFirstButtonClick}
      type={type}
    />
  );
};

export default ActionContainer;
