import React from "react";
import ReactQuill from "react-quill";
import { Divider } from "@mui/material";
import {
  StyledJobPreviewTemplateSectionContainer,
  StyledJobPreviewTemplateSectionContent,
  StyledJobPreviewTemplateSectionTitle,
} from "../styles";

const JobTemplateSection = ({
  title,
  content,
  companyColor,
}: {
  readonly title: string;
  readonly content: string;
  readonly companyColor: string;
}) => (
  <StyledJobPreviewTemplateSectionContainer>
    <StyledJobPreviewTemplateSectionTitle pb={1.5} color={companyColor}>
      {title}
    </StyledJobPreviewTemplateSectionTitle>
    <Divider sx={{ borderColor: "#E5E7EB" }} />
    <StyledJobPreviewTemplateSectionContent pt={3}>
      <ReactQuill
        className={"quill-preview"}
        readOnly
        value={content}
        theme={"bubble"}
      />
    </StyledJobPreviewTemplateSectionContent>
  </StyledJobPreviewTemplateSectionContainer>
);

export default JobTemplateSection;
