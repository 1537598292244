import React from "react";
import { Link, Stack, Typography } from "@mui/material";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import {
  StyledJobPreviewTemplateHeaderContainer,
  StyledJobPreviewTemplateSubtitle,
  StyledJobPreviewTemplateTitle,
} from "../styles";
import { openUrlnNewTab } from "../../../utils";
import { TJobPreviewJob } from "../../../models/JobPreview";

const CompanyHeader = ({
  desktop,
  companyColor,
  logo,
  jobTemplate,
  customerName,
  isAgency,
}: {
  readonly desktop: boolean;
  readonly companyColor: string;
  readonly logo: string | null;
  readonly jobTemplate?: TJobPreviewJob | null;
  readonly customerName?: string;
  readonly isAgency: number;
}) => (
  <StyledJobPreviewTemplateHeaderContainer
    direction="row"
    padding={desktop ? 3 : 2}
  >
    <Stack spacing={3}>
      <Stack direction="row" spacing={3}>
        {logo && <img src={logo} alt={"company-logo"} />}
        <Stack spacing={1.5}>
          <StyledJobPreviewTemplateTitle
            color={companyColor}
            fontSize={desktop ? 24 : 16}
            overflow="hidden"
            textOverflow="ellipsis"
            className="two-line-title"
          >
            {customerName}
          </StyledJobPreviewTemplateTitle>
          {desktop && (
            <StyledJobPreviewTemplateSubtitle
              direction={"row"}
              spacing={1}
              companyColor={companyColor}
            >
              {jobTemplate?.company_website && (
                <Stack direction={"row"} spacing={1} alignItems="center">
                  <GlobeAltIcon />
                  <Typography
                    variant="subtitle2"
                    onClick={() => openUrlnNewTab(jobTemplate?.company_website)}
                    sx={{ cursor: "pointer" }}
                  >
                    <Link
                      color={`${companyColor} !important`}
                      sx={{
                        textDecorationColor: `${companyColor} !important`,
                      }}
                    >
                      {isAgency
                        ? "https://companywebsite.de"
                        : jobTemplate?.company_website}
                    </Link>
                  </Typography>
                </Stack>
              )}
            </StyledJobPreviewTemplateSubtitle>
          )}
        </Stack>
      </Stack>
      {!desktop && (
        <StyledJobPreviewTemplateSubtitle
          direction={"row"}
          spacing={1}
          companyColor={companyColor}
        >
          {jobTemplate?.company_website && (
            <Stack direction={"row"} spacing={1} alignItems="center">
              <GlobeAltIcon
                strokeWidth={1}
                width={20}
                height={20}
                stroke={companyColor}
              />
              <Typography
                variant="subtitle2"
                onClick={() => openUrlnNewTab(jobTemplate?.company_website)}
              >
                <Link
                  color={`${companyColor} !important`}
                  sx={{
                    textDecorationColor: `${companyColor} !important`,
                  }}
                >
                  {jobTemplate?.company_website}
                </Link>
              </Typography>
            </Stack>
          )}
        </StyledJobPreviewTemplateSubtitle>
      )}
    </Stack>
  </StyledJobPreviewTemplateHeaderContainer>
);

export default CompanyHeader;
