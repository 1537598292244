import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "..";

export const updateCandidateProfile = createAsyncThunk(
  "updateCandidateProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/candidate/update-profile`,
        data
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCandidatePhoto = createAsyncThunk(
  "updateCandidatePhoto",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/candidate/upload-photo`,
        props
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getApplications = createAsyncThunk(
  "getApplications",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/candidate/applications`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLatestCandidateMessages = createAsyncThunk(
  "getLatestCandidateMessages",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/candidate/messages/get-latest`
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCandidateMessages = createAsyncThunk(
  "getCandidateMessages",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/candidate/messages/list/`,
        props
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const refreshCandidateMessages = createAsyncThunk(
  "refreshCandidateMessages",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/candidate/messages/list/`,
        props
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
