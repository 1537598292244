import React from "react";
import { Switch } from "@mui/material";
import {
  CellClickedEvent,
  ColDef,
  ICellRendererParams,
  ValueGetterParams,
} from "ag-grid-community";
import ActionsCell from "../cellRenderers/actions";
import iconAndText from "../cellRenderers/iconAndText";
import { TDataGridGetColDef } from "../../../models/DataGrid";
import { generateCandidateDate } from "../../../utils";

const getAutomationsColDef = ({
  t,
  actions,
}: Pick<TDataGridGetColDef, "t" | "actions">): ColDef[] => [
  {
    field: "name",
    headerName: "name",
    cellClass: "ag-grid-cell-clickable",
    flex: 1,
    valueGetter: (params: ValueGetterParams) => params.data.name,
    onCellClicked: (params: CellClickedEvent) => {
      actions.onClick && actions.onClick(params.data);
    },
  },
  {
    field: "creator",
    headerName: t("automation.createdBy") as string,
    flex: 1,
    cellClass: "ag-grid-cell-clickable",
    cellRenderer: (params: ICellRendererParams) =>
      iconAndText({
        src: params.data.photo,
        text: `${params.data.firstname} ${params.data.lastname}`,
        variant: "avatar",
      }),
  },
  {
    field: "updated",
    headerName: t("automation.lastUpdated") as string,
    flex: 0.75,
    valueGetter: (params: ValueGetterParams) =>
      generateCandidateDate(params.data.updated_at),
  },
  {
    field: "status",
    headerName: "Status",
    flex: 0.7,
    cellRenderer: (params: ValueGetterParams) => (
      <Switch
        checked={params.data.enabled}
        onChange={() => {
          actions.onChangeEnabledDisabledStatus &&
            actions.onChangeEnabledDisabledStatus(params.data);
        }}
      />
    ),
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    flex: 0.3,
    maxWidth: 70,
    cellStyle: { justifyContent: "center" },
    cellRenderer: (params: ICellRendererParams) =>
      ActionsCell({
        t,
        actions: {
          onRemove: () => actions.onRemove && actions.onRemove(params.data),
        },
        type: params.data.status,
        forceMultiple: true,
      }),
  },
];

export default getAutomationsColDef;
