// TODO REFACTOR (js -> TS)

import React, { useMemo } from "react";
import {
  Button,
  Divider,
  Drawer,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ClockIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import EmptyCart from "../../assets/empty_cart.png";
import {
  jobSliceSelectors,
  setCartOpen,
  setNoStepCheckOut,
  setSelectedProducts,
} from "../../slices/Job/jobSlice";
import { useStyles } from "./styles";
import { openCheckout } from "../../store_new/reducers/JobEditor";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { getJobEditorState } from "../../store_new/selectors/JobEditor";

export default function Cart({ t, isCartOpen, dialogCart = false }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cart = useSelector(jobSliceSelectors.selectedProducts);

  const { isAgency } = useSelector(getCurrentUserData);
  const { packagesTotalPrice } = useSelector(getJobEditorState);

  const selectedProductsSum = useMemo(() => {
    const retail_price = cart.reduce((accumulator, object) => {
      return accumulator + object.retail_price;
    }, 0);
    const recommended_retail_price = cart.reduce((accumulator, object) => {
      return accumulator + object.recommended_retail_price;
    }, 0);
    const agencyTotal = cart.reduce((accumulator, object) => {
      return accumulator + object.agency_price;
    }, 0);
    return [retail_price, recommended_retail_price, agencyTotal];
  }, [cart]);

  const discount = useMemo(() => {
    if (isAgency) {
      return selectedProductsSum[2] - packagesTotalPrice;
    }
  }, [packagesTotalPrice]);

  const redirectToCheckout = () => {
    dispatch(openCheckout());
    if (!dialogCart) {
      dispatch(setNoStepCheckOut(true));
    }
  };

  const roundToTwoDecimals = (value) => {
    return value.toFixed(2);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      open={isCartOpen}
      className={classes.drawer}
    >
      <Stack width="100%" height="100%" spacing={3}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <Typography variant="title" fontSize={20}>
                {t("cart.title")}
              </Typography>
              {cart.length > 0 && (
                <Stack
                  backgroundColor="#818CF8"
                  color="#fff"
                  borderRadius="50%"
                  justifyContent="center"
                  alignItems="center"
                  width={20}
                  height={20}
                  fontSize={12}
                  fontWeight={500}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {cart.length}
                </Stack>
              )}
            </Stack>
            <Stack>
              <XMarkIcon
                className={classes.removeIcon}
                onClick={() => dispatch(setCartOpen(false))}
              />
            </Stack>
          </Stack>

          {cart.length > 0 ? (
            <Stack width="100%">
              {cart.map((item, index) => (
                <Stack key={index} width="100%">
                  <Divider className={classes.divider} />
                  <Stack
                    backgroundColor="#fff"
                    borderRadius="6px"
                    key={index}
                    direction="row"
                    alignItems="center"
                    width="100%"
                    spacing={1}
                  >
                    <Stack width="62%" spacing={1}>
                      <Stack direction="row" spacing={1.25}>
                        <Tooltip title={item.name}>
                          <Typography
                            variant="body2"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            maxWidth={270}
                            noWrap
                          >
                            {item.name}
                          </Typography>
                        </Tooltip>
                        {item.discount > 0 && (
                          <Stack
                            backgroundColor="#818CF8"
                            borderRadius="6px"
                            minWidth="42px"
                            height={24}
                            color="#fff"
                            alignItems="center"
                            justifyContent="center"
                            p={1}
                            fontSize={12}
                          >
                            {item.discount}%
                          </Stack>
                        )}
                      </Stack>
                      {item.runtime_in_days && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <ClockIcon className={classes.clockIcon} />
                          <Typography variant="body4">
                            {item.runtime_in_days} days
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                    <Stack
                      width="21%"
                      height="100%"
                      spacing={0.5}
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      {item.agency_price
                        ? item.agency_price < item.recommended_retail_price && (
                            <Typography
                              variant="mobileTitle"
                              color="#EF4444"
                              className={classes.discountPrice}
                            >
                              {roundToTwoDecimals(
                                item.recommended_retail_price
                              )}
                              €
                            </Typography>
                          )
                        : item.retail_price < item.recommended_retail_price && (
                            <Typography
                              variant="mobileTitle"
                              color="#EF4444"
                              className={classes.discountPrice}
                            >
                              {roundToTwoDecimals(
                                item.recommended_retail_price
                              )}
                              €
                            </Typography>
                          )}
                      <Typography variant="mobileTitle">
                        {roundToTwoDecimals(
                          item.agency_price || item.retail_price
                        )}
                        €
                      </Typography>
                    </Stack>
                    <Stack width="5%" alignItems="center">
                      <Stack
                        width="1px"
                        height={48}
                        backgroundColor="#E5E7EB"
                      />
                    </Stack>
                    <Stack width="24px" direction="row" justifyContent="center">
                      <XCircleIcon
                        className={classes.clearIcon}
                        onClick={() =>
                          dispatch(
                            setSelectedProducts(
                              cart.filter((el) => el.id !== item.id)
                            )
                          )
                        }
                      />
                    </Stack>
                  </Stack>
                </Stack>
              ))}
              <Divider className={classes.footerDivider} />
            </Stack>
          ) : (
            <Stack height={500} alignItems="center" justifyContent="center">
              <img src={EmptyCart} alt="empty" />
              <Typography variant="title" paddingTop={4}>
                {t("cart.empty_title")}
              </Typography>
              <Typography variant="body3" color="textSecondary" paddingTop={1}>
                {t("cart.empty_text_one")}
              </Typography>
              <Typography
                variant="body3"
                color="textSecondary"
                paddingTop={1}
                paddingBottom={4}
              >
                {t("cart.empty_text_two")}
              </Typography>
              <Button
                variant="contained"
                onClick={() => dispatch(setCartOpen(false))}
              >
                {t("cart.empty_button")}
              </Button>
            </Stack>
          )}
        </Stack>
        {cart.length > 0 && (
          <Stack width="100%" pb={3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                {t("create_job_fifth_step.original_price")}
              </Typography>
              <Typography variant="mobileTitle">
                {roundToTwoDecimals(selectedProductsSum[1])} €
              </Typography>
            </Stack>
            {!!isAgency && (
              <Stack mt={3} direction="row" justifyContent="space-between">
                <Typography variant="body2">
                  {t("create_job_fifth_step.agency_price")}
                </Typography>
                <Typography variant="mobileTitle">
                  {roundToTwoDecimals(selectedProductsSum[2])} €
                </Typography>
              </Stack>
            )}

            <Stack direction="row" justifyContent="space-between" mt={3}>
              <Typography variant="body2">
                {t("create_job_fifth_step.discount")}
              </Typography>
              <Typography variant="mobileTitle">
                {roundToTwoDecimals(
                  isAgency
                    ? discount
                    : selectedProductsSum[1] - selectedProductsSum[0]
                )}{" "}
                €
              </Typography>
            </Stack>

            <Divider className={classes.divider} />
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                {t("create_job_fifth_step.sub_total")}
              </Typography>
              <Typography variant="mobileTitle">
                {roundToTwoDecimals(
                  isAgency ? selectedProductsSum[2] : selectedProductsSum[1]
                )}{" "}
                €
              </Typography>
            </Stack>
            <Divider className={classes.divider} />
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="title" fontWeight={800} fontSize={20}>
                {t("create_job_fifth_step.total")}
              </Typography>
              <Typography variant="title" fontWeight={800} fontSize={20}>
                {roundToTwoDecimals(
                  isAgency
                    ? selectedProductsSum[2] - discount
                    : selectedProductsSum[0]
                )}
                €
              </Typography>
            </Stack>
            <Divider className={classes.divider} />
            {!!isAgency && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    {t("create_job_fifth_step.customer_total")}
                  </Typography>
                  <Typography variant="mobileTitle">
                    {roundToTwoDecimals(selectedProductsSum[0])} €
                  </Typography>
                </Stack>

                <Stack mt={3} direction="row" justifyContent="space-between">
                  <Typography variant="body2">
                    {t("create_job_fifth_step.margin")}
                  </Typography>
                  <Typography variant="mobileTitle">
                    {roundToTwoDecimals(
                      selectedProductsSum[0] - selectedProductsSum[2] + discount
                    )}
                    €
                  </Typography>
                </Stack>

                <Divider className={classes.termsDivider} />
              </>
            )}
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              mt={3}
            >
              <Button
                variant="outlined"
                onClick={() => dispatch(setCartOpen(false))}
              >
                {t("button.secondCancel")}
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={redirectToCheckout}
              >
                {t("cart.checkout")}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Drawer>
  );
}
