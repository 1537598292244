import { styled } from "@mui/system";
import { Stack, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export const StyledTemplateWrapper = styled(Stack)(
  ({ background }: { background: string }) => ({
    width: "100%",
    background: background || "#fff",
    overflow: "hidden auto",
  })
);
export const StyledFloatingButton = styled(Button)(
  ({ companyColor }: { readonly companyColor?: string }) => ({
    background: companyColor,
    opacity: 1,
    width: "100%",
    "&:hover": {
      background: companyColor,
      opacity: 0.8,
    },
  })
);

export const StyledContainer = styled(Stack)(
  ({
    desktop,
    companyColor,
  }: {
    readonly desktop: boolean;
    readonly companyColor: string;
  }) => ({
    flexDirection: desktop ? "row" : "column",
    minHeight: "100vh",
    width: desktop ? 1440 : "100%",
    justifyContent: "center",
    gap: 24,
    padding: desktop ? "64px 0" : 24,
    margin: desktop ? "auto" : 0,
    overflow: "hidden",
    ".show-all-jobs-mobile-button": {
      color: companyColor,
      width: "90vw",
      borderColor: `${companyColor} !important`,
    },
  })
);

export const StyledJobPreviewTemplateContainer = styled(Stack)(
  ({ desktop }: { desktop: boolean }) => ({
    width: desktop ? 744 : "calc(100vw - 48px)",
  })
);

export const StyledJobPreviewTemplateHeaderContainer = styled(Stack)({
  background: "#fff",
  borderRadius: 6,
  border: "1px solid #E5E7EB",
  alignItems: "center",
  gap: 27,
  img: {
    width: 130,
    height: 64,
    borderRadius: 6,
    objectFit: "contain",
  },
});

export const StyledJobPreviewTemplateTitle = styled(Typography)({
  lineHeight: "32px",
  fontWeight: 500,
});

export const StyledJobPreviewTemplateSubtitle = styled(Stack)(
  ({ companyColor }: { readonly companyColor: string }) => ({
    color: "#6B7280",
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "20px",
    svg: {
      strokeWidth: 1,
      width: 20,
      height: 20,
      stroke: companyColor,
    },
    "mobile-company-title": {
      color: "red",
    },
  })
);

export const StyledJobPreviewJobDescriptionHeader = styled(Stack)({
  background: "#fff",
  borderRadius: "6px",
  padding: "12px 24px",
});

export const StyledJobPreviewActionContainer = styled(Stack)(
  ({ disabled }: { disabled?: boolean }) => ({
    background: "#fff",
    padding: 24,
    borderRadius: 6,
    border: "1px solid #E5E7EB",
    height: "fit-content",
    form: {
      pointerEvents: disabled ? "none" : "auto",
    },
    ".floating-button": {
      position: "fixed",
      top: "25%",
      width: "100%",
    },
  })
);

export const StyledJobPreviewTemplateImageContainer = styled(Stack)({
  img: { width: "100%", height: 285, objectFit: "cover", borderRadius: 6 },
});

export const StyledJobPreviewTemplateSectionContainer = styled(Stack)({
  background: "#fff",
  padding: "12px 24px",
  borderRadius: 6,
  border: "1px solid #E5E7EB",
  justifyContent: "center",
});

export const StyledJobPreviewTemplateSectionTitle = styled(Stack)({
  fontSize: 18,
  lineHeight: "28px",
  fontWeight: 500,
  alignItems: "flex-start",
});

export const StyledJobPreviewTemplateSectionContent = styled(Stack)(
  ({ companyColor }: { readonly companyColor?: string }) => ({
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
    color: "#6B7280",
    svg: {
      strokeWidth: 1,
      width: 20,
      height: 20,
      stroke: companyColor,
    },
  })
);

export const StyledUploadCVContainer = styled(Stack)({
  border: "1px solid #E5E7EB!important",
  borderRadius: 6,
  boxShadow: "none",
  padding: "10px 12px",
  color: "#6B7280",
  fontSize: 14,
  justifyContent: "center",
  lineHeight: "20px",
  ".upload-section": {
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  ".uploaded-cv-section": {
    flexDirection: "row",
    ".success-icon": { stroke: "#22C55E", marginRight: 12 },
    ".delete-icon": {
      stroke: "#EF4444",
      marginLeft: "auto",
      cursor: "pointer",
    },
  },
  span: {
    maxWidth: 270,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  svg: {
    width: 20,
    height: 20,
    stroke: "#6B7280",
  },
});

export const StyledAgreementContainer = styled(Stack)(
  ({ companyColor }: { companyColor?: string }) => ({
    ".agreement-text": {
      fontSize: 14,
      color: "#6B7280",
      lineHeight: "20px",
      ".error": { color: "#EF4444" },
    },
    ".link-text": {
      fontSize: 14,
      color: "#178CF2",
      lineHeight: "20px",
      cursor: "pointer",
    },
    ".checkbox": {
      alignSelf: "flex-start",
      color: `${companyColor} !important`,
    },
  })
);

export const StyledUserDataContainer = styled(Stack)(
  ({ companyColor }: { readonly companyColor: string }) => ({
    padding: 8,
    alignItems: "center",
    border: "1px solid #E5E7EB",
    cursor: "pointer",
    borderRadius: 6,
    svg: {
      width: 18,
      height: 18,
      marginLeft: "auto !important",
    },
    "&:hover": {
      border: `1px solid ${companyColor}`,
      svg: { stroke: companyColor },
    },
    ".MuiAvatar-root": {
      svg: { width: "95%", stroke: "none" },
    },
  })
);

export const StyledLoadingApplyButton = styled(LoadingButton)(
  ({ companyColor }: { readonly companyColor: string }) => ({
    minWidth: 100,
    background: companyColor,
    opacity: 1,
    "&:hover": {
      background: companyColor,
      opacity: 0.8,
    },
  })
);
