import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "..";

export const getMe = createAsyncThunk("me", async () => {
  const response = await axiosInstance.post("/me");
  return response.data;
});

export const logIn = createAsyncThunk(
  "login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/login", data);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const logOut = createAsyncThunk("logout", async () => {
  const response = await axiosInstance.post("/logout");
  return response.data;
});
