// TODO REFACTOR (convert this reducer according to the new redux flow)

import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setJobHeader: (state, { payload: { title, created_at } }) => {
      sessionStorage.setItem("jobTitle", JSON.stringify(title));
      sessionStorage.setItem("jobCreatedAt", JSON.stringify(created_at));
    }
  }
});

export const { setJobHeader } = dashboardSlice.actions;
export default dashboardSlice.reducer;
