import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import {
  IAutomationAction,
  IAutomationState,
  ICurrentAutomationTrigger,
  ICurrentAutomation,
} from "../../models/Automation";
import { PayloadActionWithCallback } from "../../models/common";

const initialState: IAutomationState = {
  automations: [],
  isListLoading: false,
  isSaveLoading: false,
  filter: "",
  currentAutomation: {
    id: null,
    name: "",
    trigger: null,
    actions: [],
    enabled: false,
  },
  error: false,
  isTriggersLoading: false,
  isActionsLoading: false,
  isAddActionContainer: false,
  triggers: [],
  actions: [],
  selectedAction: undefined,
  selectedActionData: {
    jobs: undefined,
    members: undefined,
  },
  isSelectedActionDataLoading: false,
};

const apiReducers = {
  fetchAutomations: (state: Draft<IAutomationState>) => {
    state.isListLoading = true;
  },
  fetchAutomationsFailed: (
    state: Draft<IAutomationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isListLoading = false;
    state.error = action.payload;
  },
  fetchAutomationsSuccess: (
    state: Draft<IAutomationState>,
    action: PayloadAction<any>
  ) => {
    state.isListLoading = false;
    state.automations = action.payload;
  },
  addUpdateAutomation: (
    state: Draft<IAutomationState>,
    _: PayloadActionWithCallback<unknown>
  ) => {
    state.isSaveLoading = true;
  },
  addUpdateAutomationFailed: (
    state: Draft<IAutomationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isSaveLoading = false;
    state.error = action.payload;
  },
  addUpdateAutomationSuccess: (state: Draft<IAutomationState>) => {
    state.isSaveLoading = false;
  },
  fetchTriggers: (state: Draft<IAutomationState>) => {
    state.isTriggersLoading = true;
  },
  fetchTriggersFailed: (
    state: Draft<IAutomationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isTriggersLoading = false;
    state.error = action.payload;
  },
  fetchTriggersSuccess: (
    state: Draft<IAutomationState>,
    action: PayloadAction<any>
  ) => {
    state.isTriggersLoading = false;
    state.triggers = action.payload;
  },
  fetchActions: (state: Draft<IAutomationState>) => {
    state.isActionsLoading = true;
  },
  fetchActionsFailed: (
    state: Draft<IAutomationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isActionsLoading = false;
    state.error = action.payload;
  },
  fetchActionsSuccess: (
    state: Draft<IAutomationState>,
    action: PayloadAction<any>
  ) => {
    state.isActionsLoading = false;
    state.actions = action.payload;
  },
  fetchSelectedActionData: (
    state: Draft<IAutomationState>,
    _: PayloadAction<string>
  ) => {
    state.isSelectedActionDataLoading = true;
  },
  fetchSelectedActionDataFailed: (
    state: Draft<IAutomationState>,
    action: PayloadAction<unknown>
  ) => {
    state.isSelectedActionDataLoading = false;
    state.error = action.payload;
  },
  fetchSelectedActionDataSuccess: (
    state: Draft<IAutomationState>,
    action: PayloadAction<any>
  ) => {
    state.isSelectedActionDataLoading = false;
    state.selectedActionData = action.payload;
  },
  activateDeactivateAutomation: (
    state: Draft<IAutomationState>,
    _: PayloadActionWithCallback<{
      id: number;
      enabled: boolean;
    }>
  ) => {
    state.isListLoading = true;
  },
  activateDeactivateAutomationFailed: (
    state: Draft<IAutomationState>,
    action: PayloadAction<any>
  ) => {
    state.isListLoading = false;
    state.error = action.payload;
  },
  activateDeactivateAutomationSuccess: (state: Draft<IAutomationState>) => {
    state.isListLoading = false;
  },
  deleteAutomation: (
    state: Draft<IAutomationState>,
    _: PayloadActionWithCallback<{
      id: number;
    }>
  ) => {
    state.isListLoading = true;
  },
  deleteAutomationFailed: (
    state: Draft<IAutomationState>,
    action: PayloadAction<any>
  ) => {
    state.isListLoading = false;
    state.error = action.payload;
  },
  deleteAutomationSuccess: (state: Draft<IAutomationState>) => {
    state.isListLoading = false;
  },
};

const setReducers = {
  setTrigger: (
    state: Draft<IAutomationState>,
    action: PayloadAction<ICurrentAutomationTrigger | null>
  ) => {
    state.currentAutomation.trigger = action.payload;
  },
  addNewAction: (
    state: Draft<IAutomationState>,
    action: PayloadAction<IAutomationAction>
  ) => {
    state.currentAutomation.actions = [
      ...state.currentAutomation.actions,
      action.payload,
    ];
  },
  setCurrentAutomationEnabledDisabled: (
    state: Draft<IAutomationState>,
    action: PayloadAction<boolean>
  ) => {
    state.currentAutomation.enabled = action.payload;
  },
  setActions: (
    state: Draft<IAutomationState>,
    action: PayloadAction<IAutomationAction[]>
  ) => {
    state.currentAutomation.actions = action.payload;
  },
  setCurrentAutomationName: (
    state: Draft<IAutomationState>,
    action: PayloadAction<string>
  ) => {
    state.currentAutomation.name = action.payload;
  },
  setSelectedAction: (
    state: Draft<IAutomationState>,
    action: PayloadAction<IAutomationAction | undefined>
  ) => {
    state.selectedAction = action.payload;
  },
  setIsAddActionContainer: (
    state: Draft<IAutomationState>,
    action: PayloadAction<boolean>
  ) => {
    state.isAddActionContainer = action.payload;
  },
  setAutomationsFilter: (
    state: Draft<IAutomationState>,
    action: PayloadAction<string>
  ) => {
    state.filter = action.payload;
  },
  setCurrentAutomation: (
    state: Draft<IAutomationState>,
    action: PayloadAction<ICurrentAutomation>
  ) => {
    state.currentAutomation = action.payload;
  },
  resetCurrentAutomation: (state: Draft<IAutomationState>) => {
    state.currentAutomation = initialState.currentAutomation;
    state.selectedAction = initialState.selectedAction;
    state.isAddActionContainer = initialState.isAddActionContainer;
  },
  resetTriggersAndActions: (state: Draft<IAutomationState>) => {
    state.triggers = initialState.triggers;
    state.actions = initialState.actions;
  },
};

export const AutomationSlice = createSlice({
  name: "AUTOMATION",
  initialState,
  reducers: {
    ...apiReducers,
    ...setReducers,
  },
});

export const {
  fetchAutomations,
  fetchAutomationsSuccess,
  fetchAutomationsFailed,
  fetchTriggers,
  fetchTriggersFailed,
  fetchTriggersSuccess,
  fetchActions,
  fetchActionsFailed,
  fetchActionsSuccess,
  fetchSelectedActionData,
  fetchSelectedActionDataFailed,
  fetchSelectedActionDataSuccess,
  activateDeactivateAutomation,
  activateDeactivateAutomationFailed,
  activateDeactivateAutomationSuccess,
  addUpdateAutomation,
  addUpdateAutomationFailed,
  addUpdateAutomationSuccess,
  deleteAutomation,
  deleteAutomationFailed,
  deleteAutomationSuccess,
  setTrigger,
  addNewAction,
  setActions,
  setSelectedAction,
  setIsAddActionContainer,
  setCurrentAutomation,
  setCurrentAutomationName,
  setAutomationsFilter,
  setCurrentAutomationEnabledDisabled,
  resetCurrentAutomation,
  resetTriggersAndActions,
} = AutomationSlice.actions;

export default AutomationSlice.reducer;
