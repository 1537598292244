// TODO REFACTOR (convert this reducer according to the new redux flow)

import { createSlice } from "@reduxjs/toolkit";
import {
  getApplications,
  getCandidateMessages,
  getLatestCandidateMessages,
  refreshCandidateMessages
} from "../../services/Candidate";
import {
  updateCandidateCV,
  updateCandidateDocuments
} from "../../services/Job";
import { fetchCountries } from "../../services/utils";

const SLICE_NAME = "candidate";

const initialState = {
  info: {},
  candidate: {
    title: undefined
  },
  candidateUrlKey: "",
  candidateJobId: "",
  status: false,
  job: null,
  applications: [],
  formatedApplications: [],
  applicationsLoading: false,
  messagesLoading: false,
  uploadDocumentLoading: false,
  countries: undefined,
  messages: [],
  messagesInfo: undefined,
  latestMessages: [],
  recruiterId: undefined,
  application_id: undefined,
  job_id: undefined
};

export const candidateSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setCandidateId: (state, action) => {
      sessionStorage.setItem(
        "candidateUrlKey",
        JSON.stringify(action.payload.candidateUrlKey)
      );
      sessionStorage.setItem(
        "candidateJobId",
        JSON.stringify(action.payload.candidateJobId)
      );
    },
    setCandidate: (state, action) => {
      sessionStorage.setItem("candidate", JSON.stringify(action.payload));
      state.candidate = action.payload;
    },
    setCandidateJob: (state, action) => {
      sessionStorage.setItem("candidateJob", JSON.stringify(action.payload));
      state.candidate = action.payload;
    },
    changeStatus: (state, action) => {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getApplications.pending, (state) => {
      state.applicationsLoading = true;
    });
    builder.addCase(getApplications.rejected, (state) => {
      state.applicationsLoading = false;
    });
    builder.addCase(getApplications.fulfilled, (state, { payload }) => {
      state.applicationsLoading = false;
      state.applications = payload.data.applications;
      const currentApplicatios = [...state.applications];
      const formatApplications = currentApplicatios.map((item) => ({
        ...item,
        application_id: item.id,
        company_title: item.job.company,
        field_of_work: item.job.work_type,
        position_type: item.job.position_type,
        url_key: item.job.url_key,
        title: item.job.title,
        documents: item.documents.map((document) => ({
          ...document,
          link: document.filename
        })),
        locations: item.job_locations
      }));
      state.formatedApplications = formatApplications;
    });
    builder.addCase(updateCandidateDocuments.pending, (state) => {
      state.uploadDocumentLoading = true;
    });
    builder.addCase(updateCandidateDocuments.rejected, (state) => {
      state.uploadDocumentLoading = false;
    });
    builder.addCase(updateCandidateDocuments.fulfilled, (state) => {
      state.uploadDocumentLoading = false;
    });
    builder.addCase(updateCandidateCV.pending, (state) => {
      state.uploadDocumentLoading = true;
    });
    builder.addCase(updateCandidateCV.rejected, (state) => {
      state.uploadDocumentLoading = false;
    });
    builder.addCase(updateCandidateCV.fulfilled, (state) => {
      state.uploadDocumentLoading = false;
    });
    builder.addCase(fetchCountries.fulfilled, (state, { payload }) => {
      const allCountries = Object.entries(payload.data.countries).map(
        (entry) => ({
          key: entry[0],
          value: entry[1]
        })
      );
      state.countries = allCountries;
    });
    builder.addCase(getCandidateMessages.pending, (state) => {
      state.messagesLoading = true;
    });
    builder.addCase(getCandidateMessages.rejected, (state) => {
      state.messagesLoading = false;
    });
    builder.addCase(getCandidateMessages.fulfilled, (state, { payload }) => {
      state.messagesLoading = false;
      state.messages = payload.data[1].messages;
      state.messagesInfo = payload.data[0].info;
    });
    builder.addCase(
      refreshCandidateMessages.fulfilled,
      (state, { payload }) => {
        state.messagesLoading = false;
        state.messages = payload.data[1].messages;
      }
    );
    builder.addCase(getLatestCandidateMessages.pending, (state) => {
      state.messagesLoading = true;
    });
    builder.addCase(getLatestCandidateMessages.rejected, (state) => {
      state.messagesLoading = false;
    });
    builder.addCase(
      getLatestCandidateMessages.fulfilled,
      (state, { payload }) => {
        state.messagesLoading = false;
        state.latestMessages = payload.data.messages;
      }
    );
  }
});

export const { setCandidateId, changeStatus, setCandidate, setCandidateJob } =
  candidateSlice.actions;
export default candidateSlice.reducer;
