import React from "react";
import {
  ArchiveBoxIcon,
  CheckIcon,
  PencilIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TrashIcon,
  ArrowDownTrayIcon,
  CheckCircleIcon,
  DocumentIcon,
  DocumentPlusIcon,
  UserPlusIcon,
  UserMinusIcon,
  PlayCircleIcon,
  MinusCircleIcon,
  EnvelopeIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  SignalIcon,
  ArrowPathIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";
import { ICurrentAutomation } from "../../models/Automation";

const RenderIcon = ({
  type,
  currentAutomation,
}: {
  readonly type: string;
  readonly currentAutomation?: ICurrentAutomation;
}) => {
  switch (type) {
    // triggers icons
    case "job_created":
      return <SparklesIcon className="job-created-icon" />;
    case "job_updated":
      return <PencilSquareIcon className="trigger-icon" />;
    case "delete_job":
    case "job_deleted":
      return (
        <TrashIcon
          className={
            currentAutomation?.trigger ? "action-icon" : "trigger-icon"
          }
        />
      );
    case "job_activated":
      return <CheckIcon className="trigger-icon" />;
    case "archive_job":
    case "job_archived":
      return (
        <ArchiveBoxIcon
          className={
            currentAutomation?.trigger ? "action-icon" : "trigger-icon"
          }
        />
      );
    case "application_created":
      return <PlusCircleIcon className="trigger-icon" />;
    case "application_updated":
      return <PencilSquareIcon className="trigger-icon" />;
    case "application_status_updated":
      return <PencilIcon className="trigger-icon" />;
    case "job_draft_duplicated":
      return <Square2StackIcon className="trigger-icon" />;
    case "job_draft_saved":
      return <ArrowDownTrayIcon className="trigger-icon" />;
    case "job_published":
      return <CheckCircleIcon className="trigger-icon" />;
    case "member_invited":
      return <UserPlusIcon className="trigger-icon" />;
    case "create_not":
    case "note_created":
      return <DocumentIcon className="trigger-icon" />;
    case "note_member_tagged":
      return (
        <DocumentPlusIcon
          className={
            currentAutomation?.trigger ? "action-icon" : "trigger-icon"
          }
        />
      );
    case "talentpool_application_added":
      return <UserPlusIcon className="trigger-icon" />;
    case "talentpool_application_deleted":
      return <UserMinusIcon className="trigger-icon" />;
    case "talentpool_created":
      return <PlayCircleIcon className="trigger-icon" />;
    case "talentpool_updated":
      return <PencilSquareIcon className="trigger-icon" />;
    case "talentpool_record_added":
      return <PlusCircleIcon className="trigger-icon" />;
    case "talentpool_record_deleted":
      return <MinusCircleIcon className="trigger-icon" />;
    case "workflow_created":
      return <PlayCircleIcon className="trigger-icon" />;
    case "workflow_updated":
      return <PencilSquareIcon className="trigger-icon" />;
    case "workflow_deleted":
      return <TrashIcon className="trigger-icon" />;
    // actions icons
    case "send_mail":
      return <EnvelopeIcon className="action-icon" />;
    case "send_message":
      return <ChatBubbleOvalLeftEllipsisIcon className="action-icon" />;
    case "change_application_status":
      return <SignalIcon className="action-icon" />;
    case "transition_application_to":
      return <ArrowPathIcon className="action-icon" />;
    case "add_action_container":
      return <BoltIcon className="action-icon" />;
    case "create_note":
      return <DocumentIcon className="action-icon" />;

    default:
      return <></>;
  }
};

const ShowIcon = ({
  type,
  currentAutomation,
}: {
  readonly type: string;
  readonly currentAutomation?: ICurrentAutomation;
}) => <RenderIcon type={type} currentAutomation={currentAutomation} />;

export default ShowIcon;
