import React from "react";
import { Stack } from "@mui/material";
import Job from "./Job";
import Pagination from "../../components/Pagination";
import { TCompany, TJob } from "../../models/CompanyInfo";

const Jobs = ({
  company,
  page,
  desktop,
  jobWidgetPage = false,
  paginate,
}: {
  readonly company: TCompany;
  readonly page: number;
  readonly desktop: boolean;
  readonly jobWidgetPage?: boolean;
  readonly paginate: (page: number) => void;
}) => {
  return (
    <>
      <Stack width="100%" spacing={1.5} id="jobs-widget-jobs">
        {company?.jobs?.map((job: TJob) => (
          <Job
            key={job.id}
            desktop={desktop}
            job={job}
            company={company}
            jobWidgetPage={jobWidgetPage}
          />
        ))}
      </Stack>
      {parseInt(company?.jobs_total as string) > 10 && (
        <Pagination
          desktop={desktop}
          totalPosts={company?.jobs_total}
          itemsPerPage={10}
          page={page}
          paginate={paginate}
        />
      )}
    </>
  );
};

export default Jobs;
