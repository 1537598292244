import React, { useEffect, useMemo, useState } from "react";
import { Dialog } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { TPremiumPackage, TPremiumProductItem } from "../../models/SingleJob";
import CheckoutComponent from "./component";
import {
  jobSliceSelectors,
  resetSelectedProducts,
  setCartOpen,
  setSelectedProducts,
} from "../../slices/Job/jobSlice";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";
import {
  closeCheckout,
  closeJobEditor,
  publishProducts,
  resetAgencyPackagePrice,
  setAgencyPackagePrice,
} from "../../store_new/reducers/JobEditor";
import { getJobEditorState } from "../../store_new/selectors/JobEditor";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import { useTranslation } from "react-i18next";

const Checkout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAgency } = useSelector(getCurrentUserData);
  const selectedProducts = useSelector(jobSliceSelectors.selectedProducts); // TODO refactor later
  const { isCheckoutOpen } = useSelector(getJobEditorState);
  const { isLoading, isJobEditorOpen, packagesTotalPrice } =
    useSelector(getJobEditorState);
  const [checked, setChecked] = useState(false);

  const agencyTotal = useMemo(
    () =>
      selectedProducts.reduce(
        (acc: number, item: any) => acc + item.agency_price,
        0
      ),
    [selectedProducts]
  );

  const discount = useMemo(
    () =>
      selectedProducts.reduce((acc: number, item: any) => {
        if (isAgency) {
          return agencyTotal - packagesTotalPrice;
        }
        const discount = item.sales_products
          ? item.oldPrice - item.retail_price
          : item.discount_price ??
            item.recommended_retail_price - item.retail_price;
        return acc + discount;
      }, 0),
    [selectedProducts, packagesTotalPrice]
  );

  const oldTotal = useMemo(
    () =>
      selectedProducts.reduce(
        (acc: number, item: any) =>
          acc + (item.oldPrice || item.recommended_retail_price),
        0
      ),
    [selectedProducts]
  );

  const total = useMemo(
    () =>
      selectedProducts.reduce(
        (acc: number, item: any) => acc + item.retail_price,
        0
      ),
    [selectedProducts]
  );

  useEffect(() => {
    const productsIds = selectedProducts.map(
      (item: TPremiumProductItem) => item.id
    );
    if (isAgency) {
      if (selectedProducts.length > 0) {
        dispatch(
          setAgencyPackagePrice({
            product_ids: productsIds,
          })
        );
      } else {
        dispatch(resetAgencyPackagePrice());
      }
    }
  }, [selectedProducts]);

  const handleOnCheckAgreement = () => {
    setChecked((prevState) => !prevState);
  };

  const handleOnRedirectPrivacy = () => {
    window.location.href = "https://karriera.de/privacy";
  };

  const handleOnRedirectTerms = () => {
    window.location.href = "https://karriera.de/agb";
  };

  const handleOnPublish = () => {
    dispatch(publishProducts({ callback: handleOnPublishSuccess }));
  };

  const handleOnPublishSuccess = () => {
    dispatch(setCartOpen(false));
    dispatch(resetSelectedProducts());
    dispatch(
      addMessage({
        type: "success",
        title: t("create_job_fifth_step.snackbar_title"),
      })
    );
    handleOnClose();
    if (isJobEditorOpen) dispatch(closeJobEditor());
  };

  const handleOnClose = () => {
    dispatch(closeCheckout());
  };

  const handleOnRemoveItems = (item: TPremiumPackage | TPremiumProductItem) => {
    dispatch(
      // TODO refactor later (cart component)
      setSelectedProducts(
        selectedProducts.filter(
          (selectedProduct: TPremiumPackage | TPremiumProductItem) =>
            selectedProduct.id !== item.id
        )
      )
    );
  };

  return (
    <Dialog open={isCheckoutOpen} fullScreen>
      <CheckoutComponent
        t={t}
        isLoading={isLoading}
        oldTotal={oldTotal}
        total={total}
        agencyTotal={agencyTotal}
        discount={discount}
        selectedProducts={selectedProducts}
        agreement={checked}
        isAgency={isAgency}
        onCheckAgreement={handleOnCheckAgreement}
        onPublish={handleOnPublish}
        onClose={handleOnClose}
        onRedirectPrivacy={handleOnRedirectPrivacy}
        onRedirectTerms={handleOnRedirectTerms}
        onRemoveItem={handleOnRemoveItems}
      />
    </Dialog>
  );
};
export default Checkout;
