import React from "react";
import { Divider, Link, Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import JobAction from "./JobAction";
import JobTemplate from "./JobTemplate";
import OtherJobs from "./JobTemplate/OtherJobs";
import { StyledContainer, StyledTemplateWrapper } from "./styles";

const JobPreviewComponent = ({
  t,
  // metaData,
  // jobPostingFormat,
  desktop,
  companyColor,
  logo,
  totalJobs,
  wrapperBackground,
  disabled = false
}: {
  readonly t: TFunction;
  readonly metaData?: { title: string; description: string; imgUrl: string };
  readonly jobPostingFormat?: { [x: string]: any };
  readonly desktop: boolean;
  readonly companyColor: string;
  readonly logo: string | null;
  readonly totalJobs?: number;
  readonly wrapperBackground: string;
  readonly disabled?: boolean;
}) => {
  return (
    <StyledTemplateWrapper background={wrapperBackground}>
      <StyledContainer desktop={desktop} companyColor={companyColor}>
        <JobTemplate desktop={desktop} companyColor={companyColor} />
        <JobAction
          desktop={desktop}
          disabled={disabled}
          companyColor={companyColor}
        />
        {!desktop && (
          <OtherJobs
            desktop={desktop}
            companyColor={companyColor}
            logo={logo}
            totalJobs={totalJobs as number}
          />
        )}
      </StyledContainer>
      <Stack
        sx={{
          width: "100%",
          maxWidth: desktop ? 1440 : "88vw",
          margin: "auto"
        }}
        pb={desktop ? 8 : 2}
        pt={desktop ? 2 : 0}
      >
        <Divider sx={{ borderColor: "#E5E7EB" }} />
        <Stack pt={3} direction={"row"} spacing={2} justifyContent={"center"}>
          <Link href={"https://karriera.de/agb"} underline={"none"}>
            <Typography
              variant={"subtitle2"}
              color={companyColor}
              sx={{ cursor: "pointer" }}
            >
              {t("terms")}
            </Typography>
          </Link>
          <Link href={"https://karriera.de/privacy"} underline={"none"}>
            <Typography
              variant={"subtitle2"}
              color={companyColor}
              sx={{ cursor: "pointer" }}
            >
              {t("privacy")}
            </Typography>
          </Link>
        </Stack>
      </Stack>
    </StyledTemplateWrapper>
  );
};
export default JobPreviewComponent;
