import { Typography, CircularProgress, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  PencilSquareIcon,
  PlusCircleIcon,
  ArrowDownTrayIcon
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { updateCandidateDocuments } from "../../services/Job";
import { useStyles } from "./styles";
import { getApplications } from "../../services/Candidate";
import { setCandidateJob } from "../../slices/Candidate";

const ContentBox = (props) => {
  const {
    formatedApplications,
    documents,
    jobUrlKey,
    children,
    title,
    section,
    handleSave = () => {},
    handleEdit = () => {},
    handleFileSave = () => {},
    edit,
    maxHeight = ""
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { uploadDocumentLoading, candidate, applications } = useSelector(
    (state) => state.candidate
  );
  const { is_candidate } = useSelector((state) => state.user);

  useEffect(() => {
    const candidateData = formatedApplications?.find(
      (item) => item.url_key === jobUrlKey
    );
    if (candidateData && !uploadDocumentLoading) {
      dispatch(
        setCandidateJob({
          ...candidate,
          documents: candidateData.documents
        })
      );
    }
  }, [applications]);

  const RenderActions = () => {
    const dispatch = useDispatch();
    switch (section) {
      case "personalInformation":
        if (edit) {
          return (
            <ArrowDownTrayIcon
              className={classes.icon}
              onClick={() => {
                handleSave();
              }}
            />
          );
        }
        return (
          <PencilSquareIcon onClick={handleEdit} className={classes.icon} />
        );
      case "documentAdded": {
        if (documents.length >= 4) {
          return <Stack height={24}></Stack>;
        }
        return (
          <Stack height={24} component="label">
            {uploadDocumentLoading ? (
              <CircularProgress size={18} color="secondary" />
            ) : (
              <PlusCircleIcon
                onClick={handleFileSave}
                className={classes.icon}
              />
            )}
            <input
              hidden
              type="file"
              onChange={(event) => {
                if (event.target.files && event.target.files[0]) {
                  const bodyFormData = new FormData();
                  let img = event.target.files[0];
                  bodyFormData.append("files[]", img);
                  dispatch(
                    updateCandidateDocuments([jobUrlKey, bodyFormData])
                  ).then(() => {
                    dispatch(getApplications());
                  });
                }
              }}
            />
          </Stack>
        );
      }
      default:
        return <></>;
    }
  };

  const Header = () => {
    switch (section) {
      case "candidateMessages":
        return (
          <div className={classes.messageHeader}>
            <Typography
              fontSize={18}
              color={"#131E30"}
              fontWeight={500}
              lineHeight={"18px"}
            >
              {t("create_job_second_step.notes")}
            </Typography>
          </div>
        );
      default:
        return (
          <>
            {title ? (
              <div className={classes.header}>
                <Typography
                  variant="title"
                  fontSize={18}
                  style={{ color: "#131E30" }}
                  fontWeight={500}
                  lineHeight={"18px"}
                >
                  {title}
                </Typography>
                {is_candidate && <RenderActions />}
              </div>
            ) : (
              <></>
            )}
          </>
        );
    }
  };

  return (
    <>
      <div className={classes.container} style={{ maxHeight: maxHeight }}>
        <Header />
        <div className={classes.body}>{children}</div>
      </div>
    </>
  );
};

export default ContentBox;
