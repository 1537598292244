import React, { useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { TApplication } from "../../models/Applications";
import {
  deleteApplication,
  fetchApplications,
  resetJobOwnersAndActivities,
  resetSelectedApplications,
  setApplicationFilters,
  setPageNum,
  setSelectedApplications,
  setSelectedJobApplications,
  setView,
  updateApplicationStatus,
} from "../../store_new/reducers/Applications";
import {
  getApplicationListState,
  getApplicationsFiltersState,
  getApplicationsIsListLoading,
  getApplicationsPaginationState,
  getApplicationsViewState,
  getJobWorkflow,
  getSelectedApplications,
} from "../../store_new/selectors/Applications";
import { getSingleJobState } from "../../store_new/selectors/SingleJob";
import ApplicationsComponent from "./component";
import { TSelectedJobCandidateApplication } from "../../models/ApplicationPage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCurrentUserData } from "../../store_new/selectors/CurrentUser";
import {
  fetchJobApplications,
  resetJobDetailsAndBookings,
} from "../../store_new/reducers/SingleJob";
import { getAllActiveJobs } from "../../store_new/selectors/JobList";
import { fetchAllActiveJobs } from "../../store_new/reducers/JobList";
import { DIALOG_TYPE } from "../../models/common";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";
import { deleteCandidate } from "../../store_new/reducers/TalentPools";
import { getTalentPoolState } from "../../store_new/selectors/Talentpool";
import { ITalentPool } from "../../models/TalentPools";
import { getWorkflowState } from "../../store_new/selectors/Workflow";

const Applications_new = ({
  containerMargin = 4,
}: {
  readonly containerMargin?: number;
  readonly showPremiumButton?: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { list, totalCount, cardsApplications } = useSelector(
    getApplicationListState
  );

  const isListLoading = useSelector(getApplicationsIsListLoading);
  const { pageNum, pageSize } = useSelector(getApplicationsPaginationState);
  const { quickSearch, status, appliedTo, location, company } = useSelector(
    getApplicationsFiltersState
  );
  const { jobId } = useSelector(getSingleJobState);
  const allActiveJobs = useSelector(getAllActiveJobs);
  const selectedApplications = useSelector(getSelectedApplications);
  const view = useSelector(getApplicationsViewState);
  const jobWorkflow = useSelector(getJobWorkflow);
  const localView: string | null = localStorage.getItem("view");
  const { isAgency, applicationsAllowed, firstName } =
    useSelector(getCurrentUserData);
  const { talentPools } = useSelector(getTalentPoolState);
  const {
    workflows,
    isListLoading: isWorkflowListLoading,
    isWorkflowsListLoaded,
  } = useSelector(getWorkflowState);

  const CSVLinkRef = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const [csvData, setCsvData] = useState<TApplication[] | null>(null);
  const [dialogType, setDialogType] = useState<DIALOG_TYPE | null>(null);
  const [deleteApplicationId, setDeleteApplicationId] = useState<
    string | number | null
  >(null);

  const isApplicationsListLoading =
    isListLoading || isWorkflowListLoading || !firstName;

  const statuses = [
    {
      const: "new",
      title: t("status.new"),
    },
    {
      const: "review",
      title: t("status.in_progress"),
    },
    {
      const: "hired",
      title: t("status.hired"),
    },
    {
      const: "rejected",
      title: t("status.rejected"),
    },
  ];

  const defaultStatuses = [
    {
      label: t("status.new"),
      value: "new",
    },
    {
      label: t("status.in_progress"),
      value: "review",
    },
    {
      label: t("status.hired"),
      value: "hired",
    },
    {
      label: t("status.rejected"),
      value: "rejected",
    },
  ];

  const uniqueStatuses = useMemo(() => {
    if (workflows) {
      if (view === "cards" && jobWorkflow) {
        const formattedWorkflowStatuses: any = jobWorkflow.statuses.map(
          (status) => ({
            label: status.title || status.label,
            value: status.value,
          })
        );
        return formattedWorkflowStatuses;
      }
      const allUniqueStatuses = Array.from(
        new Set(
          workflows.flatMap((workflow) =>
            workflow.statuses.map((status) => ({
              label: status.label,
              value: status.value,
            }))
          )
        )
      );
      return allUniqueStatuses;
    }
    return defaultStatuses;
  }, [workflows, statuses]);

  const handleOnPaginate = (pageNum: number) => {
    dispatch(setPageNum(pageNum));
  };

  const handleDeselectAllSelectedApplications = () => {
    dispatch(resetSelectedApplications());
  };

  const handleChangeMultipleStatus = (newStatus: string) => {
    selectedApplications.forEach((item: TApplication) => {
      dispatch(
        updateApplicationStatus({
          applicationId: item.application_id as string,
          newStatus,
          index: "0",
          callback: () =>
            onSuccessChangeApplicationStatus({
              applicationId: item.application_id as string,
              recordId: item.record_id as string,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              isTalentPooled: item.is_talentpooled,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              talentPoolTitle: item.talentpool_title,
            }),
        })
      );
    });
  };

  const handleOnEdit = (application: TSelectedJobCandidateApplication) => {
    dispatch(setSelectedJobApplications([application]));
    if (jobId) {
      navigate(
        `/jobs/applications/candidate/${application.candidate_url_key}/${application.job_id}`
      );
    } else {
      navigate(
        `/applications/candidate/${application.candidate_url_key}/${application.job_id}`
      );
    }
  };

  const handleOnExport = (applications: TApplication | TApplication[]) => {
    setCsvData(Array.isArray(applications) ? applications : [applications]);
    setTimeout(() => {
      CSVLinkRef.current && CSVLinkRef.current.link.click();
    });
  };

  const onSuccessChangeApplicationStatus = ({
    isTalentPooled,
    talentPoolTitle,
    applicationId,
    recordId,
  }: {
    readonly isTalentPooled: 0 | 1;
    readonly talentPoolTitle: string | null;
    readonly applicationId: string | number;
    readonly recordId: string;
  }) => {
    if (isTalentPooled) {
      const findTalentPool = talentPools.find(
        (item: ITalentPool) => item.title === talentPoolTitle
      );
      dispatch(
        deleteCandidate({
          id: findTalentPool?.id as number,
          applicationId: applicationId as number,
          recordId: recordId as string,
          callback: () => dispatch(fetchApplications({ withoutLoader: true })),
        })
      );
    } else {
      dispatch(fetchApplications({ withoutLoader: true }));
    }
  };

  const handleOnChangeStatus = ({
    isTalentPooled,
    talentPoolTitle,
    applicationId,
    newStatus,
    index,
    recordId,
  }: {
    readonly isTalentPooled: 0 | 1;
    readonly talentPoolTitle: string | null;
    readonly applicationId: string;
    readonly newStatus: string;
    readonly index: string;
    readonly recordId: string;
  }) => {
    dispatch(
      updateApplicationStatus({
        applicationId,
        newStatus,
        index: index ?? "0",
        callback: () =>
          onSuccessChangeApplicationStatus({
            applicationId,
            isTalentPooled,
            talentPoolTitle,
            recordId,
          }),
      })
    );
  };

  const handleOnDownloadCV = (cvLink: string) => {
    window.open(cvLink, "_blank");
  };

  const handleOnSelect = (application: TApplication) => {
    dispatch(setSelectedApplications(application));
  };

  const handleOnOpenDialog = (
    params: TApplication | null,
    type: DIALOG_TYPE | null
  ) => {
    if (params?.application_id) {
      setDeleteApplicationId(params.application_id);
    }
    setDialogType(type);
  };

  const handleOnCloseDialog = () => {
    setDialogType(null);
    setDeleteApplicationId(null);
  };

  const onSuccessDeleteAppliccation = () => {
    dispatch(fetchApplications());
    handleOnCloseDialog();
    dispatch(
      addMessage({
        type: "success",
        title: t("applications.delete_application_snackbar"),
      })
    );
  };

  const handleOnDeleteApplication = () => {
    dispatch(
      deleteApplication({
        applicationId: deleteApplicationId as number,
        callback: () => onSuccessDeleteAppliccation(),
      })
    );
  };

  useEffect(() => {
    if (isAgency && !applicationsAllowed) {
      navigate("/jobs/active");
    }
  }, [isAgency]);

  useEffect(() => {
    if (view === "cards") {
      dispatch(
        setApplicationFilters({
          filterType: "status",
          value: "",
        })
      );
    }
  }, [view]);

  useEffect(() => {
    dispatch(resetSelectedApplications());
  }, [list]);

  useEffect(() => {
    if (!localView) {
      localStorage.setItem("view", view);
    } else if (localView && localView !== view && jobId) {
      dispatch(setView(localView));
    }
    if (!jobId) {
      dispatch(resetJobOwnersAndActivities());
    }
  }, []);

  useEffect(() => {
    if (isWorkflowsListLoaded) {
      if (view === "table") {
        dispatch(fetchApplications());
      } else {
        dispatch(fetchApplications({ withoutLoader: true }));
      }
    }
  }, [
    quickSearch,
    location,
    status.value,
    appliedTo.value,
    pageNum,
    isAgency,
    company.value,
    view,
    isWorkflowsListLoaded,
  ]);

  useEffect(() => {
    if (localView && localView === "cards" && jobId && isWorkflowsListLoaded) {
      dispatch(
        fetchJobApplications({ jobId: jobId as string, callback: () => {} })
      );
    }
  }, [view, isAgency, isWorkflowsListLoaded]);

  useEffect(() => {
    if (!allActiveJobs) {
      dispatch(fetchAllActiveJobs());
    }
    if (!jobId) {
      dispatch(resetJobDetailsAndBookings());
    }
  }, []);

  return (
    <>
      <CSVLink
        data={csvData || []}
        className="hidden"
        ref={CSVLinkRef}
        filename={csvData?.[0]?.title || "Job"}
      />
      <ApplicationsComponent
        t={t}
        containerMargin={containerMargin}
        showPremiumButton={!!jobId}
        applicationsCount={totalCount}
        list={list}
        isListLoading={isApplicationsListLoading}
        selectedApplications={selectedApplications}
        pageNum={pageNum}
        pageSize={pageSize}
        view={view}
        jobId={jobId}
        dialogType={dialogType}
        statuses={uniqueStatuses}
        initialColumns={cardsApplications}
        onChangeMultipleStatus={handleChangeMultipleStatus}
        onDeselectAll={handleDeselectAllSelectedApplications}
        onItemClick={handleOnEdit}
        onPaginate={handleOnPaginate}
        onSelect={handleOnSelect}
        onDeleteApplication={handleOnDeleteApplication}
        onOpenDialog={handleOnOpenDialog}
        onCloseDialog={handleOnCloseDialog}
        actions={{
          onEdit: handleOnEdit,
          onExport: handleOnExport,
          onChangeStatus: handleOnChangeStatus,
          onDownloadCV: handleOnDownloadCV,
          onRemove: (params: TApplication) =>
            handleOnOpenDialog(params, DIALOG_TYPE.DELETE),
        }}
      />
    </>
  );
};

export default Applications_new;
