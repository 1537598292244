import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "..";

export const getCompanyData = createAsyncThunk(
  "getCompanyData",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/company/companies/my`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyInfo = createAsyncThunk(
  "getCompanyInfo",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/companies/${props[0]}`, {
        pagenum: props[1],
        pagesize: props[2],
        location: props[3],
        work_field_id: props[4]
      });

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCandidates = createAsyncThunk(
  "getCandidates",
  async (filters, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/candidates/applications`,
        filters
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCandidatesByJobId = createAsyncThunk(
  "getCandidatesByJobId",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/candidates/applications/${props[0]}`,
        props[1]
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const setStatus = createAsyncThunk(
  "setStatus",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/candidates/application-status-update/${props[0]}`,
        {
          status: props[1],
          index: props[2] || "0"
        }
      );

      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJobDetails = createAsyncThunk(
  "getJobDetails",
  async (urlKey, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/jobs/details/${urlKey}`
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJobDetailsAndTemplate = createAsyncThunk(
  "getJobDetailsAndTemplate",
  async (urlKey, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/jobs/details/${urlKey}`
      );
      dispatch(getTemplate(response.data.data.job.template_id));
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTemplate = createAsyncThunk(
  "getTemplate",
  async (templateId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/templates/get-raw/${templateId}`
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJobBoards = createAsyncThunk(
  "getJobBoards",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/job-boards`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCompanyProfile = createAsyncThunk(
  "updateCompanyProfile",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/profile/update`,
        data
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPendingCompanyMembers = createAsyncThunk(
  "getPendingCompanyMembers",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/members/invited`,
        props
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJoinedCompanyMembers = createAsyncThunk(
  "getJoinedCompanyMembers",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/company/members`, props);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const inviteMembers = createAsyncThunk(
  "inviteMembers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/members/invite`,
        data
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/profile/member-update`,
        data
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const changeProfilePhoto = createAsyncThunk(
  "changeProfilePhoto",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/profile/photo-update`,
        data
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
