import React from "react";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  infoAlert: {
    color: "#ffffff",
    backgroundColor: "#178CF2",
  },
}));

export default function Notification({
  open,
  setOpen,
  message = "",
  variant = "info",
}) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getAlertStyle = () => {
    switch (variant) {
      case "info":
        return classes.infoAlert;
      default: {
        return;
      }
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={variant}
        className={getAlertStyle()}
        icon={false}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
