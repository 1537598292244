import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, axiosInstanceDownload } from "..";
import { navigate } from "@gatsbyjs/reach-router";

export const getJob = createAsyncThunk(
  "getJob",
  async (urlKey, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/jobs/${urlKey}`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const candidateLogin = createAsyncThunk(
  "candidateLogin",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/candidate/login/${props[0]}?j=${props[1]}`,
        props[1]
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const applyJob = createAsyncThunk(
  "applyJob",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/apply/${props[0]}`, props[1]);
      navigate(`/successful-application`);
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const savePreview = createAsyncThunk(
  "savePreview",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/company/templates/save",
        data
      );
      const url = `${window.location.origin}/template/preview/${response.data.data.template_id}`;
      window.open(url, "_blank");
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPreview = createAsyncThunk(
  "getPreview",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/templates/preview/${id}`
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getJobData = createAsyncThunk(
  "getJobData",
  async (data, { rejectWithValue }) => {
    const lang = localStorage.getItem("lang");
    try {
      const response = await axiosInstance.post(`/company/jobs/create`, data, {
        headers: {
          "Accept-Language": lang,
        },
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createJob = createAsyncThunk(
  "createJob",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/company/jobs/save`, data);
      dispatch(getStepFourData(response.data.data.urlKey));
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStepFourData = createAsyncThunk(
  "getStepFourData",
  async (urlKey, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/jobs/publish-prepare/${urlKey}`
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProductsAndPackages = createAsyncThunk(
  "getProductsAndPackages",
  async (urlKey, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/jobs/publish-prepare/${urlKey}`
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const publishJob = createAsyncThunk(
  "publishJob",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/jobs/publish/${props[0]}`,
        props[1]
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const publishPackage = createAsyncThunk(
  "publishPackage",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/packages/prices`,
        props
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatuses = createAsyncThunk(
  "getStatuses",
  async (data, { rejectWithValue }) => {
    const lang = localStorage.getItem("lang");
    try {
      const response = await axiosInstance.post(`/app-statuses`, data, {
        headers: {
          "Accept-Language": lang || "de_DE",
        },
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCandidateDocuments = createAsyncThunk(
  "updateCandidateDocuments",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/candidate/applications/upload-documents/${props[0]}`,
        props[1]
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCandidateCV = createAsyncThunk(
  "updateCandidateCV",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/candidate/applications/upload-cv/${props[0]}`,
        props[1]
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkJobsEmail = createAsyncThunk(
  "checkJobsEmail",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/candidates/is-available`,
        props
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const extendJobLimit = createAsyncThunk(
  "extendJobLimit",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/company/profile/request-pro`,
        props
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const downloadCompanyLogo = createAsyncThunk(
  "downloadCompanyLogo",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceDownload.post(
        `/company/companies/download-logo`
      );
      let blob = new Blob([response.data], {
        type: response.data.type,
      });
      blob.name = "logo.60e48654.png";
      blob.lastModified = new Date();
      const downloadUrl = URL.createObjectURL(blob);
      return downloadUrl;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchLogoBlob = createAsyncThunk(
  "fetchLogoBlob",
  async (companyLogo, { rejectWithValue }) => {
    try {
      const companyLogoBlob = await fetch(companyLogo).then((response) =>
        response.blob()
      );
      return companyLogoBlob;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendMagicLink = createAsyncThunk(
  "sendMagicLink",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/apply/magic/${props[0]}`, {
        email: props[1],
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
