import { createSlice } from "@reduxjs/toolkit";

const SLICE_NAME = "navigation";

const initialState = {
  open: true,
  expandShop: false,
  expandSettings: false,
  mobileDrawerOpen: undefined,
  isInviteMemberDialogOpen: false,
};

export const navigationSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setOpen(state, { payload }) {
      state.open = payload;
    },
    setExpandShop(state, { payload }) {
      state.expandShop = payload;
    },
    setExpandSettings(state, { payload }) {
      state.expandSettings = payload;
    },
    setMobileDrawerOpen(state, { payload }) {
      state.mobileDrawerOpen = payload;
    },
    setIsInviteMemberDialogOpen(state, { payload }) {
      state.isInviteMemberDialogOpen = payload;
    },
  },
});

export const {
  setOpen,
  setExpandShop,
  setExpandSettings,
  setMobileDrawerOpen,
  setIsInviteMemberDialogOpen,
} = navigationSlice.actions;
