import { navigate } from "@gatsbyjs/reach-router";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "..";

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/password/reset", data);
      navigate("/forgot-password/resend");
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const recoveryPassword = createAsyncThunk(
  "recoveryPassword",
  async (props, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/password/recovery/${props[0]}`,
        {
          password: props[1],
          confirm: props[2],
        }
      );
      navigate("/password/confirmation");
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
