import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  List,
  ListItem,
  ListItemButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setStatus,
  getCandidates,
  getCandidatesByJobId
} from "../../../services/Company";
import { useStyles } from "./styles";
import { candidatesSliceSelectors } from "../../../slices/Candidates/candidatesSlice";
import { changeStatus } from "../../../slices/Candidate";

// TODO Refactor (js -> TS)

const StatusList = ({ candidate, noDropdown = false, showRole = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const page = useSelector(candidatesSliceSelectors.page);
  const page_size = useSelector(candidatesSliceSelectors.page_size);
  const statuses = useSelector(candidatesSliceSelectors.statuses);
  const filters = useSelector(candidatesSliceSelectors.filters);
  const singleJobCandidateFilters = useSelector(
    candidatesSliceSelectors.single_job_candidates_filters
  );
  const singleJobCandidatePage = useSelector(
    candidatesSliceSelectors.single_job_candidates_page
  );
  const { status, role, application_id } = candidate;

  const [activeStatus, setActiveStatus] = useState();
  const [tableAnchorEl, setTableAnchorEl] = useState(null);
  const tableOpen = Boolean(tableAnchorEl);
  const tableId = tableOpen ? "simple-popover-table" : undefined;
  const jobId = candidate.job_id;

  const closeTablePopover = () => {
    setTableAnchorEl(null);
  };

  const handleStatusClick = (event) => {
    setTableAnchorEl(event.currentTarget);
  };

  const handleJob = (item, currentStatus, index = "0") => {
    dispatch(setStatus([application_id, currentStatus, index])).then(() => {
      if (location.pathname === "/applications") {
        dispatch(
          getCandidates({
            name: filters?.search.length > 0 ? filters.search : undefined,
            jobId: filters?.location ? filters.location.id : undefined,
            status: filters?.job_type ? filters.job_type.id : undefined,
            pagesize: page_size,
            pagenum: page
          })
        );
      } else {
        dispatch(
          getCandidatesByJobId([
            jobId,
            {
              name:
                singleJobCandidateFilters?.search.length > 0
                  ? singleJobCandidateFilters.search
                  : undefined,
              location:
                singleJobCandidateFilters?.location.length > 0
                  ? singleJobCandidateFilters.location
                  : undefined,
              status: singleJobCandidateFilters?.job_type
                ? singleJobCandidateFilters.job_type.id
                : undefined,
              pagesize: page_size,
              pagenum: singleJobCandidatePage
            }
          ])
        );
      }
    });

    dispatch(changeStatus(true));
    closeTablePopover();
  };

  const ordersStatuses = {
    not_started: t("team_members.pending"),
    in_production: t("team_members.pending"),
    customer_action_pending: t("team_members.pending"),
    published: "Online",
    finished: "Online",
    aborted: t("aborted")
  };

  const generateStatusStyle = (name) => {
    switch (name) {
      case "new":
        return {
          backgroundColor: "#EEF2FF",
          color: "#818CF8"
        };
      case "review":
        return {
          backgroundColor: "#FFFBEB",
          color: "#F59E0B"
        };
      case "rejected":
        return {
          backgroundColor: "#FEF2F2",
          color: "#EF4444"
        };
      case "offline":
        return {
          backgroundColor: "#FEF2F2",
          color: "#EF4444"
        };
      case "hired":
        return {
          backgroundColor: "#F0FDF4",
          color: "#22C55E"
        };
      case "Active":
        return {
          backgroundColor: "#DCFCE7",
          color: "#22C55E"
        };
      case "online":
        return {
          backgroundColor: "#DCFCE7",
          color: "#22C55E"
        };
      case "Aktiv":
        return {
          backgroundColor: "#DCFCE7",
          color: "#22C55E"
        };
      case "Inactive":
        return {
          backgroundColor: "#FEF2F2",
          color: "#EF4444"
        };
      case "Inaktiv":
        return {
          backgroundColor: "#FEF2F2",
          color: "#EF4444"
        };
      case "Joined":
        return {
          backgroundColor: "#DCFCE7",
          color: "#22C55E"
        };
      case "Pending":
        return {
          backgroundColor: "#FFFBEB",
          color: "#F59E0B"
        };
      case "Admin":
        return {
          backgroundColor: "#EEF2FF",
          color: "#818CF8"
        };
      case "Recruiter":
        return {
          backgroundColor: "#F3F4F6",
          color: "#6B7280"
        };
      case "Archived":
        return {
          backgroundColor: "#FEF2F2",
          color: "#EF4444"
        };
      case "not_started":
        return {
          backgroundColor: "#EDF7FF",
          color: "#178CF2"
        };
      case "in_production":
        return {
          backgroundColor: "#EDF7FF",
          color: "#178CF2"
        };
      case "customer_action_pending":
        return {
          backgroundColor: "#EDF7FF",
          color: "#178CF2"
        };
      case "published":
        return {
          backgroundColor: "#DCFCE7",
          color: "#22C55E"
        };
      case "finished":
        return {
          backgroundColor: "#DCFCE7",
          color: "#22C55E"
        };
      case "aborted":
        return {
          backgroundColor: "#FEF2F2",
          color: "#EF4444"
        };
      case "Ausgelaufen":
        return {
          backgroundColor: "#FEF2F2",
          color: "#EF4444"
        };
      case "Expired":
        return {
          backgroundColor: "#FEF2F2",
          color: "#EF4444"
        };
      case "Archiviert":
        return {
          backgroundColor: "#FEF2F2",
          color: "#EF4444"
        };
    }
  };

  const hiredStatusCheck = status === "hired" ? "Hire" : status;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        style={
          showRole ? generateStatusStyle(role) : generateStatusStyle(status)
        }
        className={classes.statusWraper}
      >
        <Typography className={classes.status}>
          {showRole
            ? role
            : ordersStatuses[status] ||
              statuses[candidate?.status] ||
              hiredStatusCheck ||
              ""}
        </Typography>
        {!noDropdown && (
          <ChevronDownIcon
            className={classes.statusDropdownIcon}
            onClick={handleStatusClick}
            onMouseOver={() => {
              if (!tableAnchorEl) {
                setActiveStatus(candidate.status);
              }
            }}
          />
        )}
        <Popover
          id={tableId}
          open={tableOpen}
          anchorEl={tableAnchorEl}
          onClose={closeTablePopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "none"
            }
          }}
        >
          <List disablePadding>
            {Object.keys(statuses).map((item, index) => {
              if (statuses[activeStatus] === statuses[item]) {
                return;
              }

              return (
                <ListItem disablePadding key={index}>
                  <ListItemButton onClick={() => handleJob(activeStatus, item)}>
                    <Typography
                      variant="body3"
                      color="textSecondary"
                      fontWeight={400}
                    >
                      {statuses[item]}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Popover>
      </Stack>
    </>
  );
};

export default StatusList;
