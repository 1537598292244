import React from "react";
import TriggerSelection from "./TriggerSelection";
import SendEmail from "./actions/SendEmail";
import { ITriggerSelectionComponent } from "../../models/Automation";

const RenderAction = ({
  t,
  currentAutomation,
  triggers,
  activeTriggerGroup,
  triggersGroup,
  isTriggersLoading,
  selectedAction,
  onChangeActiveTrigger,
  onSelectTrigger,
}: ITriggerSelectionComponent) => {
  switch (selectedAction?.const) {
    case "send_mail":
      return (
        <SendEmail
          t={t}
          selectedAction={selectedAction}
          onChangeActiveTrigger={onChangeActiveTrigger}
        />
      );
    default:
      return (
        <TriggerSelection
          t={t}
          triggers={triggers}
          currentAutomation={currentAutomation}
          activeTriggerGroup={activeTriggerGroup}
          triggersGroup={triggersGroup}
          isTriggersLoading={isTriggersLoading}
          onChangeActiveTrigger={onChangeActiveTrigger}
          onSelectTrigger={onSelectTrigger}
        />
      );
  }
};

const ShowAction = ({
  t,
  triggers,
  currentAutomation,
  activeTriggerGroup,
  triggersGroup,
  isTriggersLoading,
  selectedAction,
  onSelectTrigger,
  onChangeActiveTrigger,
}: ITriggerSelectionComponent) => (
  <RenderAction
    t={t}
    activeTriggerGroup={activeTriggerGroup}
    currentAutomation={currentAutomation}
    triggers={triggers}
    triggersGroup={triggersGroup}
    isTriggersLoading={isTriggersLoading}
    selectedAction={selectedAction}
    onChangeActiveTrigger={onChangeActiveTrigger}
    onSelectTrigger={onSelectTrigger}
  />
);

export default ShowAction;
