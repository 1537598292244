import * as yup from "yup";

export const companyDataSchema = (t) => {
  return yup.object().shape({
    country: yup.string().required(t("register.message")),
    city: yup.string().required(t("register.message")),
    postcode: yup.string().required(t("register.message")),
    address: yup.string().required(t("register.message")),
    house_number: yup.string().required(t("register.message")),
  });
};

export const stepOneSchema = (t) => {
  return yup.object().shape({
    job_title: yup.string().required(t("register.message")),
    introduction_title: yup.string().required(t("register.message")),
    introduction: yup.string().required(t("register.message")),
    tasks_title: yup.string().required(t("register.message")),
    tasks: yup.string().required(t("register.message")),
    requirements_title: yup.string().required(t("register.message")),
    requirements: yup.string().required(t("register.message")),
    benefits_title: yup.string().required(t("register.message")),
    benefits: yup.string().required(t("register.message")),
    contact_information_title: yup.string(),
    contact_information: yup.string(),
    video: yup.string(),
  });
};

export const stepTwoSchema = (t) => {
  return yup.object().shape({
    country: yup.string().required(t("register.message")),
    city: yup.string().required(t("register.message")),
    postcode: yup.string().required(t("register.message")),
    contract_type: yup.string().required(t("register.message")),
    field_of_work: yup.string().required(t("register.message")),
    seniority: yup.string().required(t("register.message")),
    job_type: yup.string().required(t("register.message")),
    industry: yup.string().required(t("register.message")),
    qualification_level: yup.string().required(t("register.message")),
    reference_number: yup.string(),
    date_of_publication: yup.string(),
    assign_job_to: yup.string(),
    keywords: yup.string(),
    notes: yup.string(),
  });
};
