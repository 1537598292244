import React, { useEffect, useState, useMemo } from "react";
import i18next from "i18next";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import UserSettingsComponent from "./component";
import { DIALOG_TYPE } from "../../models/common";
import {
  getCurrentUserData,
  getCurrentUserIsCandidate,
  getCurrentUserLoadingState,
  getCurrentUserUpdateLoadingState,
} from "../../store_new/selectors/CurrentUser";
import {
  fetchUser,
  updateCurrentUserData,
  updateCurrentUserPhoto,
} from "../../store_new/reducers/CurrentUser";
import {
  addMessage,
  turnOffNotifications,
} from "../../slices/NotificationSlice/GlobalNotificationSlice";
import {
  setIsChangePasswordDialogOpen,
  setIsUploadPictureDialogOpen,
} from "../../store_new/reducers/dialogs";
import { resetJobData } from "../../store_new/reducers/JobEditor";
import { IUserValue } from "../../models/CurrentUser";
import {
  fetchActions,
  fetchTriggers,
} from "../../store_new/reducers/Automation";

const UserSettingsPageNew = ({ desktop }: { readonly desktop: boolean }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    lang,
    photo,
    username,
    phoneNumber,
    xingUrl,
    linkedinUrl,
    firstName,
    lastName,
  } = useSelector(getCurrentUserData);

  const oldValue = useMemo(
    () => ({
      firstname: firstName,
      lastname: lastName,
      phonenumber: phoneNumber,
      linkedin_url: linkedinUrl,
      xing_url: xingUrl,
      username,
    }),
    [firstName, lastName, phoneNumber, linkedinUrl, xingUrl]
  );

  const [newValue, setNewValue] = useState<IUserValue>(oldValue);
  const [language, setLanguage] = useState<string>();

  const isLoading = useSelector(getCurrentUserLoadingState);
  const isUpdateUserLoading = useSelector(getCurrentUserUpdateLoadingState);
  const isCandidate = useSelector(getCurrentUserIsCandidate);

  const languages = [
    {
      key: "de_DE",
      value: t("userSettings.german") as string,
      label: t("userSettings.german") as string,
    },
    {
      key: "en_US",
      label: t("userSettings.english"),
      value: t("userSettings.english"),
    },
  ];

  const findLanguage = languages.find((item) => item.key === lang);

  const isEmptryUrls =
    newValue.linkedin_url?.length === 0 || newValue?.xing_url?.length === 0;

  const isUrlsNotChanged =
    newValue.linkedin_url === oldValue.linkedin_url &&
    newValue.xing_url === oldValue.xing_url;

  const isConnectAccountButtonDisabled = isEmptryUrls || isUrlsNotChanged;

  const isEmptyUserData =
    newValue.firstname?.length === 0 || newValue?.lastname?.length === 0;

  const isUserDataNotChanged =
    newValue.firstname === oldValue.firstname &&
    newValue.lastname === oldValue.lastname &&
    newValue.phonenumber === oldValue.phonenumber &&
    language === findLanguage?.value;

  const isPhoneNumber =
    newValue.phonenumber?.length === 0 && isCandidate === false;

  const isSaveUserDataButtonDisabled =
    isEmptyUserData || isUserDataNotChanged || isPhoneNumber;

  const handleOnChangeValue = (type: string, value: string) => {
    if (type === "lang") {
      setLanguage(value);
    } else {
      setNewValue({
        ...newValue,
        [type]: value,
      });
    }
  };

  const handleOnOpenCloseDialog = (type: DIALOG_TYPE | null) => {
    if (type === DIALOG_TYPE.CHANGE_PASSWORD) {
      dispatch(setIsChangePasswordDialogOpen(true));
    } else if (type === DIALOG_TYPE.UPLOAD_IMAGE) {
      dispatch(setIsUploadPictureDialogOpen(true));
    } else {
      dispatch(setIsChangePasswordDialogOpen(false));
      dispatch(setIsUploadPictureDialogOpen(false));
    }
  };

  const onSuccesUpdateUser = (newLanguage?: string) => {
    const currentLanguage = localStorage.getItem("lang");
    if (newLanguage !== currentLanguage) {
      i18next.changeLanguage(newLanguage);
      localStorage.setItem("lang", newLanguage as string);
      if (!isCandidate) {
        dispatch(resetJobData());
      }
    }
    dispatch(fetchTriggers());
    dispatch(fetchActions());
    dispatch(fetchUser());
    dispatch(
      addMessage({
        type: "success",
        title: t("userSettings.snackbar_title"),
      })
    );
  };

  const handleOnRemovePhoto = () => {
    dispatch(turnOffNotifications());
    dispatch(
      updateCurrentUserPhoto({
        reset: true,
        callback: () => onSuccesUpdateUser(),
      })
    );
  };

  const handleOnUpdateData = (isUrlsChangned?: boolean) => {
    const mainData = {
      ...newValue,
      linkedin_url: undefined,
      xing_url: undefined,
    };

    const urls = {
      linkedin_url: newValue.linkedin_url,
      xing_url: newValue.xing_url,
    };

    const formattedData = isUrlsChangned ? urls : mainData;
    const formattedLanguage = languages.find((lang) => lang.value === language);

    dispatch(
      updateCurrentUserData({
        data: {
          ...formattedData,
          lang: formattedLanguage?.key as string,
        },
        callback: () => onSuccesUpdateUser(formattedLanguage?.key),
      })
    );
  };

  useEffect(() => {
    const findDefaultLanguage = languages.find(
      (language) => language.key == lang
    );
    if (lang && !language) {
      setLanguage(findDefaultLanguage?.value as string);
    }
  }, []);

  return (
    <UserSettingsComponent
      t={t}
      isLoading={isLoading || isUpdateUserLoading}
      desktop={desktop}
      isCandidate={Boolean(isCandidate)}
      oldValue={oldValue}
      photo={photo}
      language={language as string}
      languages={languages}
      newValue={newValue}
      isConnectAccountButtonDisabled={isConnectAccountButtonDisabled}
      isSaveUserDataButtonDisabled={isSaveUserDataButtonDisabled}
      onRemovePhoto={handleOnRemovePhoto}
      onOpenCloseDialog={handleOnOpenCloseDialog}
      onChangeValue={handleOnChangeValue}
      onUpdateData={handleOnUpdateData}
    />
  );
};

export default UserSettingsPageNew;
