import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  statusWraper: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 12,
    borderRadius: "100px",
    width: "min-content",
  },
  status: {
    // whiteSpace: "nowrap",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 2,
    fontSize: 12,
    textAlign: "center",
    borderRadius: "100px",
    backgroundColor: ({ statusBackgroundColor }) => statusBackgroundColor,
    color: ({ statusTextColor }) => statusTextColor,
    width: 80,
    whiteSpace: "nowrap",
    textTransform: "capitalize",
  },
  promote: {
    padding: 8,
    fontSize: 15,
    textAlign: "center",
    borderRadius: "6px",
    maxWidth: "min-content",
    backgroundColor: ({ promoteBackgroundColor }) => promoteBackgroundColor,
    color: ({ promoteTextColor }) => promoteTextColor,
  },
  statusDropdownIcon: {
    cursor: "pointer",
    height: 14,
    width: 14,
  },
}));
