import React from "react";
import { Stack } from "@mui/material";
import TriggerSelection from "./TriggerSelection";
import AddActionContainer from "./AddActionContainer";
import ShowAction from "./ShowAction";
import { ITriggerSelectionComponent } from "../../models/Automation";

const RenderSelection = ({
  t,
  currentAutomation,
  triggers,
  activeTriggerGroup,
  triggersGroup,
  isTriggersLoading,
  isAddActionContainer,
  selectedAction,
  onOpenSelectActionContainer,
  onChangeActiveTrigger,
  onSelectTrigger,
}: ITriggerSelectionComponent) => {
  if (selectedAction?.const) {
    return (
      <ShowAction
        t={t}
        activeTriggerGroup={activeTriggerGroup}
        currentAutomation={currentAutomation}
        triggers={triggers}
        triggersGroup={triggersGroup}
        isTriggersLoading={isTriggersLoading}
        selectedAction={selectedAction}
        onChangeActiveTrigger={onChangeActiveTrigger}
        onSelectTrigger={onSelectTrigger}
      />
    );
  }
  if (!currentAutomation?.trigger) {
    return (
      <TriggerSelection
        t={t}
        triggers={triggers}
        currentAutomation={currentAutomation}
        activeTriggerGroup={activeTriggerGroup}
        triggersGroup={triggersGroup}
        isTriggersLoading={isTriggersLoading}
        onChangeActiveTrigger={onChangeActiveTrigger}
        onSelectTrigger={onSelectTrigger}
      />
    );
  }
  if (isAddActionContainer) {
    return (
      <AddActionContainer
        t={t}
        onOpenSelectActionContainer={onOpenSelectActionContainer as () => void}
      />
    );
  }

  return (
    <ShowAction
      t={t}
      activeTriggerGroup={activeTriggerGroup}
      currentAutomation={currentAutomation}
      triggers={triggers}
      triggersGroup={triggersGroup}
      isTriggersLoading={isTriggersLoading}
      selectedAction={selectedAction}
      onChangeActiveTrigger={onChangeActiveTrigger}
      onSelectTrigger={onSelectTrigger}
    />
  );
};

const SelectionContainer = ({
  t,
  triggers,
  currentAutomation,
  activeTriggerGroup,
  triggersGroup,
  isTriggersLoading,
  isAddActionContainer,
  selectedAction,
  onOpenSelectActionContainer,
  onSelectTrigger,
  onChangeActiveTrigger,
}: ITriggerSelectionComponent) => (
  <Stack className="selection-container">
    <RenderSelection
      t={t}
      activeTriggerGroup={activeTriggerGroup}
      currentAutomation={currentAutomation}
      triggers={triggers}
      triggersGroup={triggersGroup}
      isTriggersLoading={isTriggersLoading}
      isAddActionContainer={isAddActionContainer}
      selectedAction={selectedAction}
      onOpenSelectActionContainer={onOpenSelectActionContainer}
      onChangeActiveTrigger={onChangeActiveTrigger}
      onSelectTrigger={onSelectTrigger}
    />
  </Stack>
);

export default SelectionContainer;
