import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { TFunction } from "i18next";
import NoResultImage from "../../../assets/no_jobs.png";

const ProductsEmptyState = ({
  t,
  onResetFilters,
}: {
  t: TFunction;
  onResetFilters: () => void;
}) => (
  <Stack height={500} justifyContent="center" alignItems="center">
    <img src={NoResultImage} width={204.25} height={200} />
    <Typography variant="body2" paddingTop={4}>
      {t("create_job_fifth_step.no_result")}
    </Typography>
    <Typography
      variant="subtitle2"
      color="textSecondary"
      paddingTop={1}
      paddingBottom={3}
    >
      {t("create_job_fifth_step.using_filters")}
    </Typography>
    <Button variant="contained" onClick={onResetFilters}>
      {t("create_job_fifth_step.reset_filters")}
    </Button>
  </Stack>
);

export default ProductsEmptyState;
