// TODO REFACTOR (convert this reducer according to the new redux flow)

import { createSlice } from "@reduxjs/toolkit";
import {
  getCandidates,
  getCandidatesByJobId,
  getCompanyInfo,
  getJobBoards,
  getJobDetails,
  getJobDetailsAndTemplate,
  getTemplate
} from "../../services/Company";
import { getJob, getProductsAndPackages } from "../../services/Job";
import { getStatuses } from "../../services/Job";

const SLICE_NAME = "candidates";

const initialState = {
  archived_jobs_data: undefined,
  candidates_data: undefined,
  loading: false,
  statuses: [],
  page: 1,
  page_size: 10,
  filters: {
    search: "",
    location: undefined,
    job_type: {
      id: undefined,
      value: ""
    }
  },
  single_job_candidates_data: undefined,
  single_job_candidates_filters: {
    search: "",
    location: "",
    job_type: {
      id: undefined,
      value: ""
    }
  },
  single_job_candidates_page: 1,
  jobUrlKey: undefined,
  jobDetails: undefined,
  template: undefined,
  jobData: [],
  jobBoards: undefined,
  filteredProducts: [],
  defaultProducts: [],
  productsFilters: {
    search: "",
    country: {},
    industry: {}
  },
  isPromote: false,
  isEdit: false,
  view: "table",
  newApplications: null,
  reviewApplications: null,
  hiredApplications: null,
  rejectedApplications: null
};

export const candidatesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSingleJobUrlKey(state, { payload }) {
      state.jobUrlKey = payload;
    },
    resetProductsFilters(state) {
      state.productsFilters = initialState.productsFilters;
    },
    setJobFilteredProducts(state, { payload }) {
      state.filteredProducts = payload;
    },
    setProductsFilters(state, { payload }) {
      state.productsFilters = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCandidates.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCandidates.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCandidates.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.candidates_data = payload.data;
    });
    builder.addCase(getCandidatesByJobId.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCandidatesByJobId.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCandidatesByJobId.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.single_job_candidates_data = payload.data;
      const newApplicants = [];
      const reviewApplicants = [];
      const hiredApplicants = [];
      const rejectedApplicants = [];
      const applicants = payload.data.applications;
      applicants.map((item) => {
        const findCV = item?.documents?.find((document) => document.isCV);
        const formatedItem = {
          ...item,
          id: item.application_id,
          name: `${item.firstname} ${item.lastname}`,
          photo: item.photo,
          cv: findCV ? findCV.link : null,
          jobTitle: item.title,
          date: item.timestamp,
          location: item.location,
          appIndex: item.app_index,
          candidateUrlKey: item.candidate_url_key,
          jobId: item.job_id
        };
        switch (item.status) {
          case "new":
            newApplicants.push({ ...formatedItem, status: "new" });
            break;
          case "review":
            reviewApplicants.push({
              ...formatedItem,
              status: "review"
            });
            break;
          case "hired":
            hiredApplicants.push({ ...formatedItem, status: "hired" });
            break;
          case "rejected":
            rejectedApplicants.push({ ...formatedItem, status: "rejected" });
            break;
        }
      });
      state.newApplications = newApplicants.sort(
        (a, b) => a.appIndex - b.appIndex
      );
      state.reviewApplications = reviewApplicants.sort(
        (a, b) => a.appIndex - b.appIndex
      );
      state.hiredApplications = hiredApplicants.sort(
        (a, b) => a.appIndex - b.appIndex
      );
      state.rejectedApplications = rejectedApplicants.sort(
        (a, b) => a.appIndex - b.appIndex
      );
    });
    builder.addCase(getJobDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobDetails.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getJobDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.jobDetails = payload.data;
    });
    builder.addCase(getJobDetailsAndTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobDetailsAndTemplate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(
      getJobDetailsAndTemplate.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.jobDetails = payload.data;
      }
    );
    builder.addCase(getTemplate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTemplate.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getTemplate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.template = payload.data.raw_template;
    });
    builder.addCase(getJobBoards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJobBoards.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getJobBoards.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.jobBoards = payload.data.job_boards;
    });
    builder.addCase(getCompanyInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCompanyInfo.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getCompanyInfo.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getJob.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getJob.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getJob.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getStatuses.fulfilled, (state, { payload }) => {
      const statuses = [...payload.data.statuses];
      const formatedStatuses = statuses.map((item) => ({
        ...item,
        id: item.const
      }));
      const finalStatus = formatedStatuses.reduce(
        (obj, cur) => ({ ...obj, [cur.id]: cur.title }),
        {}
      );
      state.statuses = finalStatus;
    });
    builder.addCase(getProductsAndPackages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductsAndPackages.rejected, (state) => {
      state.loading = false;
    }),
      builder.addCase(
        getProductsAndPackages.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.jobData = payload.data;
          const products = [];
          if (state.jobData.products) {
            Object.keys(state.jobData?.products).map((item) => {
              products.push({
                name: state.jobData.products[item][0].supplier,
                products: [...state.jobData.products[item]].sort(
                  (a, b) => a.retail_price - b.retail_price
                ),
                country: state.jobData.products[item][0].country
              });
            });
          }
          state.filteredProducts = products;
          state.defaultProducts = products;
        }
      );
  }
});

export const {
  setSingleJobUrlKey,
  setJobFilteredProducts,
  setProductsFilters,
  resetProductsFilters
} = candidatesSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const candidatesSliceSelectors = {
  loading: (rootState) => {
    const appState = getAppState(rootState);
    return appState.loading;
  },
  page: (rootState) => {
    const appState = getAppState(rootState);
    return appState.page;
  },
  single_job_candidates_page: (rootState) => {
    const appState = getAppState(rootState);
    return appState.single_job_candidates_page;
  },
  page_size: (rootState) => {
    const appState = getAppState(rootState);
    return appState.page_size;
  },
  candidates_data: (rootState) => {
    const appState = getAppState(rootState);
    return appState.candidates_data;
  },
  single_job_candidates_data: (rootState) => {
    const appState = getAppState(rootState);
    return appState.single_job_candidates_data;
  },
  filters: (rootState) => {
    const appState = getAppState(rootState);
    return appState.filters;
  },
  single_job_candidates_filters: (rootState) => {
    const appState = getAppState(rootState);
    return appState.single_job_candidates_filters;
  },
  statuses: (rootState) => {
    const appState = getAppState(rootState);
    return appState.statuses;
  },
  productsFilters: (rootState) => {
    const appState = getAppState(rootState);
    return appState.productsFilters;
  }
};
