import { RootState } from "../config";

export const getCandidateState = ({ candidate_new }: RootState) =>
  candidate_new;
export const getIsCandidateState = ({ candidate_new }: RootState) =>
  candidate_new.isCandidate;
export const getCandidateJobUrlKey = ({ candidate_new }: RootState) =>
  candidate_new.candidateJobUrlKey;
export const getCandidateApplications = ({ candidate_new }: RootState) =>
  candidate_new.applications;
