import React from "react";
import { Pagination as MuiPagination, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const RenderDesktop = ({ t, itemsPerPage, totalPosts, paginate, page }) => {
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  return (
    <Stack direction="row" width="100%" mt={3} px={1}>
      <Stack flex={1} justifyContent="center">
        <Typography variant="body3" color="textSecondary">
          {t("showing")} {indexOfFirstItem + 1} -{" "}
          {totalPosts > itemsPerPage && itemsPerPage * page < totalPosts
            ? indexOfLastItem
            : totalPosts}{" "}
          {t("basic_job_board.of")} {totalPosts} {t("results")}
        </Typography>
      </Stack>
      <Stack flex={1} alignItems="flex-end">
        <MuiPagination
          count={Math.ceil(totalPosts / itemsPerPage) || 1}
          onChange={(e, currentPage) => {
            paginate(currentPage);
          }}
          page={page}
          color="secondary"
        />
      </Stack>
    </Stack>
  );
};

const RenderMobile = ({ t, itemsPerPage, totalPosts, paginate, page }) => {
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  return (
    <Stack width="100%" mt={3} spacing={2.25}>
      <Stack alignItems="center">
        <Typography variant="body3" color="textSecondary">
          {t("showing")} {indexOfFirstItem + 1} -{" "}
          {totalPosts > itemsPerPage && itemsPerPage * page < totalPosts
            ? indexOfLastItem
            : totalPosts}{" "}
          {t("basic_job_board.of")} {totalPosts} {t("results")}
        </Typography>
      </Stack>
      <Stack alignItems="center">
        <MuiPagination
          count={Math.ceil(totalPosts / itemsPerPage) || 1}
          onChange={(e, currentPage) => {
            paginate(currentPage);
          }}
          page={page}
          color="secondary"
        />
      </Stack>
    </Stack>
  );
};

export default function Pagination({
  itemsPerPage,
  totalPosts,
  paginate,
  page,
  desktop = true,
}) {
  const { t } = useTranslation();
  return desktop ? (
    <RenderDesktop
      t={t}
      itemsPerPage={itemsPerPage}
      totalPosts={totalPosts}
      paginate={paginate}
      page={page}
    />
  ) : (
    <RenderMobile
      t={t}
      itemsPerPage={itemsPerPage}
      totalPosts={totalPosts}
      paginate={paginate}
      page={page}
    />
  );
}
