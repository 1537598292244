import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ProductPageComponent from "./component";
import { getActiveProduct } from "../../store_new/selectors/CurrentUser";
import { setIsBuyProductDialogOpen } from "../../store_new/reducers/dialogs";
import {
  TConvertedPremiumProduct,
  TPremiumPackage,
  TPremiumProductItem,
} from "../../models/SingleJob";
import {
  jobSliceSelectors,
  setSelectedProducts,
} from "../../slices/Job/jobSlice";
import { getSingleJobState } from "../../store_new/selectors/SingleJob";
import { filterPackages } from "../../utils";
import { setActiveProduct } from "../../store_new/reducers/CurrentUser";

const ProductPageNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [displayAllPackages, setDisplayAllPackages] = useState(false);
  const [activeProductId, setActiveProductId] = useState<number | null>(null);

  const selectedProducts = useSelector(jobSliceSelectors.selectedProducts); // TODO refactor later (cart component)
  const activeProduct: any = useSelector(getActiveProduct);
  const { packages } = useSelector(getSingleJobState);

  const productPackages = useMemo(() => {
    if (packages) {
      const currentData = filterPackages(packages, activeProduct?.name);
      return currentData;
    }
    return [];
  }, [packages, activeProduct]);

  const isSelected = (currentItem: TPremiumProductItem | TPremiumPackage) => {
    return selectedProducts.some(
      (item: TPremiumProductItem | TPremiumPackage) =>
        item.id === currentItem.id
    );
  };

  const handleOnChangeActiveProductId = (id: number | null) => {
    setActiveProductId(id);
  };

  const handleOpenBuyProductDialog = (productItem: TPremiumProductItem) => {
    dispatch(setSelectedProducts([productItem]));
    dispatch(setIsBuyProductDialogOpen(true));
  };

  const onOpenProduct = (
    product: TConvertedPremiumProduct | TPremiumPackage
  ) => {
    dispatch(setActiveProduct(product as unknown as TConvertedPremiumProduct));

    navigate("/package");
  };

  useEffect(() => {
    if (!activeProduct) {
      navigate("/discover");
    }
  }, []);

  return (
    <ProductPageComponent
      t={t}
      activeProduct={activeProduct as unknown as TConvertedPremiumProduct}
      activeProductId={activeProductId}
      productPackages={productPackages}
      displayAllPackages={displayAllPackages}
      isSelected={isSelected}
      onChangeActiveProductId={handleOnChangeActiveProductId}
      onOpenBuyProductDialog={handleOpenBuyProductDialog}
      onDisplayAllPackages={() => {
        setDisplayAllPackages(true);
      }}
      onOpenProduct={onOpenProduct}
    />
  );
};

export default ProductPageNew;
