import React from "react";
import { Stack } from "@mui/material";
import SelectedTriggerAutomation from "./SelectedTriggerAutomation";
import {
  IAutomationAction,
  IAutomationInfoComponent,
} from "../../models/Automation";

const AutomationInfo = ({
  t,
  currentAutomation,
  onOpenAction,
  onDeleteTriggerAction,
  onHoverLeaveDeleteActionIcon,
}: IAutomationInfoComponent) => (
  <Stack className="automation-info-container">
    <SelectedTriggerAutomation
      t={t}
      variant="trigger"
      data={{
        name: currentAutomation?.trigger?.name,
        const: currentAutomation?.trigger?.const,
      }}
      subText={
        currentAutomation.trigger
          ? undefined
          : (t("automation.selectTriggerSubTitle") as string)
      }
      currentAutomation={currentAutomation}
      onDeleteTriggerAction={onDeleteTriggerAction}
    />
    {currentAutomation.actions.map(
      (action: IAutomationAction, index: number) => (
        <SelectedTriggerAutomation
          key={index}
          onClick={onOpenAction as any}
          t={t}
          variant="component"
          data={action}
          index={index}
          currentAutomation={currentAutomation}
          onDeleteTriggerAction={onDeleteTriggerAction}
          onHoverLeaveDeleteActionIcon={onHoverLeaveDeleteActionIcon}
        />
      )
    )}
    {!currentAutomation.actions.length && (
      <SelectedTriggerAutomation
        t={t}
        variant="emptyComponent"
        data={{
          name: t("automation.newComponent"),
        }}
        currentAutomation={currentAutomation}
        subText={t("automation.addAction") as string}
        hideWhenThenText
      />
    )}
  </Stack>
);

export default AutomationInfo;
