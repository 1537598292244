import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../index";

export const fetchCountries = createAsyncThunk(
  "fetchCountries",
  async (data, { rejectWithValue }) => {
    const lang = localStorage.getItem("lang");
    try {
      const response = await axiosInstance.post("/countries", data, {
        headers: {
          "Accept-Language": lang || "de_DE"
        }
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSitemap = createAsyncThunk(
  "getSitemap",
  async (props, { rejectWithValue }) => {
    try {
      const response = axiosInstance.post("/sitemap.xml");
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);
