import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const StyledStepContainer = styled(Stack)({
  position: "relative",
});

export const StyledStepLabel = styled(Typography)({
  position: "absolute",
  top: "100%",
  left: "50%",
  transform: "translate(-50%, 8px)",
  color: "#131E30",
  fontSize: 14,
  fontWeight: "400",
  lineHeight: "20px",
});

export const StyledJobEditorButtonContainer = styled(Stack)(
  ({
    isJobEditorAdditionalSteps,
  }: {
    isJobEditorAdditionalSteps: boolean;
  }) => ({
    ...(isJobEditorAdditionalSteps && {
      position: "fixed",
      width: "calc(1200px - 48px)",
      bottom: 0,
    }),
    background: "#fff",
    padding: 24,
    paddingTop: isJobEditorAdditionalSteps ? 24 : 0,
    justifyContent: "flex-end",
  }),
);
