import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PersonalInfo from "../ApplicationPage_new/PersonalInfo";
import { getSelectedJobApplications } from "../../store_new/selectors/Applications";

const DetailedApplication = ({ desktop }: { readonly desktop: boolean }) => {
  const navigate = useNavigate();
  const { talentpoolId } = useParams();
  const currentApplication = useSelector(getSelectedJobApplications)?.[0];

  useEffect(() => {
    if (!currentApplication) navigate(`/talentpool/${talentpoolId}`);
  }, [currentApplication]);

  return (
    <Grid container spacing={3} py={3}>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <PersonalInfo
            currentApplication={currentApplication}
            desktop={desktop}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DetailedApplication;
