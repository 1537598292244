import React, { useMemo, useState } from "react";
import { useStyles } from "./styles";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../../services";
import Comments from "./Comments";
import { addMessage } from "../../slices/NotificationSlice/GlobalNotificationSlice";

const Note = ({
  selectedJobOwners,
  data: { note, comments },
  currentUserId,
  application_id,
  setNewNote,
  setEdit,
  setAction,
  handleScroll
}) => {
  const { note: message, timestamp, author_id, firstname, lastname, id } = note;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const fullName = firstname + " " + lastname;
  const initials = firstname.charAt(0) + lastname.charAt(0);
  const isCurrentUser = parseInt(author_id) === parseInt(currentUserId);
  const avatarUrl = ""; // TODO: Implement avatar url

  const handleDelete = async (id) => {
    await axiosInstance
      .post(`/company/candidates/delete-note/${application_id}`, {
        note_id: id
      })
      .then(() => {
        setNewNote("");
        setAction(true);
        setOpen(false);
        dispatch(
          addMessage({
            type: "success",
            title: t("candidate.delete_message_snackbar_title")
          })
        );
      })
      .catch((err) => console.error(err));
  };

  const handleEdit = (note_id, message) => {
    handleScroll();
    setEdit({ note_id: note_id, active: true, text: message });
  };

  const formattedMessage = useMemo(() => {
    if (!message || !selectedJobOwners) return message;

    let newMessageFormat = message;
    selectedJobOwners.forEach((owner) => {
      const username = `@${owner.firstname} ${owner.lastname}`;
      const regex = new RegExp(username, "gi"); // 'g' flag for global search
      newMessageFormat = newMessageFormat.replace(
        regex,
        `<span class="highlighted-owner">${username}</span>`
      );
    });

    return newMessageFormat;
  }, [message, selectedJobOwners]);

  return (
    <div className={classes.notesSection} key={timestamp}>
      <div className={classes.avatar}>
        {avatarUrl ? (
          <Avatar src={""} style={{ width: "35px", height: "35px" }} />
        ) : (
          <p className={classes.initials}>{initials.toUpperCase()}</p>
        )}
      </div>
      <div
        className={classes.note}
        style={{ background: isCurrentUser && "#EEF2FF" }}
      >
        <div
          className={classes.noteWrapper}
          style={{
            padding: comments.length > 0 ? "12px" : "12px 12px 0px 12px"
          }}
        >
          <div className={classes.noteHeader}>
            <p className={classes.fullName}>{fullName}</p>
            {isCurrentUser && (
              <div className={classes.noteActions}>
                <PencilSquareIcon
                  onClick={() => {
                    handleEdit(id, message);
                  }}
                />
                <TrashIcon
                  style={{ marginLeft: "14px" }}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
              </div>
            )}
          </div>
          <p
            className={classes.noteBody}
            dangerouslySetInnerHTML={{ __html: formattedMessage }}
          />
        </div>
        <Comments
          comments={comments}
          id={id}
          timestamp={timestamp}
          application_id={application_id}
          fullName={fullName}
        />
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("candidate.delete_note")}
          </DialogTitle>
          <DialogContent>
            <p
              style={{ fontSize: "14px", lineHeight: "20px", color: "#6B7280" }}
            >
              {t("candidate.are_you_sure_delete_note")}
            </p>
          </DialogContent>
          <DialogActions style={{ padding: "16px 24px", paddingTop: "0px" }}>
            <Button variant="outlined" onClick={() => setOpen(false)} autoFocus>
              {t("no")}
            </Button>
            <Button variant="contained" onClick={() => handleDelete(id)}>
              {t("yes")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Note;
