import React from "react";
import {
  Box,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import ColorPicker from "../../ColorPicker";
import ImageUpload from "../../ImageUpload";
import StyledSectionTitle from "../../SectionTitle";
import TextEditor from "../../TextEditor";
import { IJobDescriptionFormComponent } from "../../../models/JobEditor";
import { SELECTED_EDIT_SECTIONS } from "../../../models/SingleJob";
import {
  StyledColorPickersContainer,
  StyledJobDialogFormSection,
  StyledOptionalLabel,
} from "../styles";
import { getJobEditorState } from "../../../store_new/selectors/JobEditor";
import { updateJobDescription } from "../../../store_new/reducers/JobEditor";

const colorPickerStyle = {
  marginLeft: -240,
};

const JobDescriptionFormComponent = ({
  t,
  register,
  errors,
  control,
  formRef,
  selectedSections,
  onSubmit,
  handleSubmit,
}: IJobDescriptionFormComponent) => {
  const dispatch = useDispatch();
  const { jobDescription } = useSelector(getJobEditorState);

  return (
    <StyledJobDialogFormSection spacing={2} pt={3}>
      <StyledSectionTitle title={t("create_job_first_step.create_job_ad")} />
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Stack spacing={2}>
          {selectedSections?.includes(
            SELECTED_EDIT_SECTIONS.JOB_DESCRIPTION_MEDIA_SECTION
          ) && (
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={4} className="test">
                  <Controller
                    name={"header_1"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ImageUpload
                        title={t("create_job_first_step.upload_photo", {
                          number: 1,
                        })}
                        subtitle={t("create_job_first_step.photo_size")}
                        onChange={(file: File | undefined) => {
                          onChange(file || null);
                          dispatch(
                            updateJobDescription({
                              jobDescription: {
                                ...jobDescription,
                                header_1: file ?? null,
                              },
                            })
                          );
                        }}
                        image={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={"header_2"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ImageUpload
                        title={t("create_job_first_step.upload_photo", {
                          number: 2,
                        })}
                        subtitle={t("create_job_first_step.photo_size")}
                        onChange={(file: File | undefined) => {
                          onChange(file || null);
                          dispatch(
                            updateJobDescription({
                              jobDescription: {
                                ...jobDescription,
                                header_2: file ?? null,
                              },
                            })
                          );
                        }}
                        image={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={"header_3"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ImageUpload
                        title={t("create_job_first_step.upload_photo", {
                          number: 3,
                        })}
                        subtitle={t("create_job_first_step.photo_size")}
                        onChange={(file: File | undefined) => {
                          onChange(file || null);
                          dispatch(
                            updateJobDescription({
                              jobDescription: {
                                ...jobDescription,
                                header_3: file ?? null,
                              },
                            })
                          );
                        }}
                        image={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={"logo"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ImageUpload
                        title={t("create_job_first_step.upload_logo")}
                        subtitle={t("create_job_first_step.photo_size")}
                        onChange={(file: File | undefined) => {
                          onChange(file || null);
                          dispatch(
                            updateJobDescription({
                              jobDescription: {
                                ...jobDescription,
                                logo: file ?? null,
                              },
                            })
                          );
                        }}
                        image={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={"background"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ImageUpload
                        title={t(
                          "create_job_first_step.upload_background_image"
                        )}
                        subtitle={t("create_job_first_step.photo_size")}
                        onChange={(file: File | undefined) => {
                          onChange(file || null);
                          dispatch(
                            updateJobDescription({
                              jobDescription: {
                                ...jobDescription,
                                background: file ?? null,
                              },
                            })
                          );
                        }}
                        image={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StyledColorPickersContainer>
                    <Controller
                      name={"company_color"}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ColorPicker
                          title={t("company_settings.company_color")}
                          color={value}
                          onChange={onChange}
                          value={"company_color"}
                          customStyle={colorPickerStyle}
                        />
                      )}
                    />
                    <Controller
                      name={"background_color"}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ColorPicker
                          title={t("create_job_first_step.background_color")}
                          color={value}
                          onChange={onChange}
                          value={"background_color"}
                          customStyle={colorPickerStyle}
                        />
                      )}
                    />
                  </StyledColorPickersContainer>
                </Grid>
              </Grid>
            </Box>
          )}
          {selectedSections?.includes(
            SELECTED_EDIT_SECTIONS.JOB_DESCRIPTION_MAIN_SECTION
          ) && (
            <>
              <Box sx={{ flex: 1 }} mb={errors["job_title"] ? 1 : 0}>
                <InputLabel>{t("create_job_first_step.job_title")}</InputLabel>
                <TextField
                  placeholder={"Sales Manager (m/w/d)"}
                  fullWidth
                  {...register("job_title")}
                  error={Boolean(errors["job_title"])}
                  helperText={errors["job_title"]?.message}
                />
              </Box>
              <Box sx={{ flex: 1 }} mb={errors["introduction_title"] ? 1 : 0}>
                <InputLabel>
                  {t("create_job_first_step.introduction_title")}
                </InputLabel>
                <TextField
                  placeholder={
                    t(
                      "create_job_first_step.introduction_title_placeholder"
                    ) as string
                  }
                  fullWidth
                  {...register("introduction_title")}
                  error={Boolean(errors["introduction_title"])}
                  helperText={errors["introduction_title"]?.message}
                />
              </Box>
              <Box sx={{ flex: 1 }} mb={errors["introduction"] ? 1 : 0}>
                <InputLabel>
                  {t("create_job_first_step.introduction")}
                </InputLabel>
                <Controller
                  name={"introduction"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextEditor
                      value={value}
                      onChange={onChange}
                      errorMessage={errors["introduction"]?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ flex: 1 }} mb={errors["tasks_title"] ? 1 : 0}>
                <InputLabel>
                  {t("create_job_first_step.tasks_title")}
                </InputLabel>
                <TextField
                  placeholder={t("create_job_first_step.tasks") as string}
                  fullWidth
                  {...register("tasks_title")}
                  error={Boolean(errors["tasks_title"])}
                  helperText={errors["tasks_title"]?.message}
                />
              </Box>
              <Box sx={{ flex: 1 }} mb={errors["tasks"] ? 1 : 0}>
                <InputLabel>{t("create_job_first_step.tasks")}</InputLabel>
                <Controller
                  name={"tasks"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextEditor
                      value={value}
                      onChange={onChange}
                      errorMessage={errors["tasks"]?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ flex: 1 }} mb={errors["profile_title"] ? 1 : 0}>
                <InputLabel>
                  {t("create_job_first_step.requirements_title")}
                </InputLabel>
                <TextField
                  placeholder={
                    t(
                      "create_job_first_step.requirements_title_placeholder"
                    ) as string
                  }
                  fullWidth
                  {...register("profile_title")}
                  error={Boolean(errors["profile_title"])}
                  helperText={errors["profile_title"]?.message}
                />
              </Box>
              <Box sx={{ flex: 1 }} mb={errors["profile"] ? 1 : 0}>
                <InputLabel>
                  {t("create_job_first_step.requirements")}
                </InputLabel>
                <Controller
                  name={"profile"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextEditor
                      value={value}
                      onChange={onChange}
                      errorMessage={errors["profile"]?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ flex: 1 }} mb={errors["offer_title"] ? 1 : 0}>
                <InputLabel>
                  {t("create_job_first_step.benefits_title")}
                </InputLabel>
                <TextField
                  placeholder={
                    t("create_job_first_step.benefits_placeholder") as string
                  }
                  fullWidth
                  {...register("offer_title")}
                  error={Boolean(errors["offer_title"])}
                  helperText={errors["offer_title"]?.message}
                />
              </Box>
              <Box sx={{ flex: 1 }} mb={errors["offer"] ? 1 : 0}>
                <InputLabel>{t("create_job_first_step.benefits")}</InputLabel>
                <Controller
                  name={"offer"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextEditor
                      value={value}
                      onChange={onChange}
                      errorMessage={errors["offer"]?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <InputLabel>
                  <Stack direction={"row"}>
                    {t("create_job_first_step.contact_information_title")}
                    <StyledOptionalLabel>Optional</StyledOptionalLabel>
                  </Stack>
                </InputLabel>
                <TextField
                  placeholder={
                    t(
                      "create_job_first_step.contact_information_placeholder"
                    ) as string
                  }
                  fullWidth
                  {...register("contact_title")}
                  error={Boolean(errors["contact_title"])}
                  helperText={errors["contact_title"]?.message}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <InputLabel>
                  <Stack direction={"row"}>
                    {t("create_job_first_step.contact_information")}
                    <StyledOptionalLabel>Optional</StyledOptionalLabel>
                  </Stack>
                </InputLabel>
                <Controller
                  name={"contact"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextEditor
                      value={value}
                      onChange={onChange}
                      errorMessage={errors["contact"]?.message}
                    />
                  )}
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <InputLabel>
                  <Stack direction={"row"}>
                    Video
                    <StyledOptionalLabel>Optional</StyledOptionalLabel>
                  </Stack>
                </InputLabel>
                <TextField
                  placeholder={
                    t("create_job_first_step.video_placeholder") as string
                  }
                  fullWidth
                  {...register("video_url")}
                  error={Boolean(errors["video_url"])}
                  helperText={errors["video_url"]?.message}
                />
              </Box>
            </>
          )}
        </Stack>
      </form>
    </StyledJobDialogFormSection>
  );
};

export default JobDescriptionFormComponent;
